$(document).ready(function () {
    const locale = $('#local').val();

    if ($('#dictionnaire_dataTable').length) {
        var dictionnaire_dataTable = $('#dictionnaire_dataTable').DataTable({
            "columnDefs": [
                { "data": "id", "targets": 0 },
                { "data": "file_name", "targets": 1 },
                { "data": "fonction_name", "targets": 2 },
                { "data": "description", "targets": 3 },
                { "data": "action", "targets": 4, orderable: false },
            ],
            "processing": true,
            "serverSide": true,
            "ajax": Routing.generate('server_processing_project_dictionnaire',{ "iso_code": $("#local").val(), "id_project": $("#id_project").val()}),
            "paging": true,
            "pageLength": 20,
            "lengthChange": false,
            "searching": true,
            "ordering": true,
            "order": [[0, "desc"]],
            "info": false,
            "autoWidth": false,
            "responsive": true,
            "scrollX": true,
            "language": {
                "url": $("#datatable_lang").val()
            },
        });
   }
   
   
   
   
   
   
   
   $(document).on('input', '#project_dictionnaires_file_name',function(){
        let name_file = $(this).val();
        let name_fonction = $("#project_dictionnaires_fonction_name").val();
		$.ajax({
			method: 'get',
			url: Routing.generate('check_unique_name_file_and_fonction'),
            data: {name_file,name_fonction},
			success: function (res){
                if(res) {
                    $(".fonction_and_file_name_error").show();
                    $("#project_dictionnaires_save").prop('disabled', true);
                } else {
                    $(".fonction_and_file_name_error").hide();
                    $("#project_dictionnaires_save").prop('disabled', false);
                }
			}
		});
   });
   
   
   
   
   
   
   $(document).on('input', '#project_dictionnaires_fonction_name',function(){
        let name_file = $("#project_dictionnaires_file_name").val();
        let name_fonction = $(this).val();
        let $button = $(this).closest('form').find('#project_dictionnaires_save');
        
		$.ajax({
			method: 'get',
			url: Routing.generate('check_unique_name_file_and_fonction'),
            data: {name_file,name_fonction},
			success: function (res){
				if(res) {
                    $(".fonction_and_file_name_error").show();
                    $button.prop('disabled', true);
                } else {
                    $(".fonction_and_file_name_error").hide();
                    $button.prop('disabled', false);
                }
			}
		});
   });
   
   
   
   
   
 
    $(document).on('click', '#project_dictionnaires_save', function(event) {
        event.preventDefault();
        
        let $form = $(this).closest('form');
        let hasError = false;
        
        $('.error_msg').addClass('display-none');

        if ($('#project_dictionnaires_file_name').val().trim() === '') {
            $('#file_name_error').removeClass('display-none');
            hasError = true;
        }

        if ($('#project_dictionnaires_fonction_name').val().trim() === '') {
            $('#fonction_name_error').removeClass('display-none');
            hasError = true;
        }

        if ($('#project_dictionnaires_description').val().trim() === '') {
            $('#description_error').removeClass('display-none');
            hasError = true;
        }

        if (!hasError) {
            $form.submit();
        }
    });
    $('#project_dictionnaires_file_name,#project_dictionnaires_fonction_name ,#project_dictionnaires_description').on('change', function() {
        let $input = $(this);
        let errorId;
    
        if ($input.is('#project_dictionnaires_file_name')) {
            errorId = '#file_name_error';
        
        } 
        else if ($input.is('#project_dictionnaires_fonction_name')) {
            errorId = '#fonction_name_error';
        }
        else if ($input.is('#project_dictionnaires_description')) {
            errorId = '#description_error';
        }
    
        if ($input.val().trim() !== '') {
            $(errorId).addClass('display-none');
        }
    });

	
	
	
	
	

    $(document).on('click', '.project_dictionnaire_page #btn_export_file', function() {
        let id_project = $("#id_project").val();
        let search = $(".datatable_search").val();
        let exportUrl = Routing.generate('export_project_dictionnaire', { id_project, search });

        window.location.href = exportUrl;
    });
	
	
	
	
	$(document).on('change', '#developers_acces', function () {
		var developers = $(this).val()
        $.ajax({
			method: 'post',
            url: Routing.generate('admin_add_acces_dictionnaire'),
            data: {developers:developers , id: $('#id_project').val()},
			success: function (res){
				
			}
		});
	});

});