
let annoncesRequests_table =null;

function loadRequests()
{
    
       var columnDefs = [
            { "data": "id", "targets": 0,"orderable": false }, 
            { "data": "question", "targets": 1,"orderable": false },
            { "data": "position", "targets": 2 ,"orderable": false},
            { "data": "Nbreponses", "targets": 3 ,"orderable": false},
            { "data": "action", "targets":4 ,"orderable": false},
            
        ];
    

        annoncesRequests_table = $('#datatable_questions').DataTable({
        "columnDefs": columnDefs,
        "processing": true,
        "serverSide": true,
        "ajax": {
            "url": Routing.generate('server_processing_annoncesRequests', {"_locale": $('#local').val()}),
            "type": "POST",
            "data": function (d) {
                d._locale = $('#local').val();
                d.id_annonce = $('#id_annonce').val();
             },
            

        },
        "paging": true,
        "pageLength": 10,
        "lengthChange": false,
        "lengthMenu": [[20, 50, 100, -1], [20, 50, 100, "All"]],
        "searching": true,
        "ordering": true,
        "order": [[2, "asc"]],
        "info": false,
        "autoWidth": false,
        "responsive": true,
        "scrollX": true,
        "rowReorder": {
			dataSrc: 'element_menu',
		},
        "language": {
            "url": $("#datatable_lang").val()
        },
    });
	
    return annoncesRequests_table;
   
}






$(document).ready(function () {

  
    if($('#datatable_questions').length){
		loadRequests()

	}

    $("#datatable_questions").on('row-reordered.dt', function (e, diff, edit) {
		let tabId = Array();
		let move = "";

		$("#datatable_questions tbody tr").each(function (index) {
			let id = $(this).find(".Div_request").data("id");
			tabId.push(id);
		});

		if (diff.length > 1) {
			$.ajax({
				method: 'POST',
				url: Routing.generate('update_positions', {
					"tabId": tabId,
                    id_annonce:$("#id_annonce").val()
				}),
				success: function (data) { }
			});
		}
	});
     


})