
let annoncesRequestsOption_table =null;

function loadRequests()
{
    
        var columnDefs = [
            { "data": "id", "targets": 0 }, 
            { "data": "content", "targets": 1 },
            { "data": "action", "targets": 2 },
            
        ];
    

        annoncesRequestsOption_table = $('#datatable_options').DataTable({
        "columnDefs": columnDefs,
        "processing": true,
        "serverSide": true,
        "ajax": {
            "url": Routing.generate('server_processing_annoncesRequestsOptions', {"_locale": $('#local').val()}),
            "type": "POST",
            "data": function (d) {
                d._locale = $('#local').val();
                d.id_request = $('#id_request').val();
             },

        },
        "paging": true,
        "pageLength": 20,
        "lengthChange": false,
        "searching": true,
        "ordering": true,
        "order": [[0, "desc"]],
        "info": false,
        "autoWidth": false,
        "responsive": true,
        "scrollX": true,
        "language": {
            "url": $("#datatable_lang").val()
        },
    });
	
    return annoncesRequestsOption_table;
}

$(document).ready(function () {


  
    if($('#datatable_options').length){
		loadRequests()

	}
     
   
    

})