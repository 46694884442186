import {apiSearchEntreprises} from './global.js';

function OnLoadAccessRequestDataTable() {
	let table = $("#acces_request_dataTable").DataTable({
		"columnDefs": [
			{ "data": "id", "targets": 0, visible: false },
			{ "data": "nom", "targets": 1 },
			{ "data": "prenom", "targets": 2 },
			{ "data": "nom_societe", "targets": 3 },
			{ "data": "email", "targets": 4 },
			{ "data": "creat_at", "targets": 5 },
			{ "data": "traiter_le", "targets": 6 },
			{ "data": "action", "targets": 7 }
		],
		"processing": true,
		"serverSide": true,
		"ajax": Routing.generate('server_processing_Access_request'),
		"paging": true,
		"pageLength": 10,
		"lengthChange": false,
		"lengthMenu": [[20, 50, 100, -1], [20, 50, 100, "All"]],
		"language": {
			url: "/datatable_fr.json",
			paginate: {
				next: "<i class='ti-arrow-right'></i>",
				previous: "<i class='ti-arrow-left'></i>"
			}
		},
		"order": [[0, "desc"]],
		"info": false,
		"ordering": true,
		"searching": true,
		"bLengthChange": false,
		"scrollX": true,
	});

	return table;
}
$(document).ready(function () {
	$("#form_demander_acces #company_name").autocomplete({
        source: function (request, response) {
            apiSearchEntreprises(request.term).done(function (data) {
                var entreprises = [];
                response($.map(data["results"], function (item) {
                    let denomination = item["nom_raison_sociale"] ?? (item["nom_complet"] ?? item["siege"]["nom_commercial"]);
                    
                    if ($.inArray(denomination.toUpperCase(), entreprises) == -1) {
                        entreprises.push(denomination.toUpperCase());
                        return { label: denomination.toUpperCase(), value: denomination };
                    }
                }));
            });
        },
        minLength: 3,
        position: { my : "right top", at: "right top+30" },
    })

    var accessRequestTable = OnLoadAccessRequestDataTable();

	$(document).on("click", ".access_request .btn_action", function(){
		let state = $(this).attr("data-state");
		let id_access = $(this).attr("data-id");
		var modal_text = $("#modalConfirmation .textConfirmation").text();
	
		let nom = $(this).parent().attr("data-nom");
		let prenom =  $(this).parent().attr("data-prenom");
		let nom_societe = $(this).parent().attr("data-nom-societe");
		let date_demande = $(this).parent().attr("data-date-demande");
		modal_text = modal_text.replace("#nom#", nom);
		modal_text = modal_text.replace("#prenom#", prenom);
		modal_text = modal_text.replace("#nom_société#", nom_societe);
		modal_text = modal_text.replace("#date_demande#", date_demande);
		$("#modalConfirmation .textConfirmation").text(modal_text);
		$("#modalConfirmation .AccessRequestAction").attr("data-id",id_access);
		$('.btn.btn-info.pull-left').css("display", "none");
		
		$('#modalConfirmation').modal("show");

	});

	$(document).on('click','#modalConfirmation .AccessRequestAction', function(event) {
		let state = $(this).attr("data-state");
		let id_access = $(this).attr("data-id");

		$.ajax({
			url: Routing.generate('accepted_refused_access_request'),
			type: 'POST',
			data: {id_access, state},
			success: function(res) {
				accessRequestTable.ajax.reload();
				
			}
		});
	});



});