function ComptesClientDatatable() {
	let comptes_client_table = $('#datatable_comptes_client').DataTable({
		"columnDefs": [
			{ "data": "id", "targets": 0 },
 			{ "data": "nom", "targets": 1 },
			{ "data": "prenom", "targets": 2 },
			{ "data": "email", "targets": 3 },
			{ "data": "action", "targets": 4, orderable: false },
		],
		"processing": true,
		"serverSide": true,
		"ajax": Routing.generate('server_processing_comptes_clients', { 'id_client': $('#id_client').val() , '_locale': $('#local').val()}),
		"paging": true,
		"pageLength": 20,
		"lengthChange": false,
		"searching": true,
		"ordering": true,
		"order": [[0, "desc"]],
		"info": false,
		"autoWidth": false,
		"responsive": true,
		"scrollX": true,
		"language": {
			"url": $("#datatable_lang").val()
		},
	})
	return comptes_client_table;
}

function ComptesClientFrontDatatable() {
	let comptes_client_front_table = $('#datatable_comptes_client_front').DataTable({
		"columnDefs": [
			{ "data": "id", "targets": 0 },
			{ "data": "nom", "targets": 1 },
			{ "data": "prenom", "targets": 2 },
			{ "data": "email", "targets": 3 },
			{ "data": "action", "targets": 4, orderable: false },
		],
		"processing": true,
		"serverSide": true,
		"ajax": Routing.generate('server_processing_comptes_client_front', { '_locale': $('#local').val()}),
		"paging": true,
		"pageLength": 20,
		"lengthChange": false,
		"searching": true,
		"ordering": true,
		"order": [[0, "desc"]],
		"info": false,
		"autoWidth": false,
		"responsive": true,
		"scrollX": true,
		"language": {
			"url": $("#datatable_lang").val()
		},
	})
	return comptes_client_front_table;
}

$(document).ready(function () {

	let comptes_client_table = ComptesClientDatatable();
	ComptesClientFrontDatatable();

});