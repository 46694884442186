	var others = true;
	var endByDefault = false;
	var ableToScroll = false;
	var cookies = document.cookie;
	var $window = $(window);


	function show_in_scroll()
	{	
		$(".show_top_projects").hide()
		$(".shadow_filtre").css("visibility", "visible");
		
		if(!$(".show_top_projects").is(":hidden")){
			window.scrollTo(0, 0);
		}	
	}





	function load_projects_ajax( categorie_liste_details , container , position = null , current_projet = null )
	{
		$.ajax({
			method: 'POST',
			url: Routing.generate("project_charger_ajax", {
				'position':position,
				'local':$("#local").val(),
				'liste_categorie_selected':categorie_liste_details,
				'current_projet':current_projet
			}),
			success: function (html) {
				$(container).empty();
				$(container).append(html["html_content"]);
				
				if(position){	
					$("#projList").attr("data-lastid",html["count"])
					others = html["others_test"];
					
					if(others && endByDefault && $(window).scrollTop() + $(window).height() == $(document).height()){
						endByDefault = true;
						charge_images_list();
					}
					
					ableToScroll = true;
					$(window).scroll();
				}else{
					ableToScroll = false;
					
					if( parseInt(html["count"]) > 1 )
						$(".slick_block_title").show();
					else					
						$(".slick_block").hide();
					
					
					$('#more_project_categorie').slick({
						  dots: true,
						  autoplay:true,
						  autoplaySpeed:1500,
						  arrows:true,
						  infinite: true,
						  slidesToShow: 3,
						  slidesToScroll: 1,
						  responsive: [
							{
							  breakpoint: 1281,
							  settings: {
								slidesToShow: 2,
								slidesToScroll:1
							  }
							},
							{
							  breakpoint: 880,
							  settings: {
								slidesToShow: 1,
								slidesToScroll: 1
							  }
							}
						  ]
					});
					
					
				}
			}		
		});
	}



	function more_project_categorie()
	{
		let categorie_liste_details = null;
		
		if($("#categorie_liste_details").length > 0 ){
			categorie_liste_details = JSON.parse($("#categorie_liste_details").val());
			load_projects_ajax(categorie_liste_details,"#more_project_categorie",null,$("#current_project").val()); 	
		}		
		
	}





	function get_gategory_selected()
	{
		var liste_categorie_selected = []
		
		$(".Filter_switcher").each(function(key){
			if($(this).prop( "checked" ) && !$(this).hasClass("AllSwitchBlock"))			liste_categorie_selected[key] = $(this).data('id');
		})
		
		return liste_categorie_selected 
	}




	function charge_images_list () 
	{	
		if(others){
			others = false
			endByDefault = false

			var lastid = $("#projList").attr("data-lastid");
			var positionget = $("#max_item").val();
			var position = parseInt(lastid)+parseInt(positionget);
					
			var liste_categorie_selected = get_gategory_selected()
			
			$("#projList").attr("data-lastid",position);

			if(get_gategory_selected.length == 0){	
				$("#projList").attr("data-lastid",0)
				$("#add_more_results").show();
			}
			
			load_projects_ajax(liste_categorie_selected,"#projList",position); 	
		}
	}




	function formatState (data) 
	{
		var baseUrl = "";

		if (!data.id) 	return data.text;
					
		if(data.element.id){
			html = $("#select2_image_text").html();
			html = html.replace("#src#", data.element.id);
			html = html.replace("#txt#", data.text);
		}else{
			html= '<span> ' + data.text + '</span>';
		}  

		return $(html);
	};






	function creat_tags_btn()
	{	
		$.ajax({
			method: 'POST',
			
			url: Routing.generate("creat_tags_btn_ajax", {
				'local':$("#local").val(),
				'liste_categorie_selected':get_gategory_selected(),
				'filtre':true
				}
			),
			success: function (html) {
				$('.input_tags').empty()
				$("#projList").attr("data-lastid",0);
				$('.input_tags').append(html);
				
				others = true;
				charge_images_list();
			}	
		});
		
		$('.filer_modal').hide();
		$(".Filter_switcher_Close_button").click();
		
	}




	$(document).ready(function() {
		$(".cover_image_details").css("background-image", "url(" + $("#img_cover").val() + ")");
		
	   
		$window.scroll(function() {
			if ( $window.scrollTop() >= $( ".show_top_projects" ).height() ) {
					show_in_scroll()
			}
		});
		
		
		$(".cover_image_details").css("background-image", "url(" + $("#img_cover").val() + ")");
		
		
		if($('div.index_page').length){
			if(cookies !=''){
				var cookie_name_val = cookies.split("=");
				var val_coockie = JSON.parse(decodeURIComponent(cookie_name_val[1]))
			}

			if($(".bloc_input_tags").length>0 && cookie_name_val && cookie_name_val[0]=="item_selected"){
				if(val_coockie.length > 0){
					show_in_scroll()
					
					for (let i = 0; i < val_coockie.length; i++) {
						$("#switcher_"+val_coockie[i]).click()
					}
					
					for (let i = 0; i < val_coockie.length; i++) {
						$("#AllSwitchBlock_"+val_coockie[i]).click()
						
						if($("#AllSwitchBlock_"+val_coockie[i]).length>0){
							$("#AllSwitchBlock_"+val_coockie[i]).parent('.categorie_name').siblings().each(function() {
								$(this).children('.Filter_switcher').click();
							});
						}
					}
					
					creat_tags_btn();
				}
			}else{
				charge_images_list()
			}
		}
		
		
		
		if(!$('div.index_page').length)		more_project_categorie()
		
		
		
		$(window).scroll(function() {
			if(ableToScroll && others && !endByDefault && $(window).scrollTop() + $(window).height() == $(document).height())		charge_images_list();
		});

		
		
		if(document.getElementById("projList") !== null){
			if(val_coockie && val_coockie.length == 0)		charge_images_list();
		}

			
		$('.select_search').select2({
			templateResult: formatState
		});
		
		$.switcher('.Filter_switcher');



		$(document).on("click",".close_tag",function(){            
			id = $(this).parent('.categorie_child_tags').data('id')
			$("#"+id).remove()
			$("#switcher_"+id).click()
			
			$("#switcher_"+id).parent('.sous_categorie_name').siblings('.categorie_name').children('.ui-switcher').attr('aria-checked',"false");
			
			creat_tags_btn()
		})




		$(document).on('click','#search_button',function(){ 
			$("#modal_Filter").modal({   
				closeText: "x",
				closeClass: "Filter_switcher_Close_button"
			});
			
			$(".blocker.current").addClass("filter_moda_added_div");
			
			return false;
		});
		
		
		
		$(document).on('change','.AllSwitchBlock',function(){		
			var idBlock = $(this).val();
			
			if($(this).prop("checked") == true){	
				$('.switcher_'+idBlock).each(function(key,index) {
					if( $(this).prop("checked") != true )		$(this).click();
				});
			}else{
				$('.switcher_'+idBlock).each(function(key,index) {
					if( $(this).prop("checked") == true )	$(this).click();
				});
			}
		});
		
		
		
		
		$(document).on('click','.part_photo',function(){
			var current_id = $(this).data('id');
			var current_same= $(this).data('same');
			var count_next = 0;
			var html='';
			
			$( ".part_photo" ).each(function() {
				var id = $( this ).data( "id" );
				var src = $( this ).data( "slide" );
				var same = $( this ).data( "same" );
				
				if(same == current_same){
					count_next++;
					slide_html = $('#slider_template_part').html();
					slide_html = slide_html.replace("#id#", id);
					slide_html = slide_html.replace("#src#", src);
					slide_html = slide_html.replace("#same#", same);
					html += slide_html;
				}
			});
			
			if( count_next == 1)
				$(".prev_slide , .next_slide").css("display", "none");
			else
				$(".prev_slide , .next_slide").show();
			
			$("#myModal.slider .content_image_hd").html(html);
			
			current_slide_position = $("#mySlides_photo_"+current_id+"_"+current_same).index();
			
			$("#myModal.slider .content_image_hd .mySlides").hide();
			$("#myModal.slider .content_image_hd #mySlides_photo_"+current_id+"_"+current_same).css("display", "flex");
			
			$("#myModal").show();
		});
		
		
		
		
		
		$("#btn_valid_filtre").click(function(){
			creat_tags_btn();
		});




		$(document).on('click','.part_img_bloc_one,.part_img_bloc,.prev_slide,.next_slide',function(){
			pos_img = $(".mySlides_image").data("position")
			  
			if($(this).hasClass("part_img_bloc_one") || $(this).hasClass("part_img_bloc")){
				pos_img = $(this).find('img').data("position")-1
				
				$("#myModal .popap_img_liste").val($(this).parent().find(".img_liste").val()) 
			}
			
			if($(this).hasClass("prev_slide")){
				if(pos_img == 0)
					pos_img = (JSON.parse($(this).parent().find(".popap_img_liste").val()).length) -1
				else
					pos_img -= 1
			}else if($(this).hasClass("next_slide")){
				if(pos_img == (JSON.parse($(this).parent().find(".popap_img_liste").val()).length) -1)
					pos_img = 0
				else
					pos_img += 1				
			}
			
			$(".mySlides_image").data("position",pos_img);
			$(".mySlides_image").attr("src",(JSON.parse($(".popap_img_liste").val())[pos_img]).split("|")[0]);
			$("#myModal.slider .content_image_hd .mySlides").hide();
			
			$("#myModal").show();
		});
		
		

		
		$(document).on('click','.name_categorie_details',function(){
			var categorie = [$(this).attr('data-filtre')];
			
			
			$.ajax({
				method: 'POST',
				url: Routing.generate("update_cookie_item_selected", {
					'categorie_selected':categorie,
					}
				),
				success: function (html) {
					window.location = $("#front_projects_url").val();
				}	
			});
		});


		


	});
