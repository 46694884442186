let candidat_test_date = null;
let candidat_test_heure_debut = null;
let candidat_test_heure_fin = null;
let defaultDate = new Date();




var candidatColumnDefs = [
    { "data": "id", "targets": 0 },
    { "data": "nom", "targets": 1 },
    { "data": "date_debut", "targets": 2 },
    { "data": "date_fin", "targets": 3 },
    { "data": "test", "targets": 4 },
    { "data": "state", "targets": 5 },
    { "data": "action", "targets": 6, orderable: false },
];




if ($("#id_candidat").val()) {
    candidatColumnDefs = [
        { "data": "date", "targets": 0 },
        { "data": "type", "targets": 1 },
        { "data": "state", "targets": 2 },
        { "data": "action", "targets": 3, orderable: false },
    ];
}



function setHeureFinTest() 
{
    var id_test = $("#recrutement_candidat_tests_test").val();
    
	$.ajax({
        method: 'POST',
        url: Routing.generate('getTestDuree'),
        data: { id_test },
        success: function (rep) {
            var durationInMinutes = parseInt(rep);
            var dateParts = $("#recrutement_candidat_tests_date").val().split(" ")[0].split("/");
            var timeParts = $("#recrutement_candidat_tests_heure_debut").val().split(":");
            var rearrangedDateString = dateParts[1] + "/" + dateParts[0] + "/" + dateParts[2] + " " + timeParts[0] + ":" + timeParts[1];
            var startDate = new Date(rearrangedDateString);
            var endDate = new Date(startDate.getTime() + (durationInMinutes * 60000));
            
			candidat_test_heure_fin.setDate(endDate, true);
            candidat_test_heure_fin.redraw();
        }
    });
}
function loadRequestOption()
{
    
        var columnDefs = [
            { "data": "id", "targets": 0 }, 
            { "data": "request", "targets": 1 },
            { "data": "option", "targets": 2 },
        ];
    
       let  request_option_table = $('#datatable_request_option').DataTable({
        "columnDefs": columnDefs,
        "processing": true,
        "serverSide": true,
        "ajax": {
            "url": Routing.generate('liste_requests_options_candidat', {"_locale": $('#local').val()}),
            "type": "POST",
            "data": function (d) {
                d._locale = $('#local').val();
                d.id_candidat = $('#id_candidat').val();
             },

        },
        "paging": true,
        "pageLength": 20,
        "lengthChange": false,
        "searching": true,
        "ordering": true,
        "order": [[0, "desc"]],
        "info": false,
        "autoWidth": false,
        "responsive": true,
        "scrollX": true,
        "language": {
            "url": $("#datatable_lang").val()
        },
    });
	
    return  request_option_table;
}






$(document).ready(function () {


    if($('#datatable_request_option').length){
		loadRequestOption()

	}


    if($("#recrutement_candidat_tests_heure_fin").length && $("#recrutement_candidat_tests_test_type_0").is(":checked")){
        $("#recrutement_candidat_tests_heure_fin").attr("readonly", "readonly");
        $("#recrutement_candidat_tests_heure_fin").css("pointer-events", "none");
    }

    var modal_msg = $('#modal_msg_user').val();
    var locale = $('#local').val();

    if ($('.recrutement_candidats_list').length) {
        var recrutement_candidats_table = $('.recrutement_candidats_list').DataTable({
            "columnDefs": [
                { "data": "id", "targets": 0 },
                { "data": "nom", "targets": 1 },
                { "data": "telephone", "targets": 2 },
                { "data": "email", "targets": 3 },
                { "data": "annonce", "targets": 4 },
                { "data": "ville", "targets": 5 },
                { "data": "etat_test", "targets": 6 },
                { "data": "action", "targets": 7, orderable: false },
            ],
            "processing": true,
            "serverSide": true,
            "ajax": {
                url: Routing.generate('server_processing_recrutement_candidats', { '_locale': locale }),
                data: function (d) {
                    if($("#recrutement_candidats_archive_list").length)     d.restore = true;
                }
            },
            "paging": true,
            "pageLength": 20,
            "lengthChange": false,
            "searching": true,
            "search": {
                search: $(".datatable_search").val()
            },
            "ordering": true,
            "order": [[0, "desc"]],
            "info": false,
            "autoWidth": false,
            "responsive": true,
            "scrollX": true,
            "language": {
                "url": $("#datatable_lang").val()
            },
        });  
    }
	
    if ($('#recrutement_candidats_tests_list').length) {
        var recrutement_candidats_tests_table = $('#recrutement_candidats_tests_list').DataTable({
            "columnDefs": candidatColumnDefs,
            "processing": true,
            "serverSide": true,
            "ajax": Routing.generate('server_processing_recrutement_candidat_tests', { '_locale': locale, 'id_candidat': $("#id_candidat").val() }),
            "paging": true,
            "pageLength": 20,
            "lengthChange": false,
            "searching": true,
            "ordering": true,
            "order": [[0, "desc"]],
            "info": false,
            "autoWidth": false,
            "responsive": true,
            "scrollX": true,
            "language": {
                "url": $("#datatable_lang").val()
            },
        });
		
        setInterval(function () {
            $('#recrutement_candidats_tests_list').DataTable().ajax.reload();
        }, 1000*60*10);
    }




    if ($("#recrutement_candidat_tests_date").length && $("#recrutement_candidat_tests_date").val())
        defaultDate = new Date($("#recrutement_candidat_tests_date").val().split("/").reverse().join("-"));


    if($("form[name='recrutement_candidats'] #recrutement_candidats_annonce").length){
        var placeholderText = $("form[name='recrutement_candidats'] #recrutement_candidats_annonce").data("placeholder");
        $("form[name='recrutement_candidats'] #recrutement_candidats_annonce").prepend("<option>" + placeholderText + "</option>");
      
        $("form[name='recrutement_candidats'] #recrutement_candidats_annonce").select2({
            sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),
            width: '100%',
            placeholder: false,
        });
    }

    candidat_test_date = $("#recrutement_candidat_tests_date").flatpickr({
        enableTime: false,
        dateFormat: "d/m/Y",
        defaultDate: defaultDate,
        position: 'auto',
        "locale": {
            "firstDayOfWeek": 1
        },
    });


    
    candidat_test_heure_debut = $("#recrutement_candidat_tests_heure_debut").flatpickr({
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        onChange: function (selectedDates, dateStr, instance) {
            if((($("#recrutement_candidat_tests_test_type_0").length && $("#recrutement_candidat_tests_test_type_0").is(":checked")) || $("#recrutement_candidat_tests_test_type_0").length == 0) && $("#recrutement_candidat_tests_date").val() && $("#recrutement_candidat_tests_heure_debut").val()){
				setHeureFinTest();
			}
        },
    });




    candidat_test_heure_fin = $("#recrutement_candidat_tests_heure_fin").flatpickr({
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
    });




    $(document).on("change", 'input[name="recrutement_candidat_tests[test_type]"]', function(){
        if($("#recrutement_candidat_tests_test_type_0").is(":checked")) {
            $("#recrutement_candidat_tests_heure_fin").attr("readonly", "readonly");
            $("#recrutement_candidat_tests_heure_fin").css("pointer-events", "none");
        }else{
            $("#recrutement_candidat_tests_heure_fin").attr("readonly", "");
            $("#recrutement_candidat_tests_heure_fin").css("pointer-events", "auto");
        }
    })





    $(document).on("change", '#recrutement_candidat_tests_test_type', function(){
        if($("#isEdit").val() == 1){
            if($(this).val() == "ecrit") 
                $(this).parents(".row").first().find("> div").removeClass("col-md-6").addClass("col-md-4");
            else
                $(this).parents(".row").first().find("> div").removeClass("col-md-4").addClass("col-md-6");
        }
    })

    $(document).on("change", '#recrutement_candidat_tests_test', function(){
        if((($("#recrutement_candidat_tests_test_type_0").length && $("#recrutement_candidat_tests_test_type_0").is(":checked")) || $("#recrutement_candidat_tests_test_type_0").length == 0) && $("#recrutement_candidat_tests_date").val() && $("#recrutement_candidat_tests_heure_debut").val()){
            setHeureFinTest();
        }
    })


   
    if($('#id_candidat').val() && $('#test').length && $('#urltest').val() ){
	 
		var href =  $('#urltest').val().replace('*candidat*', $('#id_candidat').val());
		$('#test').attr('href', href)
	}

});
