import { applySelect2 } from "../custom/global.js"


var oldeEmail = null;
let tasks_user_table=null;
let details_affectation=null;
function check_mail_username(attribut_checked, attribut_value, type) {

	var url = "";

	if (type = "email")
		url = Routing.generate("check_email_exist", { 'email': attribut_value, 'id': $('#id_item').val() })
	else
		url = Routing.generate("check_mail_username", { 'attribut_checked': attribut_checked, 'attribut_value': attribut_value, 'id': $('#id_item').val() })


	if (attribut_value == oldeEmail) {
		$('#error_user_' + attribut_checked).hide();
		$("#user_save").prop("disabled", false);
		$("#clients_user_save").prop("disabled", false);
	} else {
		$.ajax({
			method: 'POST',
			url: url,
			success: function (data) {
				if (data == true) {
					$('#error_user_' + attribut_checked).show();
					$("#email_found").show();
					$("#user_save").prop("disabled", true);
					$("#clients_user_save").prop("disabled", true);
					$(".send_access").prop("disabled", true);
				} else {
					$('#error_user_' + attribut_checked).hide();
					$("#email_found").hide();
					$("#user_save").prop("disabled", false);
					$("#clients_user_save").prop("disabled", false);
					$(".send_access").prop("disabled", false);
				}

			}
		});
	}
}


function checkPassword() {
	let password = $('#password').val();
	let confirmPassword = $('#confirme_password').val();

	var specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;
	var upperCasePattern = /[A-Z]/;
	var numberPattern = /[0-9]/;

	let isValid = true;
	$(".errorMessage").hide();
	if (password.length < 6) {
		$('#password_erreur_length').show();
		isValid = false;
	}

	if (!specialCharPattern.test(password) || !upperCasePattern.test(password) || !numberPattern.test(password)) {
		$('#password_erreur_carachter').show();
		isValid = false;
	}

	if (password !== confirmPassword) {
		$('#password_erreur_conform').show();
		isValid = false;
	}

	return isValid;
}

function LoadTasksUser()
{
    
        var columnDefs = [
            { "data": "id", "targets": 0 }, 
            { "data": "projet", "targets": 1 },
            { "data": "nb_heure", "targets": 2 },
        ];
    

    tasks_user_table = $('#datatable_tasks_users').DataTable({
        "columnDefs": columnDefs,
        "processing": true,
        "serverSide": true,
        "ajax": {
            "url": Routing.generate('list_project_user'),
            "type": "POST",
            "data": function (d) {
                d._locale = $('#local').val();
                d.user_id = $('#id_item').val();
             },

        },
        "paging": true,
        "pageLength": 20,
        "lengthChange": false,
        "searching": true,
        "ordering": true,
        "order": [[0, "desc"]],
        "info": false,
        "autoWidth": false,
        "responsive": true,
        "scrollX": true,
        "language": {
            "url": $("#datatable_lang").val()
        },
    });
	
    return tasks_user_table;
}

function loadDetailsTasks()
{
    
        var columnDefs = [
            { "data": "id", "targets": 0 }, 
            { "data": "livrable", "targets": 1 },
            { "data": "dateDebut", "targets": 2 },
            { "data": "dateFin", "targets": 3 },
            { "data": "details", "targets": 4 },
        ];
    

		details_affectation = $('#datatable_details_affectations').DataTable({
        "columnDefs": columnDefs,
        "processing": true,
        "serverSide": true,
        "ajax": {
            "url": Routing.generate('list_details_affectations', { '_locale': $('#local').val() ,'user_id': $('#id_item').val()}),
            "type": "POST",
            "data": function (d) {
                d._locale = $('#local').val();
                d.user_id = $('#id_item').val();

              },

        },
        "paging": true,
        "pageLength": 20,
        "lengthChange": false,
        "searching": true,
        "ordering": true,
        "order": [[0, "desc"]],
        "info": false,
        "autoWidth": false,
        "responsive": true,
        "scrollX": true,
        "language": {
            "url": $("#datatable_lang").val()
        },
    });
	
    return details_affectation;
}
$(document).ready(function () {
	if ($('#user_email').length) oldeEmail = $('#user_email').val();
	if ($('#clients_user_email').length) oldeEmail = $('#clients_user_email').val();
	var modal_msg = $('#modal_msg_user').val();
	var locale = $('#local').val();
    var currentProjectId = null; // Initialize with a default value or null

	var users_table = $('#user_list').DataTable({
		"columnDefs": [
			{ "data": "id", "targets": 0 },
			{ "data": "nom_prenom", "targets": 1 },
			{ "data": "email", "targets": 2 },
			{ "data": "absence", "targets": 3 },
			{ "data": "role", "targets": 4 },
			{ "data": "action", "targets": 5, orderable: false },
		],
		"processing": true,
		"serverSide": true,
		"ajax": Routing.generate('server_processing_users', { '_locale': locale }),
		"paging": true,
		"pageLength": 20,
		"lengthChange": false,
		"searching": true,
		"ordering": true,
		"order": [[0, "desc"]],
		"info": false,
		"autoWidth": false,
		"responsive": true,
		"language": {
			"url": $("#datatable_lang").val()
		},
	});
 
	if($('#id_item').val() && $('#project_user').length && $('#urlUserProject').val() ){
	 
		var href =  $('#urlUserProject').val().replace('*user_id*', $('#id_item').val());
		$('#project_user').attr('href', href)
	}

	$(document).on('click', '.change_password_btn', function (e) {
		e.preventDefault();
		if (checkPassword()) {
			$("#resetPasswordForm").submit();
		}
	});
	$("#userForm").submit(function (event) {

		$("#user_save").prop("disabled", true);
		$("#clients_user_save").prop("disabled", true);

	})
  
	 

	$('.radio_roles').change(function () {
		if (this.value == 'ROLE_ADMIN') {
			$("#user_label").removeClass("checked");
			$("#admin_label").addClass("checked");
		} else if (this.value == 'ROLE_USER') {
			$("#user_label").addClass("checked");
			$("#admin_label").removeClass("checked");
		}
	});



	$(document).on('click', '.supprimer_button', function () {
		var id = $(this).attr("data-id");
		var id_tab = $("#tableau_id").val();
		$.ajax({
			method: 'GET',
			url: Routing.generate('remove_user', { 'id': id }),
			success: function (data) {
				var dataTable = $('#' + id_tab).DataTable();
				if (data != "user innexistant") dataTable.ajax.reload();

			}
		});
	});


	$(document).on('click', '.check_actived_user', function () {
		
		var id = $(this).data("id");
		var etat = $(this).data("etat");
		var msg = "";
		if (etat == "actived") {
			msg = $('#modal_text_disactived').val();

		} else {
			msg = $('#modal_text_actived').val();
		};

		$(".modalmsg").html(msg);

		$("#activate_modal #activate").attr('data-id', id);
		$("#activate_modal #activate").attr('data-etat', etat);
		$("#activate_modal").modal();

		return false;
	});





	$(document).on('keyup', '#user_email,#clients_user_email,#email_user ', function () {
		check_mail_username("email", $(this).val(), "email")
	});


	$(document).on('select2:unselecting', '#project_users', function (e) {
		e.preventDefault();
		var id_user = e.params.args.data.id;
		var projectId = $('#id_item').val();

		$.ajax({
			method: 'POST',
			url: Routing.generate('check_current_developer', { 'id_user': id_user, 'projectId': projectId }),
			success: function (res) {
				if (res.has_task == true) {
					$('#project_users').select2('close');
					$('#modalTeamDev .replace_team_msg').html(res.msg);
					$('#modalTeamDev .dev_team_liste').html(res.equipes_dev);
					applySelect2($("#modalTeamDev .select2"));
					$('.btn.btn-info.pull-left').css("display", "none");
					$.modal.defaults.showClose = false
					$('#modalTeamDev').modal('show');
				} else {
					var select2 = $('#project_users');
					var optionToRemove = select2.find('option[value="' + id_user + '"]');

					if (optionToRemove.length) {
						optionToRemove.prop('selected', false);
						select2.trigger('change');
					}
				}
			}
		});
	});



	$(document).on('click', '.ConfirmReplaceMember', function () {
		var newMember = $(".listdev").val();
		var oldMember = $("#old_member").val();
		var projectId = $('#id_item').val();
		if (newMember != "") {
			$.ajax({
				method: 'POST',
				url: Routing.generate('delete_and_replace_developer', { 'newMember': newMember, 'oldMember': oldMember, 'projectId': projectId }),
				success: function (res) {
					if (res.msg == "done") {
						var select2 = $('#project_users');
						var developerToRemove = select2.find('option[value="' + oldMember + '"]');
						var developerToAdd = select2.find('option[value="' + newMember + '"]');
						if (developerToRemove.length) {
							developerToRemove.prop('selected', false);
							developerToAdd.prop('selected', true);
							select2.trigger('change');
							$("a#return_item").click();
						}
					}
				}
			});
		}

	});


	if($('#datatable_tasks_users').length){
		LoadTasksUser()
	}
	if($('#datatable_details_affectations').length){
		loadDetailsTasks()
	}

	$(document).on('keyup', '#user_username', function () {
		check_mail_username("username", $('#user_username').val(), "username")
	});


	$(document).on('click', '.regenerate_password', function () {
		var id = $(this).attr('data-id');

		$.ajax({
			method: 'POST',
			url: Routing.generate('regenerate_password', { 'id': id, '_locale': locale }),
			success: function (res) {
				if (res == "done") {
					var msg = $('#regenerate_password_success').val();
					$.notify({
						icon: '',
						message: msg
					}, {
						type: 'success',
						timer: 4000
					});

					users_table.ajax.reload();
				}
			}
		});
	});


	$(document).on('click', '#nb_heure', function () {
		 var currentProjectId= $(this).attr("data-id");
 		 details_affectation.ajax.url(
        	Routing.generate('list_details_affectations', {
                '_locale': $('#local').val(),
                'user_id': $('#id_item').val(),
                'projet_id': currentProjectId
             
        })).load();
 		$('#details_affectation').modal('show');
	});
});
