import { apiSearchEntreprises } from './global.js';

function formatState(item) {
    if (!item.id) return item.text;

    let parent = $("<span>");

    var flag = $("<span>", {
        class: "country_flag fi fi-" + item.id.toLowerCase(),
    });

    var name = $("<span>", {
        class: "country_name",
        text: " " + item.text
    });

    parent.append(flag);
    parent.append(name);

    return parent;
};

function initClientForm() {
    if ($("#clients_client_type").val() == "contact") {
        $(".client-info-address").hide();
        $(".client-info-address input,.client-info-address select").val("")
        $(".client-info-business").hide();
        $(".client-info-business input,.client-info-business select").val("");
        $(".is-required").attr("required", false);
    } else if($("#clients_client_type").val() == "client"){
        $(".client-info-address").show();
        $(".client-info-business").show();
        $(".is-required").attr("required", true);
        $("#clients_adresse").attr("required", true);
        $(".client-info-address input").each(function() {
            const placeholder = $(this).attr("placeholder").replace(/\*$/, "") + "*";
            $(this).attr("placeholder", placeholder);
        });
        $(".client-info-business input ").each(function() {
            const placeholder = $(this).attr("placeholder").replace(/\*$/, "") + "*";
            $(this).attr("placeholder", placeholder);
        });
        $(".client-info-general input ").each(function() {
            const placeholder = $(this).attr("placeholder").replace(/\*$/, "") + "*";
            $(this).attr("placeholder", placeholder);
        });
        $("#clients_fax").attr("placeholder", function(index, placeholder) {
            return placeholder.replace(/\*$/, ""); 
        }); 

    } else {
        $(".client-info-address").show();
        $(".client-info-business").show();
        $(".is-required").attr("required", false);
       $(".client-info-address input ").each(function() {
            const placeholder = $(this).attr("placeholder").replace(/\*$/, ""); 
            $(this).attr("placeholder", placeholder);
        });
        $(".client-info-business input ").each(function() {
            const placeholder = $(this).attr("placeholder").replace(/\*$/, ""); 
           
            $(this).attr("placeholder", placeholder);
        });
       
        
    }
}
function loadProjectClient() {

    var columnDefs = [
        { "data": "id", "targets": 0 },
        { "data": "libelle", "targets": 1 },
        { "data": "statue", "targets": 2 },
        { "data": "budget", "targets": 3 },
        { "data": "somme_facture", "targets": 4 },
        { "data": "somme_regle", "targets": 5 },
    ];


    let project_client_table = $('#datatable_projet_client').DataTable({
        "columnDefs": columnDefs,
        "processing": true,
        "serverSide": true,
        "ajax": {
            "url": Routing.generate('list_project_client'),
            "type": "POST",
            "data": function (d) {
                d._locale = $('#local').val();
                d.client_id = $('#id_client').val();
            },

        },
        "paging": true,
        "pageLength": 20,
        "lengthChange": false,
        "searching": true,
        "ordering": true,
        "order": [[0, "desc"]],
        "info": false,
        "autoWidth": false,
        "responsive": true,
        "scrollX": true,
        "language": {
            "url": $("#datatable_lang").val()
        },
    });

    return project_client_table;
}




function initSelectPays(element) {
    element.select2({
        sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),
        width: "100%",
        multiple: false,
        placeholder: element.attr("placeholder"),
        templateResult: formatState,
        templateSelection: formatState,
        language: {
            noResults: function () {
                return $("#message_Select_vide").val();
            }
        },
    });

    if (!element.val()) element.val("FR").trigger("change");
}

function ClientFactureDatatable() {
    let client_facture_table = $('#dataTable_client_factures').DataTable({
        "columnDefs": [
            { "data": "id", "targets": 0 },
            { "data": "libelle_project", "targets": 1 },
            { "data": "date_envoi", "targets": 2 },
            { "data": "date_reglement", "targets": 3 },
            { "data": "montantFacture", "targets": 4 },
            { "data": "devis", "targets": 5 },
            { "data": "Action", "targets": 6, "orderable": false },
        ],
        "processing": true,
        "serverSide": true,
        "ajax": Routing.generate('server_processing_clients_factures', { "iso_code": $("#local").val(), 'id_client': $('#id_client').val() }),
        "paging": true,
        "pageLength": 20,
        "lengthChange": false,
        "searching": true,
        "ordering": true,
        "order": [[0, "desc"]],
        "info": false,
        "autoWidth": false,
        "responsive": true,
        "scrollX": true,
        "language": {
            "url": $("#datatable_lang").val()
        },
    })
    return client_facture_table;
}


$(document).ready(function () {

    initClientForm();
    

    if ($('#datatable_projet_client').length) {
        loadProjectClient()
    }

    

    if ($('#id_client').val() && $('#project_client').length && $('#urlClientProject').val()) {

        var href = $('#urlClientProject').val().replace('*client_id*', $('#id_client').val());
        $('#project_client').attr('href', href)
    }
    if ($("#clients_pays").length) initSelectPays($("#clients_pays"));
    ClientFactureDatatable();

    var modal_msg = $('#modal_msg_user').val();
    var locale = $('#local').val();

    var users_table = $('#client_list').DataTable({
        "columnDefs": [
            { "data": "id", "targets": 0 },
            { "data": "libelle", "targets": 1 },
            { "data": "personne_contact", "targets": 2 },
            { "data": "telephone", "targets": 3 },
            { "data": "email", "targets": 4 },
            { "data": "action", "targets": 5, orderable: false },
        ],
        "processing": true,
        "serverSide": true,
        "ajax": Routing.generate('server_processing_clients', { '_locale': locale, 'type': $('#type').val() }),
        "paging": true,
        "pageLength": 20,
        "lengthChange": false,
        "searching": true,
        "ordering": true,
        "order": [[0, "desc"]],
        "info": false,
        "autoWidth": false,
        "responsive": true,
        "scrollX": true,
        "language": {
            "url": $("#datatable_lang").val()
        },
    });


    $("#clients_libelle").autocomplete({
        source: function (request, response) {
            apiSearchEntreprises(request.term).done(function (data) {
                var entreprises = [];
                response($.map(data["results"], function (item) {
                    let siren = item["siren"];
                    let siret = item["siege"]["siret"];
                    let adresse = item["siege"]["adresse_complete"] ?? item["siege"]["adresse"];
                    let ville = item["siege"]["libelle_commune"];
                    let code_postal = item["siege"]["code_postal"];
                    let denomination = item["nom_raison_sociale"] ?? (item["nom_complet"] ?? item["siege"]["nom_commercial"]);

                    if ($.inArray(denomination.toUpperCase(), entreprises) == -1) {
                        entreprises.push(denomination.toUpperCase());
                        return { label: denomination.toUpperCase(), value: denomination, siren, siret, adresse, ville, code_postal, pays: 'FR' };
                    }
                }));
            });
        },
        select: function (request, response) {
            $("#clients_pays").val(response.item.pays).trigger("change");
            $("#clients_adresse").val(response.item.adresse);
            $("#clients_code_postal").val(response.item.code_postal);
            $("#clients_siret").val(response.item.siret);
            $("#clients_siren").val(response.item.siren);
        },
        minLength: 3,
        position: { my: "right top", at: "right top+30" },
    }).keyup(function () {
    });


    $(document).on('change', '#clients_client_type', function () {
        initClientForm();
        $("#error_form").text("");
        $("#erreur_libelle").text("");
    })
    

   
    $(document).on('submit', "form[name='clients']", function (event) {
        let isValid = true;
        const clientType = $("#clients_client_type").val();

        $("#error_form, #erreur_libelle, #error_personne_contact, #error_telephone, #error_email").text("");

        const showError = (selector, message) => {
            isValid = false;
            $(selector).text(message);
            $('html, body').animate({
                scrollTop: $(selector).offset().top - 100
            }, 800);
        };

        if ($("#clients_libelle").val().trim() === "") {
            showError("#erreur_libelle", "Libelle est obligatoire.");
        }
        if ($("#clients_personne_contact").val().trim() === "") {
            showError("#error_personne_contact", "Contact de personne est obligatoire.");
        }
        if ($("#clients_telephone").val().trim() === "") {
            showError("#error_telephone", "Le numéro de téléphone est obligatoire.");
        }
        if ($("#clients_email").val().trim() === "") {
            showError("#error_email", "L'email est obligatoire.");
        }

        if (clientType === "client") {
            $(".is-required").each(function() {
                if ($(this).val().trim() === "") {
                    showError("#error_form", "Les informations sur l'adresse et sur l'entreprise sont requises pour le type 'Client'.");
                    return false; 
                }
            });
        } else if (clientType !== "" && !isValid) {
            $("#error_form").text("");
        }

        if (!isValid) {
            event.preventDefault();
        }
    });




    $(document).on('change', '#clients_libelle, #clients_personne_contact, #clients_telephone, #clients_email', function () {
        const id = $(this).attr('id');
        const errorMessages = {
            'clients_libelle': "Libelle est obligatoire pour le type 'Client'.",
            'clients_personne_contact': "Contact de personne est obligatoire.",
            'clients_telephone': "Le numéro de téléphone est obligatoire.",
            'clients_email': "L'email est obligatoire."
        };
    
        const errorId = id === 'clients_libelle' ? 'erreur_libelle' : 
                        id === 'clients_personne_contact' ? 'error_personne_contact' : 
                        id === 'clients_telephone' ? 'error_telephone' : 
                        id === 'clients_email' ? 'error_email' : '';
    
        if ($(this).val().trim() === "") {
            $(`#${errorId}`).text(errorMessages[id]);
        } else {
            $(`#${errorId}`).text("");
        }
    });
            
            

    
    $(document).on('click', '.add_client_btn', function () {

        const type = $(".filter-button.selected").attr("data-type")
        const href = $(this).attr("data-href");
        window.location.href = href + '/?type=' + type;
    });

    $(document).on('click', '#edit_etat', function () {
        var meetingUserId = $(this).data('id');
        $('#is_confirmed').attr("data-id",meetingUserId);
        $('#is_refused').attr("data-id",meetingUserId);
    
        $('#modalConfirmation').modal('show');
    });
    


    
});
