import {initRichText } from "../custom/global.js"

$(document).ready(function () {

    const locale = $('#local').val();

	initRichText($('.description_recrutement_test'));

    if ($('#recrutement_testes_dataTable').length) {

        var recrutement_testes_dataTable = $('#recrutement_testes_dataTable').DataTable({
            "columnDefs": [
                { "data": "id", "targets": 0 },
                { "data": "libelle", "targets": 1 },
                { "data": "duree", "targets": 2 },
                { "data": "nb_fois_affecte", "targets": 3 },
                { "data": "action", "targets": 4, orderable: false },
            ],
            "processing": true,
            "serverSide": true,
            "ajax": {
                "url": Routing.generate('server_processing_recrutement_tests'),
                "data": {
                    "iso_code": locale
                }
            },
            "paging": true,
            "pageLength": 20,
            "lengthChange": false,
            "searching": true,
            "ordering": true,
            "order": [[0, "desc"]],
            "info": false,
            "autoWidth": false,
            "responsive": true,
            "scrollX": true,
            "language": {
                "url": $("#datatable_lang").val()
            },
        });
    }

});