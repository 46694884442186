
if (!globalThis.formatDate) {
	var formatDate = function (date, reverse = false, hour = null) {
		if (date) {
			let array = [
				padTo2Digits(date.getDate()),
				padTo2Digits(date.getMonth() + 1),
				date.getFullYear(),
			];

			if (reverse) array.reverse();

			let date_string = array.join('-');

			if (hour !== null) date_string = date_string + "T" + padTo2Digits(hour) + ":59:59";

			return date_string;
		}
	}
	globalThis.formatDate = formatDate;
}
function padTo2Digits(num) {
	return num.toString().padStart(2, '0');
}
function check_slug(slug, id, container) {
	if (container == 'project_page')
		var entity = "project";
	else
		var entity = "category";

	$.ajax({
		method: 'POST',
		url: Routing.generate('check_slug', { 'slug': slug, 'id': id, 'entity': entity }),
		success: function (msg) {
			if (msg == "slug existe") {
				$('.' + container + ' #error_slug_msg').html($("#msg_error_slug").val());
				$('.' + container + ' #error_slug_msg').show();
				$('#' + entity + '_save').prop('disabled', true);
			} else {
				$('.' + container + ' #error_slug_msg').hide();
				$('#' + entity + '_save').prop('disabled', false);
			}
		}
	});
}
export function getModalTask(id, project_id, project_livrable_id, row = null, jobElementId = null) {
	$.ajax({
		type: "POST",
		url: Routing.generate("modal_edit_project_livrable_task"),
		data: {
			id,
			project_id,
			project_livrable_id,
		}
	}).done(function (res) {
		if (res !== "none") {
			$("#modal_edit_task_container").html(res);

			$("#modal_edit_task_container").attr('data-row', row ?? "");
			$("#modal_edit_task_container").attr('data-jobElementId', jobElementId);

			$("#modal_edit_task_container").modal({
				type: 'confirm',
				buttons: null,
				size: 'small',
				center: true,
				clickClose: false,
				escapeClose: false,
				showClose: false
			});
			$(".erreur_text").remove();

			let $taskStartDate = $("#modal_edit_task_container #task_start_date");
			let str_date = $taskStartDate.val();

			if (typeof str_date === 'string' && str_date.trim() !== '') {
				let datetime_parts = str_date.split(" ");
				datetime_parts[0] = datetime_parts[0].split('-').reverse().join('-');
				str_date = datetime_parts.join(" ");
				initTaskDates(str_date, true);
			}
		}
	});
}

function getMaxDateSwitchDuration(start_date, maxStartTime, minStartTime) {
	let maxDateObject = new Date(start_date);

	if ($("#project_livrables_jours").val()) maxDateObject.setDate(maxDateObject.getDate() + parseInt($("#project_livrables_jours").val()));
	if ($("#project_livrables_heure").val()) {
		maxDateObject.setHours(maxDateObject.getHours() + parseInt($("#project_livrables_heure").val()) - 1);

		if (maxDateObject.getHours() > parseInt(maxStartTime)) {
			maxDateObject.setDate(maxDateObject.getDate() + 1);
			let hoursDiff = maxDateObject.getHours() - parseInt(maxStartTime);
			maxDateObject.setHours(parseInt(minStartTime) + hoursDiff - 1);
		}
	}

	return globalThis.formatDate(maxDateObject) + " " + padTo2Digits(maxDateObject.getHours()) + ":59";
}
globalThis.getMaxDateSwitchDuration = getMaxDateSwitchDuration;

export function initTaskDates(str_date, isEdit) {
	let modal_id = isEdit ? "modal_edit_task_container" : "modal_add_task_container";
	const entreprise_hours = Object.values(JSON.parse($("#entreprise_hours").val()));
	let cell_start_date = new Date(str_date);
	let minDate = globalThis.formatDate(cell_start_date);

	let opening_days = Object.keys(JSON.parse($("#weekdays").val()));
	opening_days = opening_days.map((day) => parseInt(day));

	let minStartTime = padTo2Digits(entreprise_hours[0]) + ":00";
	let minTime = minStartTime;

	if (globalThis.selected_btn == "btn_week" || globalThis.selected_btn == "btn_day") {
		minTime = padTo2Digits(cell_start_date.getHours()) + ":00";
	}

	let maxStartTime = padTo2Digits(parseInt(entreprise_hours.reverse()[0])) + ":00";
	let maxDate = globalThis.getMaxDateSwitchDuration(cell_start_date, maxStartTime, minStartTime);

	let task_reel_end_datetime = null;
	let task_start_datetime = $("#" + modal_id + " #task_start_date").flatpickr({
		enableTime: true,
		time_24hr: true,
		dateFormat: "d-m-Y H:i",
		defaultDate: isEdit ? $("#" + modal_id + " #task_start_date").val() : minDate + " " + minTime,
		minTime: minStartTime,
		maxTime: maxStartTime,
		minuteIncrement: 1,
		static: true,
		position: "auto",
		"disable": [
			function (date) {
				return !opening_days.includes(date.getDay());
			}
		],
		"locale": {
			"firstDayOfWeek": 1
		},
		onChange: function (selectedDates, dateStr, instance) {
			task_end_datetime.set('minDate', dateStr);
		}
	});

	let defaultDate = isEdit ? $("#" + modal_id + " #date_fin_previsionnel").val() : maxDate;

	let maxEndTime = (parseInt(maxStartTime) + 1) + ":00";
	let task_end_datetime = $("#" + modal_id + " #date_fin_previsionnel").flatpickr({
		enableTime: true,
		time_24hr: true,
		dateFormat: "d-m-Y H:i",
		defaultDate: defaultDate,
		minDate: minDate + " " + minTime,
		minuteIncrement: 1,
		position: "auto",
		static: true,
		disable: [
			function (date) {
				return !opening_days.includes(date.getDay());
			}
		],
		locale: {
			firstDayOfWeek: 1
		}
	});


	if (isEdit) {
		task_reel_end_datetime = $("#modal_edit_task_container #date_fin_reel").flatpickr({
			enableTime: true,
			time_24hr: true,
			dateFormat: "d-m-Y H:i",
			defaultDate: $("#modal_edit_task_container #date_fin_reel").val(),
			minDate: minDate + " " + minTime,
			maxDate,
			minuteIncrement: 1,
			position: "auto",
			"disable": [
				function (date) {
					return !opening_days.includes(date.getDay());
				}
			],
			"locale": {
				"firstDayOfWeek": 1
			},
			onChange: function (selectedDates, dateStr, instance) {
				if (globalThis.formatDate(instance.config.minDate) == globalThis.formatDate(selectedDates[0])) {
					let firstDateTime = padTo2Digits(instance.config.minDate.getHours() + ":" + instance.config.minDate.getMinutes());
					task_end_datetime.set("minTime", firstDateTime);
				} else
					task_end_datetime.set("minTime", minStartTime);

				if (globalThis.formatDate(instance.config.maxDate) == globalThis.formatDate(selectedDates[0])) {
					let lastDateTime = padTo2Digits(instance.config.maxDate.getHours() + ":" + instance.config.maxDate.getMinutes());
					task_end_datetime.set("maxTime", lastDateTime);
				} else
					task_end_datetime.set("maxTime", maxEndTime);
			},
		});
	}
}

export function check_identique_password(first_password_filed, second_password_filed, error_class_filed) {
	if (first_password_filed.val() != second_password_filed.val()) {
		first_password_filed.closest("form").find('button[type="submit"]').prop("disabled", true);
		error_class_filed.show();
	} else {
		first_password_filed.closest("form").find('button[type="submit"]').prop("disabled", false);
		error_class_filed.hide();
	}
}

export function initRichText(input) {
	if (input.length > 0) {
		let richText = input.richText();

		if (input.attr("readonly") || richText.hasClass("disabled")) {
			$(".richText-editor").attr("contenteditable", "false");
			$(".richText-editor").addClass("disabled");
		}

		if (input.prop('required')) {
			richText.closest('.richText').attr('required', true);
		}
	}
}

export function emptyMoneyField(moneyField, parms) {
	let currentValue = moneyField.val().replace(parms, "").replaceAll(" ", "");

	if (currentValue === "")
		moneyField.val("");
	else {
		currentValue = parseFloat(currentValue);

		if (currentValue === 0) {
			if (moneyField.hasClass('project_devis_montant'))
				moneyField.val("0.00" + parms);
			else
				moneyField.val("");
		}
		else {
			moneyField.val(currentValue);
			MoneyFormated(moneyField, parms);
		}
	}
}

export function MoneyFormated(container, parms) {
	let currentValue = container.val().replace(parms, "").replaceAll(" ", "");
	let inputValue = parseFloat(container.val());

	container.unmask('### ### ### ### ### ### ### ### ### ### ### ###.00' + parms, {
		reverse: true
	});

	container.val(inputValue);

	if (container.val()) {
		if ($(container).val().includes(".")) {
			let nb_decimals = container.val().split(".")[1].length;

			if (nb_decimals == 1) container.val(container.val() + "0");
		} else
			container.val(container.val() + "00");
	}

	container.mask('### ### ### ### ### ### ### ### ### ### ### ###.00 ' + parms, {
		reverse: true
	});

}

export function formatPrice(prix, currency) {
	let price_format;
	prix = prix.replaceAll(',', '.');
	prix = prix.replace(/[^0-9.,]/g, '');
	let number_format = new Intl.NumberFormat("fr-FR", {
		maximumFractionDigits: 2,
		minimumFractionDigits: 2
	});
	price_format = number_format.format(prix);
	if (price_format == NaN) return "";
	return price_format + " " + currency;
}




export function processMoneyFields(selector, currency) {
	let prix = $(selector).val();
	$(selector).val(formatPrice(prix, currency));
}

export const DEFAULT_DATEPICKER_PROPERTIES = {
	language: 'fr',
	dateFormat: 'dd/mm/yyyy',
	timeFormat: 'HH:mm',
	changeMonth: false,
	changeYear: false,
	dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
	dayNamesMin: ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"],
	dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
	gotoCurrent: true,
	monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Decembre"],
	monthNamesShort: ["Jan", "Fev", "Mar", "Avr", "Mai", "Juin", "Juil", "Aoû", "Sep", "Oct", "Nov", "Dec"],
	prevText: "Préc",
	nextText: "Suiv",
}


export function areSameDate(date1, date2) {

	const year1 = date1.getFullYear();
	const month1 = date1.getMonth();
	const day1 = date1.getDate();

	const year2 = date2.getFullYear();
	const month2 = date2.getMonth();
	const day2 = date2.getDate();

	return year1 === year2 && month1 === month2 && day1 === day2;
}


function formatState(item) {
	if (!item.id) return item.text;

	let parent = $("<span>");

	var flag = $("<span>", {
		class: "country_flag fi fi-" + item.id.toLowerCase(),
	});

	var name = $("<span>", {
		class: "country_name",
		text: " " + item.text
	});

	parent.append(flag);
	parent.append(name);

	return parent;
};






export function initSelectPays(element) {
	element.select2({
		sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),
		width: "100%",
		multiple: false,
		placeholder: element.attr("placeholder"),
		templateResult: formatState,
		templateSelection: formatState,
		language: {
			noResults: function () {
				return $("#message_Select_vide").val();
			}
		},
	});

	if (!element.val()) element.val("FR").trigger("change");
}

function dateFromString(dateText) {
	var dateParts = dateText.split("/");
	if (dateParts.length == 2) {
		dateParts[2] = new Date().getFullYear()
	}
	var dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);

	return dateObject;
}

export function initializeMinAndMaxDatepickers(min_date_id, max_date_id) {

	let minProperties = {
		...DEFAULT_DATEPICKER_PROPERTIES,
		defaultDate: ($(min_date_id).val() ? ($(min_date_id).val()) : new Date()),
		onSelect: function (dateText) {
			if (dateText.length > 0) {

				var minDateObject = dateFromString(dateText);

				$(max_date_id).datepicker({
					minDate: minDateObject
				});

				var currentMaxDate = $(max_date_id).val();
				var maxDateObject = dateFromString(currentMaxDate);

				if (maxDateObject && minDateObject > maxDateObject) {
					$(max_date_id).datepicker("setDate", minDateObject);
					$(max_date_id).val($(min_date_id).val());
				}
			} else {

				$(max_date_id).datepicker({
					minDate: ''
				});
			}
		}
	};

	var from = $(min_date_id).datepicker(minProperties);

	let maxProperties = {
		...DEFAULT_DATEPICKER_PROPERTIES,
		defaultDate: ($(max_date_id).val() ? dateFromString($(max_date_id).val()) : new Date()),
		minDate: ($(min_date_id).val() ? dateFromString($(min_date_id).val()) : null)
	}

	var to = $(max_date_id).datepicker(maxProperties);
}



function getCurrencyByClient(id_client) {
	$.ajax({
		method: 'POST',
		url: Routing.generate("get_client_currency"),
		data: { id_client },
		success: function (result) {
			$("#project_currency").val(result.key_currency).trigger('change');
			processMoneyFields(".main_money_field", result.currency);
		}
	});
}







export function getOpositeColor(hex) {
	if (hex.indexOf('#') === 0) hex = hex.slice(1);

	if (hex.length === 3) hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];

	if (hex.length !== 6) throw new Error('Invalid HEX color.');

	let color = {
		'r': parseInt(hex.slice(0, 2), 16),
		'g': parseInt(hex.slice(2, 4), 16),
		'b': parseInt(hex.slice(4, 6), 16)
	};

	let opositeColor = ((0.3 * (color['r'])) + (0.59 * (color['g'])) + (0.11 * (color['b'])) <= 128) ? '#FFF' : '#000';

	return opositeColor;
}



export function apiSearchEntreprises(q) {
	return $.ajax({
		type: "GET",
		url: $('#url_api_recherche_entreprises').val() + "?q=" + q,
		async: false,
	});
}

export function applySelect2(elements, markup = null) {
	elements.each(function () {
		$(this).select2({
			sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),
			width: '100%',
			placeholder: $(this).attr("placeholder"),
			...markup
		});
	});

}


$(document).ready(function () {
	if ($("#mettings_date").length) {
		$("#mettings_date").flatpickr({
			enableTime: true,
			time_24hr: true,
			dateFormat: "d-m-Y H:i"
		});
	}

	$('#user_jour_feries_repetitif').on('change', function () {
		$("#user_jour_feries_date_debut").val("");
		$("#user_jour_feries_date_fin").val("");
		$("#user_jour_feries_date").val("");

		if ($('#user_jour_feries_repetitif').is(':checked')) {
			$("#div_user_jour_feries_date").show();
			$("#div_user_jour_feries_dates").hide();
			$("#user_jour_feries_date").prop("required", true)
			$("#user_jour_feries_date_fin").prop("required", false)
			$("#user_jour_feries_date_debut").prop("required", false)
			$("#user_jour_feries_date_debut").removeAttr("required")
			$("#user_jour_feries_date_fin").removeAttr("required")
		} else {
			$("#div_user_jour_feries_date").hide();
			$("#div_user_jour_feries_dates").show();
			$("#user_jour_feries_date").prop("required", false)
			$("#user_jour_feries_date_fin").prop("required", true)
			$("#user_jour_feries_date_debut").prop("required", true)

		}

	});

	if ($(".select2").length) {
		let markup = $('.select2 option[html="html"]').length > 0 ? { escapeMarkup: function (text) { return text; } } : {};

		applySelect2($(".select2"), markup);

	}

	if ($(".project_devis_montant").length) {
		var currency = $("#currency").val();
		processMoneyFields(".project_devis_montant", currency);
	}


	if ($("#project_devis_date_envoi").length > 0 && $("#project_devis_date_validation").length > 0) {
		initializeMinAndMaxDatepickers("#project_devis_date_envoi", "#project_devis_date_validation");
	}

	if ($("#project_factures_date_envoi").length > 0 && $("#project_factures_date_reglement").length > 0) {
		initializeMinAndMaxDatepickers("#project_factures_date_envoi", "#project_factures_date_reglement");
	}

	if ($("#user_jour_feries_date_debut").length > 0 && $("#user_jour_feries_date_fin").length > 0) {
		initializeMinAndMaxDatepickers("#user_jour_feries_date_debut", "#user_jour_feries_date_fin");
	}
	if ($("#user_jour_feries_date").length > 0) {
		var DATEPICKER_PROPERTIES = DEFAULT_DATEPICKER_PROPERTIES;
		DATEPICKER_PROPERTIES.dateFormat = "dd/mm"
		let Properties = {
			...DATEPICKER_PROPERTIES,

			defaultDate: ($("#user_jour_feries_date_debut").val() ? ($("#user_jour_feries_date_debut").val()) : ""),
			onSelect: function (dateText) {

				$("#user_jour_feries_date_debut").val(dateText);
				$("#user_jour_feries_date_fin").val(dateText);
			}
		};

		$("#user_jour_feries_date").datepicker(Properties);

	}

	if ($(".js-datepicker").length) {
		$(".js-datepicker").each(function (index) {
			$(this).datepicker({
				...DEFAULT_DATEPICKER_PROPERTIES
			});
		});
	}




	$('.onlyNumber').on('input', function () {
		this.value = this.value.replace(/[^0-9]/g, '');
	});

	if ($(".money_field").length > 0) {
		let currency = $("#currency").val();
		$(".money_field").each(function () {
			let montan = $(this).val();
			$(this).val(formatPrice(montan, currency));
		});
		$(".facture_card .money_field").each(function () {
			let montan = $(this).text();
			$(this).text(formatPrice(montan, currency));
		});
		$(".facture_card .money_field").show();
	}


	$(document).on('change', '.money_field', function () {
		let currency = $("#currency").val();
		let montan = $(this).val();
		$(this).val(formatPrice(montan, currency));
	})


	$(document).on('change', '#project_currency', function () {
		var currency = $(this).find(':selected').text();
		processMoneyFields(".main_money_field", currency);
	});


	if ($(".main_money_field").length) {
		var is_edit_project = $("#is_edit_project").val();
		if (is_edit_project == 1) {
			var currency = $("#project_currency").find(':selected').text();
			processMoneyFields(".main_money_field", currency);
		} else {
			var id_client = $("#project_client option:selected").val();
			getCurrencyByClient(id_client);
		}

	}

	$(document).on('change', '#project_client', function () {
		var is_edit_project = $("#is_edit_project").val();
		if (is_edit_project != 1) {
			var id_client = $(this).find(':selected').val();
			getCurrencyByClient(id_client);
		}

	});



	$('.search_field .datatable_search').on("keyup", function () {
		$('.dataTables_filter input[type="search"]').val($(this).val()).trigger("keyup");
	});

	var modal_msg_index = $('#modal_msg_index').val();
	var current_slide_position = 0;

	if ((typeof ($('#success_msg').val()) !== "undefined") && $('#success_msg').val() != "") {
		var msg = $('#success_msg').val();
		$.notify({
			icon: '',
			message: msg

		}, {
			type: 'success',
			timer: 4000
		});

	}



	if ((typeof ($('#error_msg').val()) !== "undefined") && $('#error_msg').val() != "") {
		if ($('#error_msg').val() != "") {
			var msg = $('#error_msg').val();
			$.notify({
				icon: '',
				message: msg

			}, {
				type: 'danger',
				timer: 4000
			});
		}
	}



	$("#demander_acces").click(function () {
		$("#form_demander_acces").slideDown()
		$("#loginform").slideUp()
		$(".btn_retour").show();
	})



	$("#form_demander_acces_retour").click(function () {
		$(".btn_retour").hide();
		$("#form_demander_acces").slideUp()
		$("#loginform").slideDown()
	})



	$(document).on('click', '.prev_slide', function () {
		showDivs(current_slide_position - 1);
	});



	$(document).on('click', '.next_slide', function () {
		showDivs(current_slide_position + 1);
	});



	$(document).on('click', '.closeModal', function () {
		$("#myModal").hide();
	});



	function showDivs(position) {
		var mySlides = document.getElementsByClassName("mySlides");

		if (position < 0) {
			position = mySlides.length - 1;
		} else if (position > (mySlides.length - 1)) {
			position = 0;
		}

		current_slide_position = position;

		$("#myModal.slider .content_image_hd .mySlides").hide();
		$("#myModal.slider .content_image_hd .mySlides").eq(position).css("display", "flex");
	}

	function countErrors(container) {
		if (container && container.length) {
			var errorCount = container.find('.error_msg').filter(function () {
				return $(this).css('display') !== 'none';
			}).length;

			return errorCount;
		} else {
			return 0;
		}
	}


	$(document).on('change', '.check_email', function () {
		var email = $(this).val();
		if (email != "") {
			var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
			if (emailRegex.test(email)) {
				$('#email_invalid').hide();
				$.ajax({
					method: 'POST',
					url: Routing.generate("check_email_exist"),
					data: { email },
					success: function (data) {
						$('#email_invalid').hide();
						if (data.exist == "yes") {
							$('#email_found').show();
						} else {
							$('#email_found').hide();
						}

					}
				});
			} else {
				$('#email_found').hide();
				$('#email_invalid').show();
			}
		} else {
			$('#email_invalid').hide();
		}

	});

	$(function () {
		$(document).on('click', 'a[data-modal]', function () {
			var id = $(this).attr('data-modal');
			$(id).modal();
			return false;
		});
	});


	$(document).on('click', '.supprimer', function () {

		$("#Supp-modal .modalmsg").html(modal_msg_index);
		$("#Supp-modal .supprimer-button").attr("data-id", $(this).attr("data-id"));

	})

	$(document).on("click", ".DeleteTicketStatus", function () {
		$('#Supp-modal .modalmsg').text($("#tickets_statuts_remove_model_msg").val());
		$("#Supp-modal .supprimer-button").attr("data-id", $(this).attr("data-id"))
	});


	$(document).on('change', '#slct', function () {
		var path = "";

		if ($(this).val() == "#") {
			if ($('.content .category_page').length) path = "new_language_category";
			if ($('.content .project_page').length) path = "new_language_project";
			if ($('.content .project_part_page').length) path = "new_language_project_part";

			$.ajax({
				method: 'GET',
				url: Routing.generate(path, { 'id': $('#id_item').val(), 'local': $("#slct :selected").text(), 'type': $("#edit_page").val() }),
				success: function (data) {
					window.location = data;
				}
			});
		} else {
			window.location = $(this).val();
		}
	});

	$('#form_demander_acces').off('submit').on('submit', function (event) {
		event.preventDefault();
		var error_number = countErrors($(this));
		if (error_number <= 0) {
			var nom = $("#nom_user").val();
			var prenom = $("#prenom_user").val();
			var email = $("#email_user").val();
			var company = $("#company_name").val();
			if (nom != "" && prenom != "" && email != "" && company != "") {
				$.ajax({
					method: $(this).attr('method'),
					url: Routing.generate('check_demander_acces'),
					data: { nom, prenom, email, company },
					success: function (res) {
						$.notify({
							icon: '',
							message: res.msg

						}, {
							type: !res.exist ? 'success' : 'danger',
							timer: 4000
						});
					}
				});
			}
		}

	});



	$(document).on('change', '#slug_category .slug_category, #slug_project .slug_project', function () {
		var slug = $(this).val();
		var id = $("#id_item").val();

		if ($(this).hasClass('slug_category'))
			check_slug(slug, id, 'category_page');
		else
			check_slug(slug, id, "project_page");
	});





	$(document).on('keyup', '#slug_category .slug_category, #slug_project .slug_project', function () {
		var slug = $(this).val();
		var id = $("#id_item").val();

		if ($(this).hasClass('slug_category'))
			check_slug(slug, id, "category_page");
		else
			check_slug(slug, id, "project_page");
	});

	$(document).on('click', '.supprimer-button', function () {
		var id = $(this).attr("data-id");
		var locale = $("#local").val();
		var pathName = $("#pathNameSupp").val();

		$.ajax({
			method: 'GET',
			url: Routing.generate(pathName, {
				'id': id,
				'locale': locale
			}),
			success: function success(data) {
				if (typeof data == "string") {
					location.reload();
				} else {
					window.location.href = data.url;
				}

			}
		});
	});

	$(document).on("input", '#user_password_first', function () {
		check_identique_password($("#user_password_first"), $("#user_password_second"), $(".error_form_password"));
	});

	$(document).on("input", '#user_password_second', function () {
		check_identique_password($("#user_password_first"), $("#user_password_second"), $(".error_form_password"));
	});

	if ($("#form_snippet_image").length) {
		var myDropzone = new Dropzone('#form_snippet_image', {
			addRemoveLinks: true,
			uploadMultiple: true,
			autoProcessQueue: false,
			parallelUploads: 100,
			timeout: null,
			url: $('#upload_images').val(),
			init: function () {

				this.on('addedfile', function (file) {

					var num = parseInt(this.files.length);

					var file_data = $(this)[0].files[num - 1];

					var project_id = $("#form_snippet_image").data('id');
					var part = $("#form_snippet_image").data('part');
					var form_data = new FormData();

					form_data.append('file', file_data);
					form_data.append('idpart', part);
					form_data.append('part', project_id);

					$.ajax({
						method: 'POST',
						url: $('#upload_images').val(),
						dataType: 'text',
						cache: false,
						contentType: false,
						processData: false,
						data: form_data,
						success: function (id) {
							var removeLink = $(file.previewElement).find('.dz-remove');
							removeLink.attr('data-id', id);

						}
					});

				});

				this.on('removedfile', function (file) {
					var fileId = $(file.previewElement).find('.dz-remove').attr('data-id');
					$.ajax({
						method: 'GET',
						url: Routing.generate('update_status_archive', { 'id': fileId }),
						success: function (data) {

						}
					})
				});

			}
		});

	}


	$(document).on("click", '#modalConfirmAction #confirm_btn', function () {
		$.ajax({
			method: 'POST',
			url: $(this).attr("data-href"),
			data: {
				comment: $("#comment").val()
			},
			success: function (res) {
				if (res && res.redirectUrl) window.location.href = res.redirectUrl;
			}
		})
	})


	$(document).on("click", '.btn_accept_refuse_test, .btnShowModalConfirmAction', function () {
		$("#modalConfirmAction #confirm_btn").attr("data-href", $(this).attr("data-href"));
	})



});