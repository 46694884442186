import {GanttCalendarRenderer} from './GanttCalendarRenderer.js';
 
const template = document.createElement('template');

template.innerHTML = $("#template_gantt").html();

export default class VanillaGanttChart extends HTMLElement {

    constructor() {
		super();

		this.attachShadow({ mode: 'open' });
		this.shadowRoot.appendChild(template.content.cloneNode(true));
    }

    _resources = [];
    _jobs = [];
    _renderer;

    set resources(list){
		this._resources = list;

		if(this.renderer){
			this.renderer.resources = this._resources;
			this.renderer.render();
		}
    }

    get resources(){
		return this._resources;
    }

    set jobs(list){
		this._jobs = list;

		if(this.renderer){
			this.renderer.jobs = this._jobs;
			this.renderer.render();
		}
    }

    get jobs(){
		return this._jobs;
    }

    get renderer(){
		return this._renderer;
    }

    set renderer(r){
		this._renderer = r;
    }

    connectedCallback() {
		this.changeLevel = this.changeLevel.bind(this);

		this.level = "day";   
		this.renderer = new GanttCalendarRenderer(this.shadowRoot);
	  
		this.renderer.dateFrom = globalThis.calendar_start_date;
		this.renderer.dateTo = globalThis.calendar_end_date;
		this.renderer.render();
    }

    disconnectedCallback() {
		if(this.renderer)		this.renderer.clear();
    }

    changeLevel(){	
		if(this.renderer)		this.renderer.clear();
			
		var r;
		  
		globalThis.getItems();
		r = new GanttCalendarRenderer(this.shadowRoot);
		  
		r.dateFrom = globalThis.calendar_start_date;
		r.dateTo = globalThis.calendar_end_date;
		r.resources = this.resources;
		r.jobs = this.jobs;
		r.render();

		this.renderer = r;
    }
}


if(!window.customElements.get('gantt-chart'))		window.customElements.define('gantt-chart', VanillaGanttChart);

  