$(document).ready(function () {
    const locale = $('#local').val();

    if ($('#datatable_user_jour_feries').length) {

        var userJourFeriesTable = $('#datatable_user_jour_feries').DataTable({
            "columnDefs": [
                { "data": "id", "targets": 0 },
                { "data": "libelle", "targets": 1 },
                { "data": "date_debut", "targets": 2 },
                 { "data": "action", "targets": 3, orderable: false },
            ],
            "processing": true,
            "serverSide": true,
            "ajax": Routing.generate('server_processing_user_jour_feries'),
            "paging": true,
            "pageLength": 20,
            "lengthChange": false,
            "searching": true,
            "ordering": true,
            "order": [[0, "desc"]],
            "info": false,
            "autoWidth": false,
            "responsive": true,
            "scrollX": true,
            "language": {
                "url": $("#datatable_lang").val()
            },
        });
    }
   

});