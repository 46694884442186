import VanillaGanttChart from "./VanillaGanttChart.js";
import { getModalTask } from "../custom/global.js"

if (document.querySelector("#client_tasks_graphique")) {
    var chart = document.querySelector("#client_tasks_graphique");

    var lastItemId = 0;

    function getData(is_local_update = false) {
        $.ajax({
            type: "POST",
            url: $('#project_client_list_url').val(),
            data: {
            }
        }).done(function (res) {
            let data = JSON.parse(res);
            let lastId = 1;
            let rows = [];
            let i = 0;

            for (const [rowKey, value] of Object.entries(data.projects)) {
                let localElement = {
                    id: lastId++,
                    object_id: value.id,
                    label: value.name,
                    type: 'projects',
                    path: value.path,
                    color: value.color
                }

                i++;
                rows.push(localElement);
            }

            let urlParams = new URLSearchParams(window.location.search);

            if (urlParams.has("currentYear") && urlParams.has("currentMonth") && urlParams.has("currentDay")) {
                if (urlParams.has("currentTime") && urlParams.get("currentView") === "btn_year") {
                    let start_date_calendar = new Date(parseInt(urlParams.get("currentTime")));
                    globalThis.year = start_date_calendar.getFullYear();
                    urlParams.delete("currentYear");

                    globalThis.month = start_date_calendar.getMonth();
                    urlParams.delete("currentMonth");

                    globalThis.date_jour = start_date_calendar.getDate();
                    urlParams.delete("currentDay");

                    globalThis.selected_btn = "btn_week";
                    urlParams.delete("currentView");
                } else {
                    globalThis.year = parseInt(urlParams.get("currentYear"));
                    urlParams.delete("currentYear");

                    globalThis.month = parseInt(urlParams.get("currentMonth"));
                    urlParams.delete("currentMonth");

                    globalThis.date_jour = parseInt(urlParams.get("currentDay"));
                    urlParams.delete("currentDay");

                    globalThis.selected_btn = urlParams.get("currentView");
                    urlParams.delete("currentView");
                }

                let url = window.location.pathname;

                window.history.pushState({}, document.title, url);
            }

            chart.resources = rows;

            globalThis.updateTime();
        });
    }


    function getDateAndTypesForItems() {
        var show_type = "day";
        var vue_type = "month";

        if (globalThis.selected_btn == "btn_week") {
            show_type = "hour";
            vue_type = "week";
        }

        var start_date = globalThis.calendar_start_date;
        let start_date_str = globalThis.formatDate(start_date);

        if (typeof startDate !== 'undefined' && startDate && start_date.valueOf() < startDate.valueOf()) {
            start_date_str = globalThis.padTo2Digits(startDate.getDate()) + "-" + globalThis.padTo2Digits(startDate.getMonth() + 1) + "-" + startDate.getFullYear();
        }

        var end_date = globalThis.calendar_end_date;
        let end_date_str = globalThis.formatDate(end_date);

        if (typeof endDate !== 'undefined' && endDate && end_date.valueOf() > endDate.valueOf()) {
            end_date_str = globalThis.padTo2Digits(endDate.getDate()) + "-" + globalThis.padTo2Digits(endDate.getMonth() + 1) + "-" + endDate.getFullYear();
        }

        return {
            show_type,
            vue_type,
            start_date_str,
            end_date_str
        };
    }



    function getItems() {
        let { start_date_str, end_date_str } = getDateAndTypesForItems();

        chart.resources.map(project => {
            $.ajax({
                type: "POST",
                url: $("#project_tasks_by_period").val(),
                data: { project_id: project['object_id'], start_date: start_date_str, end_date: end_date_str }
            }).done(function (res) {
                let data = JSON.parse(res);
                data.map((item, itemKey) => {
                    getItemObject(item, project['id'], itemKey);
                })
            })

            // $.ajax({
            //     type: "POST",
            //     url: $("#getUserCongerParPeriod").val(),
            //     data: { user_id: user['object_id'], start_date: start_date_str, end_date: end_date_str }
            // }).done(function (res) {
            //     let data = JSON.parse(res);
            //     data.map((item, itemKey) => {
            //         getItemObject(item, user['id'], itemKey);
            //     })
            // })


        });


        if (globalThis.currentScrollLeft) {
            var container = chart.shadowRoot.querySelector("#gantt-container");
            container.scrollLeft = globalThis.currentScrollLeft;
            container.dispatchEvent(new Event('scroll'));
            globalThis.currentScrollLeft = null;
        }

        return;
    }
    globalThis.getItems = getItems;


    async function getItemObject(item, rowId, itemKey) {
        let start = new Date(item.start_date);
        let end = new Date(item.end_date);

        let item_element = {
            id: ++lastItemId,
            resource: rowId,
            start,
            end,
            color: item.color ? item.color : rowData.color,
            description: " ",
            object_id: item.id
        };

        globalThis.initJobs(globalThis.setChartJobs([item_element]))
    }


    globalThis.handleCellClick = function (row, str_date, row_type) {

    }



    globalThis.handleDeleteItem = function (dataset, element) {

    }

    globalThis.handleItemDblClick = function (jobElementId, dataset) {
        if ($("#RoleUser").val() == "ROLE_ADMIN" || $("#RoleUser").val() == "ROLE_USER") {
            getModalTask(dataset.object_id, $("#current_project_id").val(), $("#current_project_livrable_id").val(), dataset.row, jobElementId);
        }
    }

    getData();

    $(document).ready(function () {

    })
}