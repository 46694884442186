import { processMoneyFields, initRichText, getModalTask, initTaskDates, formatPrice } from "../custom/global.js"

var isLoading = false;
let PojectDataTable = null;
let ProjectLivrable = null;
let ProjectDocuments = null;
let ProjectDevis = null;
let DemandesClient = null;


function saveDoc() {
	var formData = new FormData();
	var file = $("#file-upload")[0].files[0];

	formData.append('file', file);
	formData.append('livrable_id', $("#current_livrable_id").val());

	$.ajax({
		url: Routing.generate("ajax_uploadFile"),
		data: formData,
		processData: false,
		contentType: false,
		type: 'POST',
	}).done(function (html) {
		if (html != "none") {
			$(".document-container").html('');
			server_processing_livrable_documents($("#current_livrable_id").val(), 0);
		}
	});
}






function server_processing_livrable_documents(livrable_id, start) {
	$.ajax({
		url: Routing.generate("server_processing_project_livrable_documents"),
		data: { livrable_id, start },
		type: 'POST',
	}).done(function (res) {
		let data = JSON.parse(res);
		$(".document-container").append(data.html);
		$("#current_state").val(data.continue ? 1 : 0);
		$("#current_start").val(data.start);
		isLoading = false;
	});
}







function notify(message) {
	$.notify({
		icon: '',
		message: message
	}, {
		type: 'danger',
		timer: 1000
	});
}






function getCurrentPageFromUrl() {
	var fragment = window.location.hash;

	return fragment.substring(1);
}




function OnLoadPojectDataTable() {
	let showAllProject = false;

	let Col = [
		{ "data": "id", "targets": 0 },
		{ "data": "Libelle", "targets": 1 },
	];

	var role = $(".main-panel #RoleUser").val() ?? $("#RoleUser").val();

	if (role == "ROLE_ADMIN" || role == "ROLE_USER") {
		Col.push({ "data": "client", "targets": 2, "orderable": false });
		Col.push({ "data": "Category", "targets": 3, "orderable": false });
		Col.push({ "data": "etat", "targets": 4, "orderable": false });

		if (role == "ROLE_ADMIN") {
			Col.push({ "data": "Nombres_vues", "targets": 5, "orderable": false });
			Col.push({ "data": "Action", "targets": 6, "orderable": false });
		}
	} else {
		Col.push({ "data": "Category", "targets": 2, "orderable": false });
		Col.push({ "data": "etat", "targets": 3, "orderable": false });
	}

	let table = $("#project_list").DataTable({
		"columnDefs": Col,
		"processing": true,
		"serverSide": true,
		"ajax": Routing.generate('server_processing_projects', { "iso_code": $("#local").val(), "show_all_projects": showAllProject }),
		"paging": true,
		"pageLength": 10,
		"lengthChange": false,
		"lengthMenu": [[20, 50, 100, -1], [20, 50, 100, "All"]],
		"language": {
			url: "/datatable_fr.json",
			paginate: {
				next: "<i class='ti-arrow-right'></i>",
				previous: "<i class='ti-arrow-left'></i>"
			}
		},
		"order": [[0, "desc"]],
		"info": false,
		"ordering": true,
		"searching": true,
		"bLengthChange": false,
		"scrollX": true,
	});

	return table;
}






function OnLoadPojectLivrableDataTable() {
	var role = $(".main-panel #RoleUser").val() ?? $("#RoleUser").val();
	var is_prestataire = $("#is_prestataire").val()

	let Col = [
		{ "data": "titre", "targets": 0 },
		{ "data": "etat", "targets": 1 },
		{ "data": "duree", "targets": 2 },
	];

	if (role == "ROLE_ADMIN" || $("#currentUserRole").val() == "ROLE_USER") {
		Col = [...Col, { "data": "developper", "targets": 3 }];
	}
	if ($("#currentUserRole").val() != "ROLE_USER") {
		Col = [...Col, { "data": "budget_devis", "targets": Col.length }]
	}

	Col = [...Col, { "data": "Action", "targets": Col.length, "orderable": false }]


	let urlDataTable = Routing.generate('server_processing_project_livrables', {
		"iso_code": $("#local").val(),
		"id_projectDevis": $("#id_projectDevis").val(),
		"id_project": $("#id_project").val()
	})

	let table = $("#project_livrable_list").DataTable({
		"columnDefs": Col,
		"processing": true,
		"serverSide": true,
		"ajax": urlDataTable,
		"paging": true,
		"pageLength": 10,
		"lengthChange": false,
		"lengthMenu": [[20, 50, 100, -1], [20, 50, 100, "All"]],
		"language": {
			url: "/datatable_fr.json",
			paginate: {
				next: "<i class='ti-arrow-right'></i>",
				previous: "<i class='ti-arrow-left'></i>"
			}
		},
		"order": [[0, "desc"]],
		"info": false,
		"ordering": true,
		"searching": true,
		"bLengthChange": false,
		"scrollX": true,
	});

	return table;
}





function OnLoadDemandesClientDataTable() {
	let table = $("#demandes_clients").DataTable({
		"columnDefs": [
			{ "data": "checkbox", "targets": 0 },
			{ "data": "livrable", "targets": 1 },
			{ "data": "client", "targets": 2 },
			{ "data": "project", "targets": 3 },
			{ "data": "etat", "targets": 4 },
			{ "data": "devis", "targets": 5 },
			{ "data": "duree", "targets": 6, "orderable": false },
			{ "data": "user", "targets": 7, "orderable": false }
		],
		"processing": true,
		"serverSide": true,
		"ajax": {
			"url": Routing.generate('server_processing_demandes_client'),
			"type": "POST",
			"data": function (d) {
				d.iso_code = $('#local').val();
				d.etat = $('#etat_demandes_clients').val();
				d.start = $('#date_debut_recherche_demandes_clients').val();
				d.end = $('#date_fin_recherche_demandes_clients').val();
			},
		},
		"paging": true,
		"pageLength": 10,
		"lengthChange": false,
		"lengthMenu": [[20, 50, 100, -1], [20, 50, 100, "All"]],
		"language": {
			url: "/datatable_fr.json",
			paginate: {
				next: "<i class='ti-arrow-right'></i>",
				previous: "<i class='ti-arrow-left'></i>"
			}
		},
		"order": [[0, "desc"]],
		"info": false,
		"ordering": true,
		"searching": true,
		"bLengthChange": false,
		"scrollX": true,
	});

	return table;
}




function OnLoadProjectDocumentsDataTable() {
	let tableProjectDocuments = $("#Project_documents_list").DataTable({
		"columnDefs": [
			{ "data": "id", "targets": 0 },
			{ "data": "titel", "targets": 1 },
			{ "data": "action", "targets": 2, "orderable": false },
		],
		"processing": true,
		"serverSide": true,
		"ajax": Routing.generate('server_processing_project_documents', { "idProject": $("#id_projet").val() }),
		"paging": true,
		"pageLength": 10,
		"lengthChange": false,
		"lengthMenu": [[20, 50, 100, -1], [20, 50, 100, "All"]],
		"language": {
			url: "/datatable_fr.json",
			paginate: {
				next: "<i class='ti-arrow-right'></i>",
				previous: "<i class='ti-arrow-left'></i>"
			}
		},
		"order": [[0, "desc"]],
		"info": false,
		"ordering": true,
		"searching": true,
		"bLengthChange": false,
		"scrollX": true,
	});

	return tableProjectDocuments;
}



let columnDefs = [
	{ "data": "id", "targets": 0 },
	{ "data": "date_envoi", "targets": 1 },
	{ "data": "date_validation", "targets": 2 },
	{ "data": "montant", "targets": 3 },
	{ "data": "Action", "targets": 4, "orderable": false },
]
if ($("#RoleUser").val() == "ROLE_ADMIN") {
	columnDefs = [
		{ "data": "id", "targets": 0 },
		{ "data": "date_envoi", "targets": 1 },
		{ "data": "type", "targets": 2, "orderable": false },
		{ "data": "date_validation", "targets": 3 },
		{ "data": "montant", "targets": 4 },
		{ "data": "Action", "targets": 5, "orderable": false },
	]
}


function OnLoadPojectDevisDataTable() {
	let table = $("#project_devis_list").DataTable({
		"columnDefs": columnDefs,
		"processing": true,
		"serverSide": true,
		"ajax": Routing.generate('server_processing_project_devis', { "iso_code": $("#local").val(), "id_project": $("#id_project").val() ,"client_id":$("#client_id").val() }),
		"paging": true,
		"pageLength": 10,
		"lengthChange": false,
		"lengthMenu": [[20, 50, 100, -1], [20, 50, 100, "All"]],
		"language": {
			url: "/datatable_fr.json",
			paginate: {
				next: "<i class='ti-arrow-right'></i>",
				previous: "<i class='ti-arrow-left'></i>"
			}
		},
		"order": [[0, "desc"]],
		"info": false,
		"ordering": true,
		"searching": true,
		"bLengthChange": false,
		"scrollX": true,
	});

	return table;
}
let table = null

function onLoadAffectationsProjetDataTable() {
	table = $("#affectation_projet").DataTable({
		"columnDefs": [
			{ "data": "projet", "targets": 0 },
			{ "data": "livrable", "targets": 1 },
			{ "data": "user", "targets": 2 },
			{ "data": "tache", "targets": 3 },
			{ "data": "dateDebut", "targets": 4 },
			{ "data": "dateFin", "targets": 5 },

		],
		"processing": true,
		"serverSide": true,
		"ajax": {
			"url": Routing.generate('server_processing_affectation_project'),
			"type": "POST",
			"data": function (d) {
			},
		},
		"paging": true,
		"pageLength": 10,
		"lengthChange": false,
		"lengthMenu": [[20, 50, 100, -1], [20, 50, 100, "All"]],
		"language": {
			url: "/datatable_fr.json",
			paginate: {
				next: "<i class='ti-arrow-right'></i>",
				previous: "<i class='ti-arrow-left'></i>"
			}
		},
		"order": [[0, "desc"]],
		"info": false,
		"ordering": true,
		"searching": true,
		"bLengthChange": false,
		"scrollX": true,
	});


}





function getLastDayOfthisMonth() {
	const today = new Date();
	const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

	return nextMonth.getDate();
}





function areAllElementsSame(array) {
	if (array.length <= 1) return true;


	for (var i = 1; i < array.length; i++) {
		if (array[i] !== array[0]) return false;
	}

	return true;
}





$(document).ready(function () {

	PojectDataTable = OnLoadPojectDataTable();

	ProjectLivrable = OnLoadPojectLivrableDataTable();

	ProjectDocuments = OnLoadProjectDocumentsDataTable();

	ProjectDevis = OnLoadPojectDevisDataTable();

	DemandesClient = OnLoadDemandesClientDataTable();

	let defaultDateDebut = new Date();
	defaultDateDebut.setDate(1);
	defaultDateDebut.setMonth(defaultDateDebut.getMonth());
	defaultDateDebut.setHours(0, 0, 0, 0);


	let defaultDateFin = new Date();
	defaultDateFin.setDate(getLastDayOfthisMonth());

	defaultDateFin.setHours(23, 59, 59, 999);

	if ($(".document-container").length) server_processing_livrable_documents($("#current_livrable_id").val(), $("#current_start").val());

	if ($("#affectation_projet").length) onLoadAffectationsProjetDataTable()

	let date_debut_recherche_demandes_clients = $("#date_debut_recherche_demandes_clients").flatpickr({
		enableTime: true,
		time_24hr: true,
		dateFormat: "d-m-Y H:i",
		defaultDate: defaultDateDebut,
		maxDate: defaultDateFin,
		minuteIncrement: 1,
		position: 'auto',
		"locale": {
			"firstDayOfWeek": 1
		},
		onChange: function (selectedDates, dateStr, instance) {
			date_fin_recherche_demandes_clients.set('minDate', selectedDates[0]);
			DemandesClient.ajax.params.start = dateStr;
			DemandesClient.ajax.reload();
		},
	});



	let date_fin_recherche_demandes_clients = $("#date_fin_recherche_demandes_clients").flatpickr({
		enableTime: true,
		time_24hr: true,
		dateFormat: "d-m-Y H:i",
		defaultDate: defaultDateFin,
		minDate: defaultDateDebut,
		minuteIncrement: 1,
		position: 'auto',
		"locale": {
			"firstDayOfWeek": 1
		},
		onChange: function (selectedDates, dateStr, instance) {
			date_debut_recherche_demandes_clients.set('maxDate', selectedDates[0]);
			DemandesClient.ajax.params.end = dateStr;
			DemandesClient.ajax.reload();
		},
	});




	if ($("#client_send_document_dropzone").length) {
		var myDropzone = new Dropzone('#client_send_document_dropzone', {
			addRemoveLinks: true,
			uploadMultiple: true,
			autoProcessQueue: false,
			parallelUploads: 100,
			timeout: null,
			url: $('#sending_project_request').attr('action'),
			init: function () {
				var submitButton = document.querySelector("#project_save");
				var myDropzone = this;

				$('#sending_project_request').off('submit').on('submit', function (event) {
					event.preventDefault();
					event.stopPropagation();

					if (myDropzone.getQueuedFiles().length > 0)
						myDropzone.processQueue();
					else
						$('#sending_project_request')[0].submit();

					myDropzone.processQueue();
				});

				this.on("sending", function (file, xhr, formData) {
					$("form[name='project']").serializeArray().forEach(function (item) {
						formData.append(item.name, item.value);
					});
				});


				this.on("successmultiple", function (files, response) {
					$.notify({
						icon: '',
						message: $("#succes_msg").val()

					}, {
						type: 'success',
						timer: 4000
					});

					setTimeout(function () {
						window.location.href = $("#url_my_projects").val();
					}, 800);
				});
			}
		});
	}




	$('.img_projects_all_div').Lazy();



	initRichText($('.description_project'))
	initRichText($('.content_project_part'))




	var currentPage = getCurrentPageFromUrl();

	if (currentPage) {
		$("#menuTabs li").removeClass("active");
		var currentTab = $(`#menuTabs [data-div="${currentPage}"]`);

		if (currentTab.length) {
			currentTab.parent().addClass("active");
			id_div = "#" + currentPage
			$(".tab").hide();
			$(id_div).show();
		}
	}





	$("#etat_demandes_clients").on('change', function () {
		DemandesClient.ajax.params.etat = $('#etat_demandes_clients').val();
		DemandesClient.ajax.reload();
	});






	$(document).on('click', '#add_more_results', function () {
		var id = $(this).data('id');
		var lastid = $("#image_box").attr("data-lastid");
		var positionget = $("#max_item").val();
		var position = parseInt(lastid) + parseInt(positionget);

		$("#image_box").attr("data-lastid", position);

		$.ajax({
			method: 'GET',
			url: Routing.generate('image_parts_ajax', { 'id': id, 'position': position }),
			success: function (html) {
				$('#image_box').append(html["html_content"]);
				var others = html["others_test"];

				if (others == false) $("#add_more_results").hide();
			}
		});
	});






	$(document).on("click", "#delete_cover", function () {
		var id = $(this).data('id');

		$.ajax({
			method: 'GET',
			url: Routing.generate('delete_cover', { 'id': id, 'local': $("#local").val() }),
			success: function (data) {
				$("#for_cover").load(window.location.href + " #for_cover");
				$(".cover_edit_project").show();
			}
		});
	});




	$(document).on("click", "#delete_source", function () {
		var id = $(this).data('id');

		$.ajax({
			method: 'GET',
			url: Routing.generate('delete_source', { 'id': id, 'local': $("#local").val() }),
			success: function (data) {
				$("#for_source").load(window.location.href + " #for_source");
				$("#download-source").hide();
				$("#source").show();
			}
		});
	});




	$(document).on('click', '.delete_file', function () {
		var id_image = $(this).data('id');

		$.ajax({
			method: 'GET',
			url: Routing.generate('delete_file', { 'id': id_image }),
			success: function (data) {
				$(".image_box_container").load(window.location.href + " #image_box");
				var i = 0;
				var pos = 0;

				$(".sites_photos").each(function () {
					i++;
				});

				pos = i;

				var clik = parseInt(parseInt(pos) / 8) + 1;

				$("#image_box").attr("data-lastid", 0);

				if (pos > 8) {
					for (let j = 0; j < clik; j++) {
						$("#add_more_results").click();
					}
				}

				var positionget = $("#max_item").val();
				var count_image = $("#count_image").val();

				if (parseInt(count_image) > parseInt(positionget) - 1) {
					$("#add_more_results").show();
				} else {
					$("#add_more_results").hide();
				}
			}
		});
	});








	$(document).on("click", ".file_download", function () {
		let file_id = $(this).data("file");

		$.ajax({
			url: Routing.generate('download_file'),
			data: { file_id },
			type: 'GET',
			success: function (path) {
				var a = document.createElement('a');
				a.href = path;
				a.download = path.split("/").reverse()[0];
				a.style.display = 'none';

				document.body.appendChild(a);

				a.click();

				document.body.removeChild(a)
			}
		});
	});




	$(function () {
		$("#project_part_list tbody").sortable({
			items: 'tr:not(tr:first-child)',
			cursor: 'pointer',
			axis: 'y',
			dropOnEmpty: false,
			start: function (e, ui) {
				ui.item.addClass("selected");
			},
			stop: function (e, ui) {
				ui.item.removeClass("selected");

				$(this).find("tr").each(function (index) {
					if (index > 0) {
						var id = $(this).find("td").eq(0).text().trim();

						var pos = index;
						$(this).find('td').eq(2).html(index);

						$.ajax({
							method: 'GET',
							url: Routing.generate('reorder', { 'id': id, 'pos': pos }),
							success: function (data) { }
						});
					}
				});


			}
			
		});

	});



	$(document).on('click', '.supprimer', function () {
		var id = $(this).attr("data-id");
		console.log(id)
		$.ajax({
			method: 'GET',
			url: Routing.generate('remove_project_part', { 'id': id }),
			success: function (data) {
				$(".project_part_list_content").load(window.location.href + " .project_part_list");

				
			}
		});
	});






	$(document).on('click', '.icon_top_project', function () {
		var msgConfirmation = $(this).attr('data-msg');
		var id = $(this).data('id');

		$(".modalmsg").html(msgConfirmation);
		$("#top_project_modal").attr("data-id", id);
		$("#top_project_modal").modal();

		return false;
	});






	$(document).on('click', '#top_project_modal #valider_top_project', function () {
		var id = $(this).parent().attr("data-id");
		$.ajax({
			method: 'GET',
			url: Routing.generate('valide_top_project', { 'id': id }),
			success: function (data) {
				if ($(".icon_top_project[data-id=" + id + "]").children().hasClass("uncheck")) {
					$(".icon_top_project[data-id=" + id + "]").children().removeClass("fa-long-arrow-up uncheck");
					$(".icon_top_project[data-id=" + id + "]").children().addClass("check").addClass("fa-long-arrow-up");
					$(".icon_top_project[data-id=" + id + "]").attr("data-msg", $("#non_top_project_model_msg").val());
				} else {
					$(".icon_top_project[data-id=" + id + "]").children().removeClass("fa fa-long-arrow-down check");
					$(".icon_top_project[data-id=" + id + "]").children().addClass("uncheck").addClass("fa-long-arrow-down");
					$(".icon_top_project[data-id=" + id + "]").attr("data-msg", $("#top_project_model_msg").val());
				}

				$.modal.close();
			}
		});

	});




	$(document).on('click', '.icons_project_activate', function () {
		var id = $(this).data("id");
		var etat = $(this).data("etat");
		var msg = "";
		var url = Routing.generate('activate_project', { 'id': id });

		if (etat == "actived")
			msg = $('#modal_text_disactived').val();
		else
			msg = $('#modal_text_actived').val();

		$(".modalmsg").html(msg);

		$("#activate_modal #activate").attr('data-id', id);
		$("#activate_modal #activate").attr('data-etat', etat);
		$("#activate_modal #activate").attr('data-url', url);
		$("#activate_modal").modal();

		return false;
	});






	$(document).on('click', '#activate_modal #activate', function () {
		var id = $(this).attr('data-id');
		var data_url = $(this).attr('data-url');
		var id_tab = $("#tableau_id").val();
		var url = "";

		if (data_url)
			url = data_url;
		else if ($("#activate_compte_user_front_url").length > 0)
			url = Routing.generate($("#activate_compte_user_front_url").val(), { 'id': id, '_locale': $("#local").val() })
		else
			url = Routing.generate("activate_user", { 'id': id, '_locale': $("#local").val() })


		$.ajax({
			method: 'POST',
			url: url,
			success: function (data) {
				var dataTable = $('#' + id_tab).DataTable();

				if (data == "ok")
					location.reload();
				else if (data != "user innexistant")
					dataTable.ajax.reload();

				$('#activate_modal').modal('hide');

			}
		});
	});






	$(document).on('click', '.icons_project_part_activate', function () {
		var id = $(this).data("id");
		var etat = $(this).data("etat");
		var msg = "";
		var url = Routing.generate('activate_project_part', { 'id': id });

		if (etat == "actived")
			msg = $('#modal_text_desactived').val();
		else
			msg = $('#modal_text_actived').val();

		$(".modalmsg").html(msg);

		$("#activate_modal #activate").attr('data-id', id);
		$("#activate_modal #activate").attr('data-etat', etat);
		$("#activate_modal #activate").attr('data-url', url);
		$("#activate_modal").modal();

		return false;
	});







	$(document).on('click', '.headTab', function () {
		var id_div = "#" + $(this).attr('data-Div')
		$(".headTab").parent().removeClass("active")
		$(this).parent().addClass("active")

		$(".tab").hide();
		$(id_div).show();

		const url = new URL(window.location.href);
		const searchParams = new URLSearchParams(url.search);

		searchParams.set('tab', $(this).attr('data-Div'));
		url.search = searchParams.toString()

		history.pushState({}, null, url.toString());
	});





	$(document).on('click', '.submitModalselectProjectDevis', function () {
		var ids = [];

		$('.checkboxDemandesClient:checked').each(function () {
			ids.push($(this).attr('data-id'));
		});

		if ($("#selectProjectDevis").val() && ids) {
			$.ajax({
				method: 'GET',
				url: Routing.generate('submitModalselectProjectDevis', {
					'ids': ids,
					'idDevis': $("#selectProjectDevis").val()
				}),
				success: function (res) {
					DemandesClient.ajax.reload();
					$("#ModalselectProjectDevis").modal("hide");
				}
			});
		}
	});




	$(document).on('click', '.affecter_devis', function () {
		var idsArray = [];
		var projectIdArray = [];

		$('.checkboxDemandesClient:checked').each(function () {
			idsArray.push($(this).attr('data-id'));

			if (!projectIdArray.includes($(this).attr('data-ProjectId'))) projectIdArray.push($(this).attr('data-ProjectId'));
		});

		if (projectIdArray.length == 1) {
			$.ajax({
				method: 'GET',
				url: Routing.generate('getlist_affecter_devis', { 'ids': idsArray, 'projectId': projectIdArray }),
				success: function (res) {
					$("#ModalselectProjectDevis .modal-body").html(res);

					$("#selectProjectDevis").select2({
						sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),
						width: "100%",
						multiple: false,
						language: {
							noResults: function () {
								return $("#message_Select_vide").val();
							}
						},
					});

					$("#ModalselectProjectDevis").modal("show");
				}
			});
		} else {
			$.notify({
				icon: '',
				message: $("#mes_affecter_devis_error").val()
			}, {
				type: 'danger',
				timer: 4000
			});
		}
	});





	$(document).on('change', '.checkboxDemandesClient', function () {
		if ($('.checkboxDemandesClient:checked').length > 0)
			$('.affecter_devis').css("display", "block");
		else
			$('.affecter_devis').hide();
	});





	$(document).on("click", ".DeleteDevisFromLivrable", function () {
		var url = $(this).attr('data-href');

		$('#Supp-modal .modalmsg').text($("#SuppLivrablefromDevis").val());
		$('.supprimer-button-DevisLivrable').attr("data-href", url);
	});






	$(document).on("click", ".supprimer-button-DevisLivrable", function () {
		var url = $(this).attr('data-href');
		$.ajax({
			method: 'GET',
			url: url,
			success: function (res) {
				ProjectLivrable.ajax.reload();
				$("#Supp-modal").modal('hide')
				$(".form-group:has(#project_devis_montant)").load(location.href + " .form-group:has(#project_devis_montant)>*");
				setTimeout(() => {
					var currency = $("#currency").val();
					processMoneyFields(".project_devis_montant", currency);
				}, 500)
			}
		});
	});







	$(document).on("click", ".devis_activate", function () {
		var url = $(this).attr('data-href');
		var type = $(this).attr('data-type');

		if (type == "desactivate")
			$("#activate_modal .modalmsg").text($('#deactivationmsg').val());
		else
			$("#activate_modal .modalmsg").text($('#activationmsg').val());

		$("#activate_modal .confirm_button").attr("data-url", url);
		$("#activate_modal .confirm_button").attr("data-type", "devis");
	});







	$(document).on("change", "#file-upload", function () {
		const max_size_file = '1024';
		var max_size_file_Bytes = max_size_file * 1024 * 1024;

		if (this.files[0].size > max_size_file_Bytes) {
			notify($("#msg_error_size").val());
			this.value = "";
		} else {
			if (this.files.length > 0) saveDoc();
		}
	});






	$(document).on("click", ".supprimer-button-livrableDocument", function () {
		let id = $(this).attr('data-id');

		$.ajax({
			url: Routing.generate("ajax_deleteFile"),
			data: { id },
			type: 'POST',
		}).done(function (resp) {
			if (resp != "none") {
				$("#Supp-modal").modal('hide');
				$("#document_livrable_" + id).remove();
			}
		});
	});





	$(document).on("click", ".delete-document-livrable", function () {
		var id = $(this).attr('data-id');

		$('#Supp-modal .modalmsg').text($("#modal_suppression_msg").val());
		$('.supprimer-button-livrableDocument').attr("data-id", id);
		$("#Supp-modal").modal();
	});

	$(document).on("change", 'form[name="project_livrables"] #project_livrables_type', function () {
		if ($(this).val() == "interne") {
			$('form[name="project_livrables"] .users_container').show();
			$('form[name="project_livrables"] .prestataires_container').hide();
		} else {
			$('form[name="project_livrables"] .users_container').hide();
			$('form[name="project_livrables"] .prestataires_container').show();
		}
	});

	$(window).on('scroll', function () {
		if ($("#current_state").val()) {
			if (isLoading) return;

			var scrollTop = $(window).scrollTop();
			var windowHeight = $(window).height();
			var documentHeight = $(document).height();
			var threshold = documentHeight - windowHeight - 100;

			if (scrollTop > threshold) {
				isLoading = true;
				server_processing_livrable_documents($("#current_livrable_id").val(), $("#current_start").val());
			}
		}
	});


	if ($('#id_item').val() && $('#affectation').length && $('#urlaffectation').val()) {

		var href = $('#urlaffectation').val().replace('*id_project*', $('#id_item').val());
		$('#affectation').attr('href', href)
	}

	$("#affectation_projet tbody").on('click', 'tr', function (dataset) {
		let id = $(this).find("td p.projectLivrableTask").attr('data-idProjectLivrableTask');
		let idProjectLivrable = $(this).find("td p.projectLivrable").attr('data-idprojectlivrable');
		let project_id = $(this).find("td p.projectLivrable").attr('data-project_id');

		getModalTask(id, project_id, idProjectLivrable);
	});


	$(document).on("click", ".add_livrabel_devi", function () {
		$.ajax({
			url: Routing.generate("ajax_modal_ajout_livrable_devi"),
			data: { devi_id: $("#devi_id").val() },
			type: 'POST',
		}).done(function (html) {
			$(".modal-livrable-devi-container").html(html);
			$("#livrable_devi").select2({
				sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),
				width: "100%",
				multiple: false,
				language: {
					noResults: function () {
						return $("#message_Select_vide").val();
					}
				},
			});
			$("#modal_add_livrable_devi").modal();
		});
	});

	function updateInputMontant() {
		let livrable_id = $("#livrable_devi").val();

		$.ajax({
			url: Routing.generate("ajax_get_devi_max_montant"),
			data: { livrable_id },
			type: 'POST',
		}).done(function (montant) {
			$("#livrable_devi_montant").val(montant).attr("max", montant);
			let currency = $("#currency").val();
			processMoneyFields("#livrable_devi_montant", currency);
			$(".msg-erreur-livrable-devi").hide();
		});
	}

	$(document).on("click", ".add_livrable_dev_btn", function () {
		let montant = parseFloat($("#livrable_devi_montant").val().replace(/\s+/g, ''));

		if (checkDeviAmount()) {
			$.ajax({
				url: Routing.generate("ajax_action_livrable_devi"),
				data: {
					devi_id: $("#id_projectDevis").val(),
					livrable_id: $("#livrable_devi").val(),
					montant,
					action: "add"
				},
				type: 'POST',
			}).done(function () {
				$("#modal_add_livrable_devi").modal('hide');
				ProjectLivrable.ajax.reload();
				$(".form-group:has(#project_devis_montant)").load(location.href + " .form-group:has(#project_devis_montant)>*");
				setTimeout(() => {
					let currency = $("#currency").val();
					processMoneyFields(".project_devis_montant", currency);
				}, 500);
			});
		}
	});

	$(document).on("change", "#livrable_devi_montant", function () {
		let currency = $("#currency").val();
		processMoneyFields("#livrable_devi_montant", currency);
	});

	$(document).on("change", "#livrable_devi", updateInputMontant);
	$(document).on("submit", "form[name='project_devis']", function (e) { if ($("#project_devis_montant").length) { $("#project_devis_montant").val($("#project_devis_montant").val().replace(/\s+/g, '')) } });
	$(document).on("change", "#livrable_devi, #livrable_devi_montant", checkDeviAmount);

	function checkDeviAmount() {
		let currency = $("#currency").val();
		let montant = parseFloat($("#livrable_devi_montant").val().replace(/\s+/g, ''));
		let max = parseFloat($("#livrable_devi_montant").attr("max"));

		$(".add_livrable_dev_btn").attr('disabled', false);
		$(".msg-erreur-livrable-devi").hide();

		if (!$("#livrable_devi").val()) {
			$(".add_livrable_dev_btn").attr('disabled', true);
			return false;
		}

		if (montant > max) {
			let max_montant = formatPrice($("#livrable_devi_montant").attr("max").toString(), currency);
			let message = $("#msg-erreur-montant").val().replace("#montant#", max_montant);
			$(".msg-erreur-livrable-devi").html(message);
			$(".add_livrable_dev_btn").attr('disabled', true);
			$(".msg-erreur-livrable-devi").show();
			return false;
		}

		return true;
	}

});
