import GanttJob from "./GanttJob.js";
const MS_PER_MINUTE = 60000;
var color_border_tab = [];
globalThis.color_border_tab = color_border_tab;



Date.prototype.getWeek = function () {
	let date_string = formatDate(this);

	return moment(date_string, "DD-MM-YYYY").week();
};



function getDateOfISOWeek(w, y) {
	var simple = new Date(y, 0, 1 + (w - 1) * 7);
	var day = simple.getDay();
	var ISOweekStart = simple;

	if (day <= 4)
		ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
	else
		ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());

	return ISOweekStart;
}



globalThis.getDateOfISOWeek = getDateOfISOWeek;




function getNbWeeksOfMonth(month_num) {
	let date = new Date(globalThis.calendar_start_date);
	date.setDate(date.getDate() + 7);

	let first_day_of_month = new Date(date.getFullYear(), month_num, 1);
	let last_day_of_month = new Date(date.getFullYear(), month_num + 1, 0);

	let first_week = first_day_of_month.getWeek();
	let last_week = last_day_of_month.getWeek();

	if (month_num === 0 && first_week !== 1) first_week = 0;

	if (month_num === 11 && last_week === 1) {
		let before_last_week = new Date(last_day_of_month.getFullYear(), 11, last_day_of_month.getDate() - 7);
		last_week = before_last_week.getWeek();
		last_week++;
	}

	if ([5, 6, 0].includes(first_day_of_month.getDay())) first_week++;

	if ([1, 2, 3].includes(last_day_of_month.getDay())) last_week--;

	return last_week - first_week + 1;
}



globalThis.getNbWeeksOfMonth = getNbWeeksOfMonth;





function getSundayOfWeek(date) {
	const first = date.getDate() - date.getDay() + 1;
	const last = first + 6;

	const sunday = new Date(date.setDate(last));

	return sunday;
}

globalThis.getSundayOfWeek = getSundayOfWeek;





function is_project_first() {
	let current_cookie = Object.entries(JSON.parse(getCookie("current_project_year_vue")));
	let current_vues = current_cookie.filter(([vue_key, vue]) => (vue == 1 && vue_key != "fusion_intervenant"))
	current_vues = Object.keys(Object.fromEntries(current_vues));

	let intervenants_vue_key = current_vues.findIndex(element => element === 'intervenant');
	let projects_vue_key = current_vues.findIndex(element => element === 'project');

	if (intervenants_vue_key >= 0 && projects_vue_key >= 0 && projects_vue_key < intervenants_vue_key) return true;

	return false;
}





function sideScroll(element, direction, speed, distance, step) {
	var scrollAmount = 0;

	var slideTimer = setInterval(function () {
		if (direction == 'left')
			element.scrollLeft -= step;
		else
			element.scrollLeft += step;

		scrollAmount += step;

		if (scrollAmount >= distance) window.clearInterval(slideTimer);

	}, speed);
}




export function GanttCalendarRenderer(root) {
	const today_date = new Date();
	today_date.setHours(0);
	var vue_type = "week";
	let text_show = "";

	var urlParams = new URLSearchParams(window.location.search);

	if (urlParams.has("currentYear") && urlParams.has("currentMonth") && urlParams.has("currentDay")) {
		if (urlParams.has("currentTime")) {
			let start_date_calendar = new Date(parseInt(urlParams.get("currentTime")));
			globalThis.year = start_date_calendar.getFullYear();
			urlParams.delete("currentYear");

			globalThis.month = start_date_calendar.getMonth();
			urlParams.delete("currentMonth");

			globalThis.date_jour = start_date_calendar.getDate();
			urlParams.delete("currentDay");

			globalThis.selected_btn = urlParams.get("currentView");
			urlParams.delete("currentView");
		} else {
			globalThis.year = parseInt(urlParams.get("currentYear"));
			urlParams.delete("currentYear");
			globalThis.month = parseInt(urlParams.get("currentMonth"));
			urlParams.delete("currentMonth");
			globalThis.date_jour = parseInt(urlParams.get("currentDay"));
			urlParams.delete("currentDay");
			globalThis.selected_btn = urlParams.get("currentView");
			urlParams.delete("currentView");
		}

		if ($(".swicth_container.year_vue_type").length > 0) $(".swicth_container.year_vue_type").show();

		let url = window.location.pathname;

		if (urlParams.has("action"))
			url += "?action=delete";
		else if (urlParams.has("vue"))
			url += "?vue=" + urlParams.get("vue");

		window.history.pushState({}, document.title, url);

		var calendar_start_date = new Date(globalThis.year, globalThis.month, globalThis.date_jour);
		var calendar_end_date = new Date(calendar_start_date);
		switch (globalThis.selected_btn) {

			case "btn_day":
				vue_type = "day";
				calendar_end_date.setHours(23);
				calendar_end_date.setMinutes(59);
				calendar_end_date.setSeconds(59);

				break;
			case "btn_week":
				vue_type = "week";
				calendar_end_date.setDate(calendar_end_date.getDate() + 6);
				break;
			case "btn_month":
				vue_type = "month";
				calendar_end_date = new Date(calendar_start_date.getFullYear(), calendar_start_date.getMonth() + 1, 0);

				break;
			default:
				break;
		}

		globalThis.calendar_start_date = calendar_start_date;
		globalThis.calendar_end_date = calendar_end_date;

		if (globalThis.selected_btn != "btn_week") {
			if ($(".fc-button-group .btn_week").hasClass("fc-state-active"))
				$(".fc-button-group .btn_week").removeClass("fc-state-active");

			if (!$(".fc-button-group ." + globalThis.selected_btn).hasClass("fc-state-active"))
				$(".fc-button-group ." + globalThis.selected_btn).addClass("fc-state-active");
		}

		updateMonthName();
	} else {
		let calendar_current_date = getDateByProjectFilter(today_date);
		const first_monday = getMonday(calendar_current_date);

		var calendar_start_date = new Date(first_monday.getFullYear(), first_monday.getMonth(), first_monday.getDate());

		globalThis.calendar_start_date = calendar_start_date;

		var calendar_end_date = new Date(calendar_start_date);
		const nb_days = Object.keys(JSON.parse($("#weekdays").val())).length;

		calendar_end_date.setDate(calendar_end_date.getDate() + nb_days - 1);
		globalThis.calendar_end_date = calendar_end_date;

		if (globalThis.calendar_end_date.getHours() != 23) {
			globalThis.calendar_end_date.setHours(23);
			globalThis.calendar_end_date.setMinutes(59);
			globalThis.calendar_end_date.setSeconds(59);
		}

		let year = calendar_start_date.getFullYear();
		globalThis.year = year;

		let month = calendar_start_date.getMonth();
		globalThis.month = month;

		let date_jour = first_monday.getDate();
		globalThis.date_jour = date_jour;

		let selected_btn = "btn_week";
		globalThis.selected_btn = selected_btn;

		text_show = globalThis.calendar_start_date.getDate() + ' - ' + globalThis.calendar_end_date.getDate() + ' ' + getLibelleOfMonth(globalThis.calendar_end_date.getMonth()) + ' ' + globalThis.calendar_end_date.getFullYear();
		updateViewName(text_show);
	}

	let loading = '';
	let overlay = '';

	const startTime = calendar_start_date.valueOf();

	var disabled = "disabled";
	var periodHasCurrentDate = true;
	globalThis.periodHasCurrentDate = periodHasCurrentDate;

	var planning_start_date = null;

	var shadowRoot = root;

	var names = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

	this.resources = [];

	this.dateFrom = globalThis.calendar_start_date;
	this.dateTo = globalThis.calendar_end_date;

	this.selectedJob = null;
	this.selectedJobElement = null;
	this.selectedJobDiv = null;
	this.cellWidth = null;
	this.currentJobWidth = null;
	this.selectedJobInitialPosition = null;
	this.initialItem = null;

	var is_project_livrable_graphique = document.querySelector("#project_livrable_graphique");
	var is_users_tasks_graphique = document.querySelector("#users_tasks_graphique");
	var current_user_tasks_graphique = document.querySelector("#current_user_tasks_graphique");
	var is_client_tasks_graphique = document.querySelector("#client_tasks_graphique");

	var hasLeftColumn = is_project_livrable_graphique || is_users_tasks_graphique || current_user_tasks_graphique || is_client_tasks_graphique;
	var nb_click_cell = 0;



	function getMonday(date) {
		let d = new Date(date.getTime());
		var day = d.getDay();
		var diff = d.getDate() - day + (day == 0 ? -6 : 1);
		d.setDate(diff)

		return new Date(d);
	}

	globalThis.getMonday = getMonday;






	function redirectToCurrentView(path, currentTime = null, view = null) {
		let url = new URL(path);
		let params = new URLSearchParams(url.search);

		params.append('currentYear', globalThis.year);
		params.append('currentMonth', globalThis.month);
		params.append('currentDay', globalThis.date_jour);

		if (currentTime) params.append('currentTime', currentTime);

		if (!view) view = globalThis.selected_btn;

		params.append('currentView', view);

		url.search = params
		window.location.href = url;
	}

	globalThis.redirectToCurrentView = redirectToCurrentView;



	function setChartJobs(jobs) {
		let p_jobs = [];

		jobs.forEach(job => {
			var validator = {
				set: function (obj, prop, value) {
					obj[prop] = value;
					return true;
				},
				get: function (obj, prop) {

					return obj[prop];
				}
			};

			var p_job = new Proxy(job, validator);
			p_jobs.push(p_job);
		});
		return p_jobs;
	}



	globalThis.setChartJobs = setChartJobs;


	function getLibelleOfMonth(month) {
		const nameOfMonthFR = new Date(2022, month, 1).toLocaleString(
			'fr-FR', { month: 'long' }
		);

		return nameOfMonthFR.charAt(0).toUpperCase() + nameOfMonthFR.slice(1);
	}




	function updateViewName(text_show) {
		$("#header_calendar .fc-center h2").html(text_show);

		if (disabled) {
			$("#header_calendar .fc-today-button").attr("disabled", disabled);
			$("#header_calendar .fc-today-button").addClass(disabled);
		} else {
			$("#header_calendar .fc-today-button").prop("disabled", false);
			$("#header_calendar .fc-today-button").removeClass("disabled");
		}

		if (!$("#header_calendar").is(":visible")) $("#header_calendar").show();
	}





	function setPrevMonth(increment) {
		if (loading) return;
		globalThis.calendar_start_date.setMonth(globalThis.calendar_start_date.getMonth() - increment);
		globalThis.calendar_end_date.setMonth(globalThis.calendar_end_date.getMonth() - increment);
	}




	function setNextMonth(increment) {
		if (loading) return;

		globalThis.calendar_start_date.setMonth(globalThis.calendar_start_date.getMonth() + increment);
		globalThis.calendar_end_date.setMonth(globalThis.calendar_end_date.getMonth() + increment);
	}




	function setPrevWeek() {
		globalThis.calendar_start_date.setDate(globalThis.calendar_start_date.getDate() - 7);
		globalThis.calendar_end_date.setDate(globalThis.calendar_end_date.getDate() - 7);
	}




	function setNextWeek() {
		globalThis.calendar_start_date.setDate(globalThis.calendar_start_date.getDate() + 7);
		globalThis.calendar_end_date.setDate(globalThis.calendar_end_date.getDate() + 7);
	}




	function setPrevDay() {
		globalThis.calendar_start_date.setDate(globalThis.calendar_start_date.getDate() - 1);
		globalThis.calendar_end_date.setDate(globalThis.calendar_end_date.getDate() - 1);
	}




	function setNextDay() {
		globalThis.calendar_start_date.setDate(globalThis.calendar_start_date.getDate() + 1);
		globalThis.calendar_end_date.setDate(globalThis.calendar_end_date.getDate() + 1);
	}




	function setCalendarStartDate(date) {
		if (globalThis.selected_btn == "btn_week") {
			planning_start_date = getMonday(date);
		} else {
			planning_start_date = new Date(date);
			planning_start_date.setDate(1);
		}

		globalThis.calendar_start_date = planning_start_date;

		if (this && this.dateFrom) this.dateFrom = planning_start_date;

		return planning_start_date;
	}


	function updateTime(minStartDate = false, maxEndDate = false, update = true, go_to_current = false) {

		if (loading) return;

		if (update) {
			loading = $('#loading').val();

			if ($('.gantt_calendar').length) {
				$('.loader_container').show();
				$('.loader_container').css("display", "flex");
			}

			overlay = 'overlay';
		}

		var scroll_to;

		var start = new Date(`${globalThis.year}-${globalThis.month + 1}-${globalThis.date_jour}`);
		var end = new Date(start.getFullYear(), start.getMonth() + 1, 0);


		if (globalThis.selected_btn == "btn_week") {
			vue_type = "week";
			const nb_days = Object.keys(JSON.parse($("#weekdays").val())).length;
			start = new Date(`${globalThis.year}-${globalThis.month + 1}-${globalThis.date_jour}`);
			end = new Date(start);
			end.setDate(start.getDate() + nb_days - 1);

		} else if (globalThis.selected_btn == "btn_day") {
			vue_type = "day";
			end = new Date(start);
			end.setHours(23);
			end.setMinutes(59);
			end.setSeconds(59);
		} else
			vue_type = "month";

		globalThis.calendar_start_date = start;
		globalThis.calendar_end_date = end;

		if (this && this.dateFrom && this.dateTo) {
			this.dateFrom = start;
			this.dateTo = end;
		}

		if (globalThis.selected_btn == "btn_day") {
			const entreprise_hours = Object.values(JSON.parse($("#entreprise_hours").val()));

			let min_hour = padTo2Digits(entreprise_hours[0]) + ":00";
			let min_hour_array = min_hour.split(':');
			let formattedMinHour = min_hour_array[0];

			let max_hour = padTo2Digits(entreprise_hours.reverse()[0]) + ":00";
			let max_hour_array = max_hour.split(':');
			let formattedMaxHour = max_hour_array[0]

			if (max_hour_array[1] != "00") formattedMaxHour = parseInt(formattedMaxHour[0]) + 1;

			end = new Date(`${globalThis.year}-${globalThis.month + 1}-${globalThis.date_jour} ${formattedMaxHour}:00:00`);
		}

		if (minStartDate && maxEndDate) {
			if (start.valueOf() < minStartDate.valueOf()) {
				start = planning_start_date = setCalendarStartDate(minStartDate);
				$(".fc-prev-button").attr("disabled", "disabled");
			} else {
				$(".fc-prev-button").prop("disabled", false);
			}

			if (start.valueOf() > maxEndDate.valueOf()) start = planning_start_date = setCalendarStartDate(maxEndDate);

			end = new Date(start);

			if (globalThis.selected_btn == "btn_week") {
				end.setDate(start.getDate() + 6);
			} else {
				let last_day = new Date(end.getFullYear(), end.getMonth() + 1, 0);
				end.setDate(last_day.getDate());
			}

			end.setHours(23);
			end.setMinutes(59);
			end.setSeconds(59);

			globalThis.calendar_end_date = end;

			if (this && this.dateTo) this.dateTo = end;

			if (end.valueOf() > maxEndDate.valueOf())
				$(".fc-next-button").attr("disabled", "disabled");
			else
				$(".fc-next-button").prop("disabled", false);
		}

		if (today_date.valueOf() >= start.valueOf() && today_date.valueOf() <= end.valueOf() && globalThis.periodHasCurrentDate) disabled = "disabled";

		updateMonthName(start.valueOf(), end.valueOf());

		if (update) {
			setTimeout(() => {
				$('.fc-button').removeClass('fc-state-active');

				$('.' + globalThis.selected_btn).addClass('fc-state-active');

				loading = '';
				overlay = '';
				clear();
				initGantt(true);
			}, 350);
		} else {
			globalThis.start = new Date(`${globalThis.year}-${globalThis.month + 1}-${globalThis.date_jour} 00:00:00`);

			if (maxEndDate && end.valueOf() > maxEndDate.valueOf())
				globalThis.end = new Date(maxEndDate);
			else
				globalThis.end = new Date(`${globalThis.year}-${globalThis.month + 1}-${globalThis.date_jour} 00:00:00`);

			loading = '';
			overlay = '';
		}

		$('.loader_container').css("display", "none");
	}



	globalThis.updateTime = updateTime;



	function getDateByProjectFilter(calendar_current_date) {
		if ($("#filter_start_date").length > 0) calendar_current_date = new Date($("#filter_start_date").val());

		if ($("#filter_end_date").length > 0) {
			calendar_current_date = new Date($("#filter_end_date").val());

			if (getMonday(calendar_current_date).getDate() === calendar_current_date.getDate()) calendar_current_date.setDate(calendar_current_date.getDate() - 7);
		}

		return calendar_current_date;
	}





	function getCurrent() {
		if (loading) return;

		let calendar_current_date = getDateByProjectFilter(today_date);

		globalThis.month = calendar_current_date.getMonth();
		globalThis.year = calendar_current_date.getFullYear();

		if (globalThis.selected_btn == "btn_week" || globalThis.selected_btn == "btn_day") {
			if (planning_start_date) {
				globalThis.date_jour = planning_start_date.getDate();
			} else {
				if (globalThis.selected_btn == "btn_week") {
					globalThis.date_jour = getMonday(calendar_current_date).getDate();
				}

				else
					globalThis.date_jour = calendar_current_date.getDate();
			}
		} else
			globalThis.date_jour = 1;

		globalThis.updateTime(false, false, true, true);
	}





	function updateMonthName(startTime = null, endTime = null) {
		let start_date = "";

		if (startTime == null && endTime == null) {
			var startTime = new Date(`${globalThis.year}-${globalThis.month + 1}-${globalThis.date_jour} 00:00:00`).valueOf();
			start_date = new Date(`${globalThis.year}-${globalThis.month + 1}-${globalThis.date_jour} 00:00:00`);

			var endTime = new Date(`${globalThis.year}-${globalThis.month + 1}-${globalThis.date_jour} 00:00:00`).valueOf();
		} else {
			start_date = new Date(startTime);
		}

		text_show = getLibelleOfMonth(globalThis.month) + ' ' + globalThis.year;

		if (vue_type == "week") {
			text_show = globalThis.calendar_start_date.getDate() + ' - ' + globalThis.calendar_end_date.getDate() + ' ' + getLibelleOfMonth(globalThis.calendar_end_date.getMonth()) + ' ' + globalThis.calendar_end_date.getFullYear();
		}

		if (vue_type == "day") {
			text_show = globalThis.date_jour + " " + getLibelleOfMonth(globalThis.month);
		}

		updateViewName(text_show);
	}


	globalThis.updateMonthName = updateMonthName;



	$(document).on("click", "#header_calendar .fc-prev-button", function () {
		if (globalThis.periodHasCurrentDate) disabled = "";

		if (globalThis.selected_btn == "btn_month")
			setPrevMonth(1);
		else if (globalThis.selected_btn == "btn_week")
			setPrevWeek();
		else if (globalThis.selected_btn == "btn_day")
			setPrevDay();

		globalThis.year = globalThis.calendar_start_date.getFullYear();
		globalThis.month = globalThis.calendar_start_date.getMonth();
		globalThis.date_jour = globalThis.calendar_start_date.getDate();

		globalThis.updateTime();
	});






	$(document).on("click", "#header_calendar .fc-next-button", function () {
		if (globalThis.periodHasCurrentDate) disabled = "";

		if (globalThis.selected_btn == "btn_month")
			setNextMonth(1);
		else if (globalThis.selected_btn == "btn_week")
			setNextWeek();
		else if (globalThis.selected_btn == "btn_day")
			setNextDay();

		globalThis.year = globalThis.calendar_start_date.getFullYear();
		globalThis.month = globalThis.calendar_start_date.getMonth();
		globalThis.date_jour = globalThis.calendar_start_date.getDate();

		globalThis.updateTime();
	});





	$(document).on("click", "#header_calendar .fc-today-button", function () {
		getCurrent();
	});




	$(document).on("click", "#header_calendar .fc-right .fc-button-group .fc-button", function () {
		if ($(this).hasClass('btn_month')) {
			globalThis.selected_btn = "btn_month";
			vue_type = "month";
		} else if ($(this).hasClass('btn_week')) {
			globalThis.selected_btn = "btn_week";
			vue_type = "week";
		} else if ($(this).hasClass('btn_day')) {
			globalThis.selected_btn = "btn_day";
			vue_type = "day";
		}

		getCurrent();
	});




	$(document).on("change", "#header_calendar .checkboxes_row :input", function () {
		let current_cookie = Object.values(JSON.parse(getCookie("graphic_view_data")));
		let typeFilter = this.name;

		if ($(this).is(":checked")) {
			setCookie("graphic_view_data", JSON.stringify([...current_cookie, typeFilter]), 360, null, false);
		} else {
			if (current_cookie.length == 1)
				setCookie("graphic_view_data", JSON.stringify(["users_data"]), 360, null, false);
			else
				setCookie("graphic_view_data", JSON.stringify(current_cookie.filter(filter => filter != typeFilter)), 360, null, false);
		}

		globalThis.getItems();
	});




	var checkElements = function () {
		if (shadowRoot) return true;

		return false;
	}



	var clear = function () {
		if (checkElements()) {
			var container = shadowRoot.querySelector("#gantt-container");
			container.innerHTML = "";

			container.removeEventListener("mousedown", _handleMouseDown, false);
			document.removeEventListener("mouseup", _handleMouseUp, false);
			container.removeEventListener("dblclick", _handleDblClick, false);
		}
	}




	var getMinAndMaxHourForDayView = function () {
		const entreprise_hours = Object.values(JSON.parse($("#entreprise_hours").val()));

		let min_hour_str = padTo2Digits(entreprise_hours[0]) + ":00";
		let max_hour_str = padTo2Digits(entreprise_hours.reverse()[0]) + ":00";

		let min_hour = parseInt(min_hour_str.split(':')[0]);

		let max_hour = parseInt(max_hour_str.split(':')[0]);
		max_hour++;

		return {
			min_hour,
			max_hour
		}
	}





	var initFirstRow = function (header) {
		if (checkElements()) {
			var first_row = document.createElement("div");
			first_row.className = "gantt-row-period header_first_row header";
			first_row.style.border = "none";

			if (hasLeftColumn) {
				var resource = document.createElement("div");
				resource.className = "resource_name_first_row first_row fixed_left_column";
				resource.innerHTML = $("#left_column_name").val();

				if (globalThis.selected_btn == "btn_month") resource.style.borderBottom = "1px solid rgba(177,184,189,0.5)"

				first_row.appendChild(resource);
			}

			if (vue_type == "month") {
				var month = new Date(globalThis.calendar_start_date);

				const days_short = JSON.parse($("#weekdaysMin").val());

				for (month; month <= globalThis.calendar_end_date; month.setMonth(month.getMonth() + 1)) {
					var monthLastDay = new Date(month.getFullYear(), month.getMonth() + 1, 0);

					monthLastDay.setHours(23);
					monthLastDay.setMinutes(59);
					monthLastDay.setSeconds(59);

					var date = new Date(month);

					for (date; date <= monthLastDay; date.setDate(date.getDate() + 1)) {
						var period = document.createElement("div");
						period.className = "gantt-row-period header";

						let template_day = $("#template_day_name").html();
						template_day = template_day.replace('#day#', days_short[date.getDay()]);
						template_day = template_day.replace('#date#', padTo2Digits(date.getDate()));

						period.innerHTML = template_day;
						period.style.borderTop = "none";
						period.style.borderRight = "none";

						first_row.appendChild(period);
					}
				}
			} else if (vue_type == "week") {
				var date = new Date(globalThis.calendar_start_date);
				date.setHours(0);

				const days_names = JSON.parse($("#weekdays").val());

				for (date; date <= globalThis.calendar_end_date; date.setDate(date.getDate() + 1)) {
					date.setHours(0);

					var date_period = document.createElement("div");
					date_period.className = "gantt-row-period header header_week";
					date_period.style.borderTop = "none";
					date_period.style.borderBottom = "none";

					let template_day = $("#template_day_name").html();
					template_day = template_day.replace('#day#', days_names[date.getDay()]);
					template_day = template_day.replace('#date#', padTo2Digits(date.getDate()));

					date_period.innerHTML = template_day;

					first_row.appendChild(date_period);
				}
			} else if (vue_type == "day") {
				const entreprise_hours = Object.values(JSON.parse($("#entreprise_hours").val()));

				entreprise_hours.map((hour) => {
					var hour_period = document.createElement("div");
					hour_period.className = "gantt-row-period header";
					hour_period.style.borderTop = "none";
					hour_period.style.borderBottom = "none";

					let template_day = $("#template_day_name").html();
					template_day = template_day.replace('#day#', padTo2Digits(hour) + "h");
					template_day = template_day.replace('#date#', "");

					hour_period.innerHTML = template_day;

					first_row.appendChild(hour_period);
				})
			}

			header.appendChild(first_row);

			setColumnForCalendar(first_row);
		}
	}








	var initSecondRow = function (header) {
		if (checkElements()) {
			var second_row = document.createElement("div");
			second_row.className = "header_second_row";
			second_row.style.border = "none";

			if (vue_type === "week") {
				if (hasLeftColumn) {
					var resource = document.createElement("div");
					resource.className = "resource_name_second_row second_row fixed_left_column";
					second_row.appendChild(resource);

					var date = new Date(globalThis.calendar_start_date);
					date.setHours(0);

					for (date; date <= globalThis.calendar_end_date; date.setDate(date.getDate() + 1)) {
						date.setHours(0);

						var date_period = document.createElement("div");
						date_period.className = "gantt-row-period header_second_row_part_container";
						second_row.appendChild(date_period);

						const entreprise_hours = Object.values(JSON.parse($("#entreprise_hours").val()));

						entreprise_hours.map((h, index) => {
							var period = document.createElement("div");
							period.className = "gantt-row-period header_second_row_part week";

							period.innerHTML = h + "h";
							if (index <= entreprise_hours.length - 2)
								period.style.borderLeft = "none";
							else
								period.style.borderRight = "none";

							date_period.appendChild(period);
						});
					}

					header.appendChild(second_row)
					setColumnForCalendar(second_row);
				}

			}
		}
	}




	var handleCellClick = function (job_id, row_id, row_type, pos) { }
	globalThis.handleCellClick = handleCellClick;



	var handleCellDblClick = function (date, resource_id) { }
	globalThis.handleCellDblClick = handleCellDblClick;




	function formatLabelLeftColumn(label) {
		return label.length > 19 ? label.substr(0, 17) + "..." : label;
	}





	function addEventClickOnProjectName(resource, element) {
		if (element.hasOwnProperty("path")) {
			resource.onclick = function () {
				window.location.href = element.path;
			};

			resource.style.cursor = "pointer";
		}
	}





	var getRowById = function (id) {
		let dataRow = shadowRoot.querySelector('#lef-colument-' + id);
		if (dataRow) return dataRow.getAttribute('data-row');;

		let elements = shadowRoot.querySelectorAll('.Element-left');
		for (let element of elements) {
			let idsArray = [];
			let dataIds = element.getAttribute('data-ids');
			let idsList = JSON.parse(dataIds) || [];


			if (Array.isArray(idsList)) {
				for (let item of idsList) {
					if (item && typeof item.id === 'number') {
						idsArray.push(item.id);
					}
				};

				if (idsArray.includes(parseInt(id))) {
					return element.getAttribute('data-row');
				}

			}
		};
	}
	globalThis.getRowById = getRowById;

	var getItemById = function (id) {
		let element = shadowRoot.querySelector('[data-object_id = "' + id + '"]');
		return element;
	}
	globalThis.getItemById = getItemById;

	var initGanttRows = function (table_body) {
		if (checkElements()) {
			var container = shadowRoot.querySelector("#gantt-container");

			this.resources.forEach((element, index) => {
				var gant_row = document.createElement("div");
				gant_row.className = "gantt-row-period gantt_calendar_row";
				gant_row.style.border = "none";

				if (hasLeftColumn) {
					var resource = document.createElement("div");
					resource.className = "gantt-row-resource fixed_left_column";
					resource.innerHTML = formatLabelLeftColumn(element.label);

					if (element.label) resource.setAttribute("title", element.label);
					if (element.object_id) resource.setAttribute("id", 'lef-colument-' + element.object_id);
					if (element.all_id) resource.setAttribute("data-ids", JSON.stringify(element.all_id));
					if (element.id) resource.setAttribute("data-row", element.id);
					resource.classList.add('Element-left')
					addEventClickOnProjectName(resource, element);
					gant_row.appendChild(resource);
				}

				if (vue_type == "month") {
					var month = new Date(globalThis.calendar_start_date);

					for (month; month <= globalThis.calendar_end_date; month.setMonth(month.getMonth() + 1)) {
						var monthLastDay = new Date(month.getFullYear(), month.getMonth() + 1, 0);
						monthLastDay.setHours(23);
						monthLastDay.setMinutes(59);
						monthLastDay.setSeconds(59);

						var date = new Date(month);

						for (date; date <= monthLastDay; date.setDate(date.getDate() + 1)) {
							var period = document.createElement("div");
							period.className = "gantt-row-item";
							period.style.borderRight = "none";

							if (date.getDay() == 0 || date.getDay() == 6) period.style.backgroundColor = "whitesmoke";

							let date_string = formatDate(date, true);
							period.setAttribute("data-date", date_string);
							period.setAttribute("data-resource", element.id);
							period.setAttribute("data-type", element.type);

							period.ondrop = onJobDrop;

							period.ondragover = function (ev) {
								ev.preventDefault();
							}

							gant_row.appendChild(period);

							period.addEventListener("click", function (e) {
								nb_click_cell++;

								setTimeout(() => {
									if (nb_click_cell == 1) globalThis.handleCellClick(element.id, date_string, element.type);

									nb_click_cell = 0;
								}, 350);
							}, false);
						}
					}
				} else if (vue_type == "week") {
					var date = new Date(globalThis.calendar_start_date);
					date.setHours(0);

					for (date; date <= globalThis.calendar_end_date; date.setDate(date.getDate() + 1)) {
						date.setHours(0);

						var date_period = document.createElement("div");
						date_period.className = "gantt-row-period body_gantt_row_part_container";
						gant_row.appendChild(date_period);


						const entreprise_hours = Object.values(JSON.parse($("#entreprise_hours").val()));

						entreprise_hours.map((h, index) => {
							var period = document.createElement("div");
							period.className = "gantt-row-item body_gantt_row_part";

							if (date.getDay() == 0 || date.getDay() == 6) period.style.backgroundColor = "whitesmoke";

							if (index <= entreprise_hours.length - 2)
								period.style.borderLeft = "none";
							else
								period.style.borderRight = "none";

							var date_string = formatDate(date, true, h);

							period.setAttribute("data-date", date_string);
							period.setAttribute("data-resource", element.id);
							period.setAttribute("data-type", element.type);

							period.ondrop = onJobDrop;

							period.ondragover = function (ev) {
								ev.preventDefault();
							}

							date_period.appendChild(period);

							period.addEventListener("click", function (e) {
								nb_click_cell++;

								setTimeout(() => {
									if (nb_click_cell == 1) globalThis.handleCellClick(element.id, this.dataset.date, element.type);

									nb_click_cell = 0;
								}, 350);

							}, false);
						});
					}
				} else if (vue_type == "day") {
					var date = new Date(globalThis.calendar_start_date);
					date.setHours(0);
					const entreprise_hours = Object.values(JSON.parse($("#entreprise_hours").val()));

					entreprise_hours.map((hour) => {
						var hour_period = document.createElement("div");
						hour_period.className = "gantt-row-period";
						hour_period.style.border = "none";
						gant_row.appendChild(hour_period);

						var period = document.createElement("div");
						period.className = "gantt-row-item";

						var date_string = formatDate(date, true);

						period.setAttribute("data-date", date_string + "T" + padTo2Digits(hour) + ":59:59");
						period.setAttribute("data-resource", element.id);
						period.setAttribute("data-type", element.type);

						period.ondrop = onJobDrop;

						period.ondragover = function (ev) {
							ev.preventDefault();
						}

						hour_period.appendChild(period);


						period.addEventListener("click", function (e) {
							nb_click_cell++;

							setTimeout(() => {
								if (nb_click_cell == 1) globalThis.handleCellClick(element.id, this.dataset.date, element.type);

								nb_click_cell = 0;
							}, 350);

						}, false);
					})
				}

				table_body.appendChild(gant_row);
				setColumnForCalendar(gant_row)
			});
		}
	}.bind(this);





	var isCollision = async function (start, end, resource, id, job = null) {
		let data = {};
		let url = "";

		if (is_project_livrable_graphique || is_users_tasks_graphique) {
			chart = (is_project_livrable_graphique || is_users_tasks_graphique);
			let newRowData = chart.resources[parseInt(resource) - 1];
			url = $("#get_interventions_project_by_intervenant_and_date").val();

			data = {
				project_id: $("#project_id").val(),
				start_date: formatDate(start, true) + " " + start.toTimeString().split(" ")[0],
				end_date: formatDate(end, true) + " " + end.toTimeString().split(" ")[0],
				object_id: newRowData.object_id,
				type: newRowData.type,
				is_week: vue_type == "week"
			};
		}

		let res = await $.ajax({
			async: false,
			type: "POST",
			url,
			data
		})

		if (res != "none") return true;

		return false;
	}





	function jobDropActions(job, gantt_item, job_id) {
		$('.loader_container').css("display", "flex");
		var jobElement = shadowRoot.getElementById(job_id);
	}






	var onJobDrop = function (ev) {
		if (checkElements()) {
			var gantt_item = getGanttElementFromPosition(ev.x, ev.y);

			var job_id = ev.dataTransfer.getData("job");
			var object_id = ev.dataTransfer.getData("object_id");
			let end = new Date(ev.dataTransfer.getData("end"));

			let start = new Date(ev.dataTransfer.getData("start"));

			let pos = ev.dataTransfer.getData("pos");
			let row = ev.dataTransfer.getData("row");
			let type = ev.dataTransfer.getData("type");

			let url = "";
			let data = {};

			if (is_project_livrable_graphique || is_users_tasks_graphique) {
				let job = {
					id: job_id,
					resource: row,
					start,
					end,
					pos,
					type,
					object_id
				};

				jobDropActions(job, gantt_item, job_id);
			}
		}
	}.bind(this);







	function setColumnForCalendar(container, left_colum = null) {
		let nb_first_row_columns = 0;
		const nb_days = Object.keys(JSON.parse($("#weekdays").val())).length;

		if (vue_type == "month") {
			nb_first_row_columns = 1;
		} else if (vue_type == "week") {
			nb_first_row_columns = nb_days;
		} else if (vue_type == "day") {
			const entreprise_hours = Object.values(JSON.parse($("#entreprise_hours").val()));
			nb_first_row_columns = entreprise_hours.length;
		}

		let width_left_column = '';

		if (hasLeftColumn) width_left_column = '160px';
		if (left_colum !== null) width_left_column = left_colum;

		container.style.gridTemplateColumns = `${width_left_column} repeat(${nb_first_row_columns},1fr)`;
	}





	var initGantt = function (changeCall = false) {
		if (checkElements()) {
			if (changeCall) globalThis.getItems();

			var container = shadowRoot.querySelector("#gantt-container");
			container.innerHTML = "";
			container.style.position = "relative";

			var header = document.createElement("div");
			header.className = "header_gantt_calendar"
			initFirstRow(header);

			initSecondRow(header);
			container.appendChild(header);

			if (["btn_month"].includes(globalThis.selected_btn) && header.offsetWidth > $('#header_calendar').width()) {
				var first_row_columns = shadowRoot.querySelectorAll(".header_first_row .gantt-row-period.header");

				for (let index = 0; index < first_row_columns.length; index++) {
					first_row_columns[index].style.width = "39px";
				}
			}

			var table_body = document.createElement("div");
			table_body.className = "body_gantt_calendar"
			initGanttRows(table_body);
			container.appendChild(table_body);

			initJobs();

			var header_rows = shadowRoot.querySelector("div.header_gantt_calendar");
			var headerStyle = header_rows.style;

			$(window).scroll(function () {
				var calendarOffsetTop = $(".gantt_calendar").offset().top;
				var currentScrollTop = $(window).scrollTop();

				if (currentScrollTop > calendarOffsetTop) {
					headerStyle.position = 'absolute';
					headerStyle.width = 'inherit';
					headerStyle.top = (currentScrollTop - calendarOffsetTop - 7) + "px";
				} else {
					headerStyle.position = 'static';
					headerStyle.top = calendarOffsetTop + "px";
				}
			})

			var stickyElements = shadowRoot.querySelectorAll(".fixed_left_column");
			var stickyElementsOffsets = new Array();

			stickyElements.forEach((stickyElement, key) => {
				var style = stickyElement.currentStyle || window.getComputedStyle(stickyElement);

				stickyElementsOffsets.push({
					top: stickyElement.offsetTop,
					height: stickyElement.offsetHeight,
					width: parseFloat(style.width),
					height_2: parseFloat(style.height),
					width_2: stickyElement.offsetWidth,
					left: stickyElement.offsetLeft,
				});

				if (stickyElement.classList.contains("first_row") || stickyElement.classList.contains("second_row"))
					stickyElement.style.zIndex = 1010;
				else
					stickyElement.style.zIndex = 1000;
			});



			container.onscroll = () => {
				let header_first_row_columns = container.querySelectorAll(".gantt-row-period.header_first_row.header");
				let column_width = container.querySelector(".gantt-row-period.header_first_row.header .gantt-row-period").offsetWidth - 2;
				let left_colum = container.scrollLeft > 0 ? column_width + 'px' : null;
				header_first_row_columns.forEach(function (column, key) {
					if (key == 0) {
						if (is_project_livrable_graphique || is_users_tasks_graphique)
							column.style.marginLeft = container.scrollLeft > 0 ? '160px' : 'unset';
					}
					setColumnForCalendar(column, left_colum);
				})

				let header_second_row_columns = container.querySelectorAll(".header_second_row");
				header_second_row_columns.forEach(function (column, key) {
					if (key == 0) {
						if (is_project_livrable_graphique || is_users_tasks_graphique)
							column.style.marginLeft = container.scrollLeft > 0 ? '160px' : 'unset';
					}
					setColumnForCalendar(column, left_colum);
				})

				let gantt_calendar_row_columns = container.querySelectorAll(".gantt-row-period.gantt_calendar_row");
				gantt_calendar_row_columns.forEach(function (column, key) {
					if (is_project_livrable_graphique || is_users_tasks_graphique)
						column.style.marginLeft = container.scrollLeft > 0 ? '160px' : 'unset';

					setColumnForCalendar(column, left_colum);
				})

				stickyElements.forEach((stickyElement, key) => {
					if (container.scrollLeft) {
						let left_offset = stickyElementsOffsets[key].left + container.scrollLeft;
						stickyElement.style.left = left_offset + "px";

						if (stickyElement.classList.contains("second_row"))
							stickyElement.style.height = stickyElementsOffsets[key].height + "px";
						else
							stickyElement.style.height = stickyElementsOffsets[key].height_2 + "px";

						if (stickyElement.classList.contains("multiple_lines"))
							stickyElement.closest(".gantt_calendar_row").style.height = (parseFloat(stickyElement.style.height) + 1) + "px";

						stickyElement.style.position = "absolute";
						stickyElement.style.width = stickyElementsOffsets[key].width + "px";
					} else {
						stickyElement.style.position = "static";
					}
				});
			}




			if (["btn_month"].includes(globalThis.selected_btn)) {
				$(".slide_buttons").show();
				var slide = document.getElementById('slide');

				slide.onclick = function () {
					sideScroll(container, 'right', 25, 10, 40);
				};

				var slideBack = document.getElementById('slideBack');

				slideBack.onclick = function () {
					sideScroll(container, 'left', 25, 10, 40);
				};

				slide.click();

				slideBack.click();
			} else {
				if (!$(".slide_buttons").is(":visible")) $(".slide_buttons").hide();
			}
		}
	}





	var formatDate = function (date, reverse = false, hour = null) {
		if (date) {
			let array = [
				padTo2Digits(date.getDate()),
				padTo2Digits(date.getMonth() + 1),
				date.getFullYear(),
			];

			if (reverse) array.reverse();

			let date_string = array.join('-');

			if (hour !== null) date_string = date_string + "T" + padTo2Digits(hour) + ":59:59";

			return date_string;
		}
	}


	globalThis.formatDate = formatDate;


	function padTo2Digits(num) {
		return num.toString().padStart(2, '0');
	}


	globalThis.padTo2Digits = padTo2Digits;



	function dayDiff(d1, d2, floor = false) {
		var diffTime = Math.abs(d2 - d1);
		var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

		if (floor) diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));

		return diffDays;
	}




	function hourDiff(d1, d2, floor = false) {
		var diffTime = Math.abs(d2 - d1);
		var diffHours = Math.ceil(diffTime / (1000 * 60 * 60)) % 24;

		if (floor) diffHours = Math.floor(diffTime / (1000 * 60 * 60)) % 24;

		return diffHours;
	}




	var getGanttElementFromPosition = function (x, y) {
		var items = shadowRoot.elementsFromPoint(x, y);
		var gantt_item = items.find(item => item.classList.contains("gantt-row-item"));

		return gantt_item;
	}




	var initJobs = function (jobs = []) {
		let min_hour, max_hour;

		const entreprise_hours = Object.values(JSON.parse($("#entreprise_hours").val()));


		if (vue_type == "day") {
			let min_max_hours = getMinAndMaxHourForDayView();
			min_hour = min_max_hours.min_hour;
			max_hour = min_max_hours.max_hour;
		}
		if (checkElements()) {
			jobs.forEach(job => {
				var date_string = "";

				if (vue_type == "month")
					date_string = formatDate(job.start, true);
				else if (vue_type == "week")
					date_string = formatDate(job.start, true, job.start.getHours());
				else {
					date_string = formatDate(job.start, true);

					if (job.start.getHours() < min_hour) {
						date_string += "T" + globalThis.padTo2Digits(min_hour + 1) + ":59:59";
					} else {
						let job_start_hour = job.start.getHours();
						date_string += "T" + globalThis.padTo2Digits(job_start_hour) + ":59:59";
					}

					if (job.end.getHours() > max_hour) job.end.setHours(max_hour - 1);
				}

				var ganttElement = shadowRoot.querySelector(`div[data-resource="${job.resource}"][data-date="${date_string}"]`);

				if (ganttElement && (!ganttElement.hasChildNodes())) {
					var jobElement = document.createElement("gantt-job");
					jobElement.id = job.id;
					jobElement.job = job;
					jobElement.type = job.type;
					jobElement.width = shadowRoot.querySelector(".gantt-row-period .gantt-row-item").getBoundingClientRect().width;

					let row = shadowRoot.querySelector(".gantt-row-period.gantt_calendar_row");

					if (vue_type == "month")
						jobElement.level = "month";
					else if (vue_type == "week")
						jobElement.level = "week";
					else
						jobElement.level = "day";

					ganttElement.appendChild(jobElement);

					jobElement.ondragstart = function (ev) {
						ev.dataTransfer.setData("job", ev.target.id);
						ev.dataTransfer.setData("start", ev.target.getAttribute("data-start"));
						ev.dataTransfer.setData("end", ev.target.getAttribute("data-end"));
						ev.dataTransfer.setData("pos", ev.target.getAttribute("data-pos"));
						ev.dataTransfer.setData("row", ev.target.getAttribute("data-row"));
						ev.dataTransfer.setData("type", ev.target.getAttribute("data-type"));

						if ("object_id") ev.dataTransfer.setData("object_id", ev.target.getAttribute("data-object_id"));
					};

					jobElement.addEventListener("editjob", (ev) => {
						var date_string = formatDate(job.start, true);
						var gantt_item = shadowRoot.querySelector(`div[data-resource="${job.resource}"][data-date="${date_string}"]`);

						gantt_item.appendChild(jobElement);
					});
				}
			});

			makeJobsResizable();
			addClickEventsToJobs();
		}
	}.bind(this);

	globalThis.initJobs = initJobs;






	var makeJobsResizable = function () {
		if (checkElements() && !(is_project_livrable_graphique || is_users_tasks_graphique)) {
			var container = shadowRoot.querySelector("#gantt-container");

			container.addEventListener("mousedown", _handleMouseDown, false);
		}
	}.bind(this);





	var addClickEventsToJobs = function () {
		if (checkElements()) {
			var container = shadowRoot.querySelector("#gantt-container");

			container.addEventListener("dblclick", _handleDblClick, false);
		}
	}.bind(this);






	var _handleDblClick = function (e) {
		if (e.target.tagName == "GANTT-JOB") {
			var jobElement = e.target;

			globalThis.handleItemDblClick(jobElement.id, jobElement.dataset);
		} else {
			e.preventDefault();
			e.stopPropagation();
			let date = e.target.getAttribute("data-date");
			let resource_id = e.target.getAttribute("data-resource");

			globalThis.handleCellDblClick(date, resource_id)
		}
	}





	var _handleMouseDown = function (e) {

		if (e.target.tagName == "GANTT-JOB") {
			this.selectedJobInitialPosition = e.x;
			this.selectedJobElement = e.target;
			this.selectedJobDiv = this.selectedJobElement.shadowRoot.querySelector(".job");
			this.currentJobWidth = parseFloat(this.selectedJobDiv.style.width);
			this.cellWidth = parseFloat(shadowRoot.querySelector(".gantt-row-item").offsetWidth);

			if (this.selectedJobElement.isMouseOverDragHandle(e)) {
				this.selectedJob = this.selectedJobElement.dataset;
				this.initialItem = new Object();

				Object.getOwnPropertyNames(this.selectedJob).forEach(key => {
					this.initialItem[key] = this.selectedJob[key];
				})

				if (this.selectedJob.type != "project_name_item") {
					document.addEventListener("mousemove", _handleMouseMove, false);
					document.addEventListener("mouseup", _handleMouseUp, false);
				}

				e.preventDefault();
			}
		}
	}.bind(this);





	var _handleMouseMove = function (ev) {
		var gantt_item = getGanttElementFromPosition(ev.x, ev.y);

		if (this.selectedJob && this.selectedJobElement && gantt_item) {

			let nb_cells = (Math.floor((ev.x - this.selectedJobInitialPosition) / this.cellWidth));
			let moveDistance = nb_cells * this.cellWidth + (nb_cells - 1);
			let current_width = this.currentJobWidth + moveDistance;

			if (current_width > 0 && moveDistance !== 0) {
				this.selectedJobElement.dataset.end = gantt_item.getAttribute("data-date");
				this.selectedJob.resize = true;
				this.selectedJob.end = this.selectedJobElement.dataset.end;
				this.selectedJobElement.update();
				this.selectedJobDiv.style.width = current_width + "px";
				this.selectedJobDiv.dataset.end = gantt_item.getAttribute("data-date");
			}
		}
	}.bind(this);



	function show_other_items_msg(msg = null) {
		$.notify({
			icon: '',
			message: msg ? msg : $("#other_items_msg").val()
		}, {
			type: 'danger',
			timer: 4000
		});
	}



	var _handleMouseUp = async function () {
		if (this.selectedJob) {
			let old_start_item_date = this.initialItem.start;
			let old_end_item_date = this.initialItem.end;

			if (typeof this.initialItem.start === "string") {
				old_start_item_date = new Date(this.initialItem.start);
				old_end_item_date = new Date(this.initialItem.end);
			}

			if (vue_type == "month") {
				old_end_item_date.setHours(23);
				old_end_item_date.setMinutes(59);
				old_end_item_date.setSeconds(59);
			}

			let new_start_item_date = this.selectedJob.start;
			let new_end_item_date = this.selectedJob.end;

			if (typeof this.selectedJob.start === "string") {
				if (this.selectedJob.start.includes("T"))
					this.selectedJob.start.replace("T", " ");

				if (this.selectedJob.end.includes("T"))
					this.selectedJob.end.replace("T", " ");

				new_start_item_date = new Date(this.selectedJob.start);
				new_end_item_date = new Date(this.selectedJob.end);
			}

			if (vue_type == "month") {
				if (new_end_item_date.getHours() == 1) {
					new_end_item_date.setHours(0);
					new_end_item_date.setSeconds(new_end_item_date.getSeconds() - 1);
				}
			}

			let type = "end";

			let old_date = formatDate(old_end_item_date, true);
			let new_date = formatDate(new_end_item_date, true);

			if (vue_type === "month") {
				old_date += " 23:59:59";
				new_date += " 23:59:59";
			} else if (vue_type == "week") {
				old_date += " " + old_end_item_date.toLocaleTimeString();
				new_date += " " + new_end_item_date.toLocaleTimeString();
			}

			let edit_data = {
				type,
				old_date,
				new_date
			}

			let betweenDatesStart = old_end_item_date;
			let betweenDatesEnd = new_end_item_date;

			if (new_end_item_date > old_end_item_date) {
				if (betweenDatesStart.getHours() == 23) betweenDatesStart.setSeconds(betweenDatesStart.getSeconds() + 1);

				if (betweenDatesStart.getHours() == 1) betweenDatesStart.setHours(0);
			}

			if (old_date !== new_date) {
				var container = shadowRoot.querySelector("#gantt-container");
				var currentScrollLeft = container.scrollLeft;

				if (this.selectedJob.row && !this.selectedJob.resource) this.selectedJob.resource = this.selectedJob.row;

				globalThis.update_project_item(edit_data, this.selectedJob, currentScrollLeft, true);
			} else {
				if (old_date !== new_date) {
					let msg = null;

					if (this.selectedJob.type == "materiel") msg = $("#materiel_already_affected_msg").val();

					show_other_items_msg(msg);
				}

				this.selectedJob.end = this.initialItem.end;
				this.selectedJobElement.update();
				this.selectedJobDiv.dataset.end = this.initialItem.end;
			}

			delete this.selectedJob.resize;
		}

		this.selectedJob = null;
		this.selectedJobElement = null;

		document.removeEventListener("mousemove", _handleMouseMove, false);
	}.bind(this);



	this.render = function () {
		clear();
		initGantt();
	}

	var resizeTimer;


	$(window).on('resize', function (e) {
		clearTimeout(resizeTimer);

		resizeTimer = setTimeout(function () {
			if (globalThis.selected_btn === "btn_week") initGantt(true);
		}, 250);
	});
}
