
let meetingDatable; 

function initMeetingDatable() {
    let client_id = $("#client_id").val();

    let column = [
        { "data": "id", "targets": 0 },
        { "data": "client", "targets": 1 },
        { "data": "type", "targets": 2 },
        { "data": "date", "targets": 3 },
        { "data": "etat", "targets": 4 },
        { "data": "action", "targets": 5 },
    ];

    if ($.fn.DataTable.isDataTable('#mettings_list')) {
        meetingDatable.clear().destroy();
    }

    meetingDatable = $('#mettings_list').DataTable({
        "columnDefs": column,
        "processing": true,
        "serverSide": true,
        "ajax": Routing.generate('server_processing_mettings', { "iso_code": $("#local").val(), client_id }),
        "paging": true,
        "pageLength": 20,
        "lengthChange": false,
        "searching": true,
        "ordering": true,
        "order": [[0, "desc"]],
        "info": false,
        "autoWidth": false,
        "responsive": true,
        "scrollX": true,
        "language": {
            "url": $("#datatable_lang").val()
        },
    });
}
function getClientSelect(client_id, type) {
    var clientUser=$("#clientUser").val();
    var roleUser =$("#roleUser").val();
    var isUserResponsable =$("#isUserResponsable").val();
    console.log(isUserResponsable);
    if (type) {
        $.ajax({
            method: 'POST',
            url: Routing.generate('ajax_get_clients_list', { type, client_id }),
            data: {isUserResponsable: isUserResponsable } , 

            success: function (html) {
                $("#meeting-client").html(html);
                $("#client").select2({
                    sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),
                    width: "100%",
                    multiple: false,
                });
                if (clientUser || (roleUser =="ROLE_USER" && isUserResponsable != 1) ) {
                    $("#client").prop('disabled', true);
                }

            }
        });
    } else {
        $("#meeting-client").html("");
    }
}





$(document).ready(function () {


    if ($("#client_id").val() && $("#client_id").length) {
        getClientSelect($("#client_id").val(), $("#mettings_client_type").val());
    }

    if ($("#mettings_list").length) {
        initMeetingDatable()
    }


    $(document).on('change', '#mettings_client_type', function () {
        let type = $(this).val();
        getClientSelect(null, type);
        getClientSelect($("#client_id").val(), type);
    })

    
    if ($("#mettings_is_online").length) {
        if ($("#mettings_is_online").val() == "1")
            $("#online-meet-info").show();
        else
            $("#online-meet-info").hide();
    }

    $(document).on('change', '#mettings_is_online', function () {
        if ($("#mettings_is_online").val() == "1")
            $("#online-meet-info").show();
        else
            $("#online-meet-info").hide();

    })

    $(document).on('click', '#is_confirmed', function () {
        var meetingUserId = $(this).data('id');
        var idclientMeeting = typeof idclientMeeting !== 'undefined' ? idclientMeeting : null;
        var nbRendezVous = parseInt($("#nbRendezVous").text()) || 0; 

        console.log(nbRendezVous);
        $.ajax({
            method: 'POST',
            url: Routing.generate('ajax_confirme_rendez_vous'),
            data: {meetingUserId: meetingUserId ,iso_code: $("#local").val() , idclientMeeting : idclientMeeting } , 
            success: function () {
                if (nbRendezVous > 0) {
                    nbRendezVous -= 1; 
                    $("#nbRendezVous").text(nbRendezVous); 
                }
                if (nbRendezVous === 0) {
                    $("#nbRendezVous").remove(); 
                }
                initMeetingDatable()
            }
        });
    });
    $(document).on('click', '#is_refused', function () {
        var meetingUserId = $(this).data('id');
        var idclientMeeting = typeof idclientMeeting !== 'undefined' ? idclientMeeting : null;
        var nbRendezVous = parseInt($("#nbRendezVous").text()) || 0; 

        $.ajax({
            method: 'POST',
            url: Routing.generate('ajax_refuse_rendez_vous'),
            data: {meetingUserId: meetingUserId , iso_code: $("#local").val() , idclientMeeting : idclientMeeting } , 
            success: function () {
                if (nbRendezVous > 0) {
                    nbRendezVous -= 1; 
                    $("#nbRendezVous").text(nbRendezVous); 
                }
                if (nbRendezVous === 0) {
                    $("#nbRendezVous").parent().remove(); 
                }
                initMeetingDatable()
            }
        });
    });
})