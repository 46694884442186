
let annonces_table =null;

function loadAnnonce()
{
    
        var columnDefs = [
            { "data": "id", "targets": 0 }, 
            { "data": "titre", "targets": 1 },
            { "data": "type", "targets": 2 },
            { "data": "nbcondidature", "targets": 3 },
            { "data": "action", "targets": 4 },
        ];
    

        annonces_table = $('#datatable_annonces').DataTable({
        "columnDefs": columnDefs,
        "processing": true,
        "serverSide": true,
        "ajax": {
            "url": Routing.generate('server_processing_annonces', {"_locale": $('#local').val()}),
            "type": "POST",
            "data": function (d) {
                d._locale = $('#local').val();
             },

        },
        "paging": true,
        "pageLength": 20,
        "lengthChange": false,
        "searching": true,
        "ordering": true,
        "order": [[0, "desc"]],
        "info": false,
        "autoWidth": false,
        "responsive": true,
        "scrollX": true,
        "language": {
            "url": $("#datatable_lang").val()
        },
    });
	
    return annonces_table;
}

$(document).ready(function () {


  
    if($('#datatable_annonces').length){
		loadAnnonce()

	}
    


})