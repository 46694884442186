"use strict";
;(function ($) { $.fn.datepicker.language['fr'] = {
    days: ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'],
    daysShort: ['Dim','Lun','Mar','Mer','Jeu','Ven','Sam'],
    daysMin: ['Di','Lu','Ma','Me','Je','Ve','Sa'],
    months: ['Janvier','Fevrier','Mars','Avril','Mai','Juin',
    'Juillet','Aout','Septembre','Octobre','Novembre','Decembre'],
    monthsShort: ['Jan','Fev','Mar','Avr','Mai','Jun',
    'Jul','Aou','Sep','Oct','Nov','Dec'],
    today: 'Aujourd\'hui',
    clear: 'Fermer',
    dateFormat: 'dd/mm/yyyy',
    timeFormat: 'hh:ii aa',
    firstDay: 1
}; })(jQuery);