var client_users_table = null;

export function ClientUserDatatable() {
	client_users_table = $('#client_user_list').DataTable({
		"columnDefs": [
			{ "data": "id", "targets": 0 },
			{ "data": "nom", "targets": 1 },
			{ "data": "email", "targets": 2 },
			{ "data": "date_creation", "targets": 3 },
			{ "data": "action", "targets": 4, orderable: false },
		],
		"processing": true,
		"serverSide": true,
		"ajax": Routing.generate('server_processing_clients_user', { '_locale': $('#local').val(), 'client_id': $('#client_id').val() }),
		"paging": true,
		"pageLength": 20,
		"lengthChange": false,
		"searching": true,
		"ordering": true,
		"order": [[0, "desc"]],
		"info": false,
		"autoWidth": false,
		"responsive": true,
		"scrollX": true,
		"language": {
			"url": $("#datatable_lang").val()
		},
	})
	return client_users_table;
}

$(document).ready(function () {

	client_users_table = ClientUserDatatable();

	var locale = $('#local').val();


	$(document).on('click', '.demande_password', function () {

		var id = $(this).attr('data-id');
		let isDemandPassword = true;

		$.ajax({
			method: 'POST',
			url: Routing.generate('demander_mot_passe', { 'id': id, '_locale': locale }),
			data: { isDemandPassword },
			success: function (res) {
				if (res == "done") {
					var msg = $('#envoi_email_password_success').val();
					$.notify({
						icon: '',
						message: msg
					}, {
						type: 'success',
						timer: 4000
					});
				}
			}
		});

	});

	$(document).on('click', '.btn_save_client_user', function (e) {
		let password = $("#clients_user_password_second").val();
		let pasword_confirm = $("#clients_user_password_first").val();
		e.preventDefault();
		$("#password_missmatch").hide();
		if (password == pasword_confirm) {
			$("form[name='clients_user']").submit();
		} else {
			$("#password_missmatch").show();
		}
	});
	$(document).on('click', '.btn_add_edit_client', function (e) {
		let password = $("#clients_password_first").val();
		let pasword_confirm = $("#clients_password_second").val();
		e.preventDefault();
		$("#password_missmatch").hide();
		if (password == pasword_confirm) {
			$("form[name='clients']").submit();
		} else {
			$("#password_missmatch").show();
		}
	});
});
