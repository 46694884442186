import { initRichText, applySelect2 } from "../custom/global.js"
var scrolled = true

var oldInputText = "";

Dropzone.autoDiscover = false;

function livrableSelectView() {
	if ($(" #projects_list").val() && $(" #projects_list").val() != '0')
		$("#div_projects_livrable").show()
	else
		$("#div_projects_livrable").hide()
}

function messagesView() {
	if ($(".modal-edit_ticket #project_tickets_project").val() && $(".modal-edit_ticket #project_tickets_project").val() != '0' && ($(".modal-edit_ticket #projects_livrable").val() || $(".modal-edit_ticket #project_tickets_livrable").val())) {
		$(".message_ticket_div").show();
	}
	else {
		$(".message_ticket_div").hide();
	}
}
function initMessageInpute() {
	if ($("#ticket_document_dropzone").length) {
		var myDropzone = new Dropzone('#ticket_document_dropzone', {
			addRemoveLinks: true,
			uploadMultiple: true,
			autoProcessQueue: false,
			parallelUploads: 100,
			maxFiles: 100,
			timeout: null,
			url: $('#ticket_document_dropzone').attr('data-url'),
			init: function () {
				var submitButton = document.querySelector(".send-ticket-message-button");
				var myDropzone = this;
				var id = $(".send-ticket-message-button").attr('data-id');
				submitButton.addEventListener("click", function (e) {
					e.preventDefault();
					e.stopPropagation();
					if (myDropzone.files.length) {
						myDropzone.processQueue();
					} else {
						var formData = new FormData($("form[name='project_tickets_messages']")[0]);
						formData.append("tickets_id", $("#tickets_id").val());
						formData.append("message", $(".message-ticket-input").val());
						formData.append("add_msg", "add_msg");
						if (id) formData.append("message_id", id);
						$.ajax({
							type: "POST",
							url: Routing.generate("submitAddEditeMessageModal"),
							data: formData,
							contentType: false,
							processData: false,
							success: function (data) {
								updateMessages(null, null, "add_msg");
								$(".message-ticket-input").val("")
								Dropzone.forElement('#ticket_document_dropzone').removeAllFiles(true)
							}
						})
					}
				});

				this.on("sendingmultiple", function (file, xhr, formData) {
					formData.append("tickets_id", $("#tickets_id").val());
				});

				this.on("successmultiple", function (files, response) {
					var formData = new FormData($("form[name='project_tickets_messages']")[0]);
					formData.append("upload_files_id", response["upload_files_id"]);
					formData.append("temporaryFilesPath", response["temporaryFilesPath"]);
					formData.append("tickets_id", $("#tickets_id").val());
					formData.append("message", $(".message-ticket-input").val());
					formData.append("add_msg", "add_msg");
					if (id) formData.append("message_id", id);
					$.ajax({
						type: "POST",
						url: Routing.generate("submitAddEditeMessageModal"),
						data: formData,
						contentType: false,
						processData: false,
						success: function (data) {
							updateMessages(null, null, "add_msg");
							$(".message-ticket-input").val("")
							Dropzone.forElement('#ticket_document_dropzone').removeAllFiles(true)

						}
					})
				});

			}
		});
	}
}

function annulerEditeMessage() {
	$(".message-ticket-input-edite").val("");
	$(".send-ticket-message-edite-button").removeClass('annuler-edite');
	$(".send-ticket-message-edite-button").addClass('valider-edite');
	$(".annuler-edite-icon").hide();
	$(".envoiye-edite-icon").show();

	$("#message_inpute_div").show();
	$("#message_inpute_div_edite").hide();
}

function updateMessages(start = null, end = null, add_msg = null) {
	$.ajax({
		method: 'POST',
		sync: false,
		url: Routing.generate('ajax_get_message_temp'),
		data: { 'ticket_id': $("#tickets_id").val(), 'start_msg': start, 'length_msg': end },
		success: function (reponse) {
			var container = $('#discution-div')
			if (!$("#tickets_id").val()) {
				container.html(reponse['html']);
				container.scrollTop(container[0].scrollHeight);
			} else {
				if (!add_msg) {
					$(".vide-discution-div").after(reponse['html']);

					scrolled = false


					if (start == 0) {

						container.scrollTop(container[0].scrollHeight);
					} else {
						container.scrollTop(150)
					}
					scrolled = true
					//setTimeout(function() {scrolled = true}, 500);

				} else {
					container.html(reponse['html']);
					container.scrollTop(container[0].scrollHeight);
				}
			}



			$("#is_end").val(reponse['is_end'])
		}
	});
}

var ticketstatus = null;
function OnLoadTicketStatusDataTable() {
	let table = $("#table_tickets_statuts").DataTable({
		"columnDefs": [
			{ "data": "id", "targets": 0, "orderable": false },
			{ "data": "libelle", "targets": 1, "orderable": false },
			{ "data": "position", "targets": 2, "orderable": false },
			{ "data": "action", "targets": 3, "orderable": false },
		],
		"processing": true,
		"serverSide": true,
		"ajax": Routing.generate('server_processing_tickets_statuts', { "iso_code": $("#local").val() }),
		"paging": true,
		"pageLength": 10,
		"lengthChange": false,
		"lengthMenu": [[20, 50, 100, -1], [20, 50, 100, "All"]],
		"language": {
			url: "/datatable_fr.json",
			paginate: {
				next: "<i class='ti-arrow-right'></i>",
				previous: "<i class='ti-arrow-left'></i>"
			}
		},
		"order": [[2, "asc"]],
		"info": false,
		"ordering": true,
		"searching": true,
		"bLengthChange": false,
		"scrollX": true,
		"rowReorder": {
			dataSrc: 'element_menu',
		},
	});

	return table;
}

function OnLoadTicketArchiveDataTable() {
	if ($("#ticket_archive_list").length) {

		let ticket_archive_list = $("#ticket_archive_list").DataTable({
			"columnDefs": [
				{ "data": "id", "targets": 0, "orderable": true },
				{ "data": "titre", "targets": 1, "orderable": true },
				{ "data": "projet", "targets": 2, "orderable": true },
				{ "data": "livrable", "targets": 3, "orderable": true },
				{ "data": "action", "targets": 4, "orderable": false },
			],
			"processing": true,
			"serverSide": true,
			"ajax": Routing.generate('server_processing_project_tickets_archiver', { "iso_code": $("#local").val() }),
			"paging": true,
			"pageLength": 10,
			"lengthChange": false,
			"lengthMenu": [[20, 50, 100, -1], [20, 50, 100, "All"]],
			"language": {
				url: "/datatable_fr.json",
				paginate: {
					next: "<i class='ti-arrow-right'></i>",
					previous: "<i class='ti-arrow-left'></i>"
				}
			},
			"order": [[2, "asc"]],
			"info": false,
			"ordering": true,
			"searching": true,
			"bLengthChange": false,
			"scrollX": true,
			"rowReorder": {
				dataSrc: 'element_menu',
			},
		});

		return ticket_archive_list;
	}
}

var dragItem = null;

function allowDrop(event) {
	event.preventDefault();
}

function update_position(statut_id, ticket_id) {

	$.ajax({
		method: 'POST',
		url: Routing.generate('update_status_position'),
		data: { ticket_id, statut_id },
		success: function () {
			$(".ui-widget-overlay").hide();
			$(".Customloader").hide();
		}
	});

}

function dragStart() {

	dragItem = this;
	setTimeout(() => this.className = 'invisible', 0)
}

function dragEnd() {
	$(".ui-widget-overlay").show();
	$(".Customloader").show();
	this.className = 'item'
	var statut_id = dragItem.closest('.columnEtat.droppable').getAttribute('data-status');
	var ticket_id = dragItem.getAttribute('data-id');
	update_position(statut_id, ticket_id);
	dragItem = null;
}

function dragOver(e) {
	e.preventDefault()
}



function dragDrop() {
	if (dragItem != null) this.append(dragItem);

}

function initialiseDragging() {
	var items = document.querySelectorAll('.item')
	var columns = document.querySelectorAll('.columnEtat')
	items.forEach(item => {
		item.addEventListener('dragstart', dragStart)
		item.addEventListener('dragend', dragEnd)
	});
	columns.forEach(column => {
		column.addEventListener('dragover', dragOver);
		column.addEventListener('drop', dragDrop);
	});
}

function checkMessageScroll() {

	$('#discution-div').on("scroll", function (e) {

		var container = $(this);

		var scrollTop = container.scrollTop();

		if (scrollTop == 0 && $("#is_end").val() == "false" && scrolled) {

			$("#start_msg").val(parseInt($("#start_msg").val()) + parseInt($("#length_msg").val()));

			var start = $("#start_msg").val();
			var end = $("#length_msg").val();

			updateMessages(start, end);

		}

	});
}

function updateProjectsList(project_id, result_show, livrable_id = null, ticket_id = null) {
	const edit = $("#isEdit").val();
	$.ajax({
		type: "POST",
		url: Routing.generate("get_project_livrables"),
		data: { project_id, livrable_id, edit },
		success: function (result) {
			result_show.html(result);
			livrableSelectView();
			applySelect2($(".select2"));

			if ($("#current_livrable_id").length && $("#current_livrable_id").val()) {
				$(".modal-body #projects_livrable").val($("#current_livrable_id").val()).trigger('change');
				$(".modal-body #projects_livrable").attr("readonly", true);
			} else if (livrable_id) {
				$(".modal-body #projects_livrable").val(livrable_id).trigger('change');
			}

			$.ajax({
				method: 'POST',
				url: Routing.generate('updateSelectdestinataire'),
				data: {
					"ticket_id": ticket_id ?? null,
					"project_id": $("#project_tickets_project").val() ?? null,
					"projectLivrable_id": livrable_id ?? $("#current_livrable_id").val(),
					"_local": $("#local").val()
				},
				success: function (response) {
					$(".divProjectTicketDestination").html(response)
					applySelect2($(".select2"));
					setTimeout(() => { messagesView(); checkMessageScroll(); }, 1000);
				}
			})
		},

	});
}


function updateTickets(project_id, livrable_id, check_for_modal = false) {
	$(".ui-widget-overlay").show();
	$(".Customloader").show();

	setTimeout(function () {
		$.ajax({
			type: "POST",
			url: Routing.generate("filter_ticket_etat"),
			data: { project_id, livrable_id },
			success: function (data) {
				$(".containerTicket").html(data);
				$('.columnEtat.droppable').on('dragover', allowDrop);
				initialiseDragging()
				$(".ui-widget-overlay").hide();
				$(".Customloader").hide();
				if (check_for_modal && $("#current_ticket_id").length && $("#current_ticket_id").val())
					$("#card-ticket-" + $("#current_ticket_id").val()).trigger("click");
			}
		})
	}, 500);
}
$(document).ready(function () {


	var ticket_archive_list = OnLoadTicketArchiveDataTable();

	$('.columnEtat.droppable').on('dragover', allowDrop);

	initialiseDragging();

	ticketstatus = OnLoadTicketStatusDataTable();

	$("#table_tickets_statuts").on('row-reordered.dt', function (e, diff, edit) {
		let tabId = Array();
		let move = "";

		$("#table_tickets_statuts tbody tr").each(function (index) {
			let id = $(this).find(".Div_tickets").data("id");
			tabId.push(id);
		});

		if (diff.length > 1) {
			$.ajax({
				method: 'POST',
				url: Routing.generate('update_ticket_position', {
					"tabId": tabId,
				}),
				success: function (data) { }
			});
		}
	});

	$(document).on('click', '.file-link', function (e) {
		e.preventDefault();
		var fileUrl = $(this).attr('data-href');
		var downloadAnchor = $('<a style="display:none;" href="' + fileUrl + '" download></a>');
		$('body').append(downloadAnchor);
		downloadAnchor[0].click();
		downloadAnchor.remove();
	});


	$(document).on('click', '.message-block p.sender-name span i.pe-7s-more', function (event) {
		$(this).parents('.message-block').eq(0).find('.message-option').toggleClass("show-dropdown");
		$(".message-block p.sender-name span i.pe-7s-more").each(function (index) {
			$(this).css('visibility', 'hidden')
		});
	});

	$(document).on('click', function (event) {
		if (!$(event.target).closest('.message-block p.sender-name span i.pe-7s-more').length) {
			$('.message-option.show-dropdown').removeClass('show-dropdown');
			$(".message-block p.sender-name span i.pe-7s-more").each(function (index) {
				$(this).css('visibility', 'visible')
			});
		}
	});



	if ($("#discution-div").length) {
		updateMessages();
	}

	/* if ($("#project_tickets_project").length) {
		if ($("#project_tickets_project").val()) {}
	} */

	$(document).on('change', '#project_tickets_project', function () {
		if ($("#project_tickets_project").val()) {
			updateProjectsList($("#project_tickets_project").val(), $(".project_livrable_info"));
		}
		else {
			$(".project_livrable_info").hide();
		}
	});



	$(document).on('click', '.delete_file_footer', function () {
		var id = $(this).attr("data-id");
		var id_message = $(this).attr("id_message");

		$.ajax({
			method: 'POST',
			url: Routing.generate('delete_message_file', { 'file': id }),
			data: {
				"id_message": id_message,
			},
			success: function (response) {
				$("#image_" + id).hide();
				updateMessages();
			}
		});
	});


	$(document).on('click', '#btn_add_ticket_message', function () {
		var href = $(this).attr("data-href");
		var session_id = $(this).attr("data-sessionId");
		$.ajax({
			method: 'POST',
			url: Routing.generate('set_ticket_session'),
			data: {
				ticket_session_id: $('input[name= "ticket_session_id"]').length ? $('input[name= "ticket_session_id"]').val() : session_id,
				project: $("#project_tickets_project").val(),
				livrable: $("#project_tickets_livrable").val(),
				developper: $("#project_tickets_developper").val(),
				state: $("#project_tickets_state").val(),
			},
			success: function (response) {
				window.location.href = href;
			}
		});
	});

	$(document).on('click', '.download-tmp-file', function () {
		var href = $(this).attr("data-href");
		$.ajax({
			method: 'POST',
			url: Routing.generate('ajax_get_file_from_temp'),
			data: { file_path: href },
			success: function (path) {
				window.open(path);
				setTimeout(function () {
					$.ajax({
						method: 'POST',
						url: Routing.generate('ajax_delete_file'),
						data: { file_path: path },
						success: function (path) { }
					});
				}, 5000);
			}
		});
	});

	$('#upload_files').on('hidden.bs.modal', function () {
		setTimeout(() => $('body').addClass('modal-open'), 500);
	});

	$('#modal_edit_ticket').on('hidden.bs.modal', function () {
		$("#length_msg").val($("#default_length_msg").val());
		$("#start_msg").val(0);
	});


	if ($("#tablistSatut_livrable").length) {
		var project_id = $('#current_project_id').val();
		var livrable_id = $('#current_livrable_id').val();
		updateTickets(project_id, livrable_id)
	}

	if ($(".containerTicket").length) {
		var project_id = $('#projects_list').val();
		var livrable_id = $('#projects_livrable:visible').val();
		updateTickets(project_id, livrable_id, true)
	}

	$(document).on('change', '#select_categories_container #projects_list , #select_categories_container #projects_livrable', function () {
		var livrable_id = $('#projects_livrable:visible').val();
		var project_id = $('#projects_list:visible').val();
		if ($(this).is('#projects_list')) {
			updateProjectsList($('#projects_list').val(), $("#div_projects_livrable"));
			livrable_id = null;
		}
		updateTickets(project_id, livrable_id)
	})

	$(document).on('click', '.send-ticket-message-button', function (event) {
		event.preventDefault();
	});

	$(document).on('click', '.item', function () {
		var ticket_id = $(this).attr("data-id");
		var livrable_id = $(this).attr("data-livrable");
		$("#tickets_id").val(ticket_id);
		$.ajax({
			method: 'POST',
			url: Routing.generate('edit_ticket_project_livrable'),
			data: {
				"ticket_id": ticket_id,
				"project_id": $("#current_project_id").val(),
				"projectLivrable_id": livrable_id ?? $("#current_livrable_id").val(),
				"_local": $("#local").val()
			},
			success: function (response) {
				$("#modal_edit_ticket .modal-body").html(response);
				updateMessages($("#start_msg").val(), $("#length_msg").val());
				$("#modal_edit_ticket .project_livrable_info").show();
				applySelect2($(".select2"));
				$(".modal-body #project_tickets_project").trigger("change");
				updateProjectsList($("#project_tickets_project").val(), $("#project_tickets_livrable"), livrable_id, ticket_id);
				$(".titel-modal-ticket").text($("#modal-ticket-titel").val())
				$("#modal_edit_ticket").modal("show");
				$('#modal_edit_ticket .close-modal').hide();
				initMessageInpute();
			}
		});
	});


	$(document).on('change', '#projects_livrable', function () {
		$.ajax({
			method: 'POST',
			url: Routing.generate('updateSelectdestinataire'),
			data: {
				"ticket_id": $("#ticket_id").val() ?? null,
				"project_id": $("#project_tickets_project").val() ?? null,
				"projectLivrable_id": $(this).val() ?? null,
				"_local": $("#local").val()
			},
			success: function (response) {
				$(".divProjectTicketDestination").html(response)
				applySelect2($(".select2"));


			}

		})
	});

	if ($("#ticket_document_dropzone").length) initMessageInpute();

	$(document).on('click', '.ticket-attache-file', function () {
		$(".dz-button").trigger('click')
	});

	$(document).on('click', '.edite-message-ticket-option', function () {
		let message = $(this).closest(".message-block").find('.message-container p:not(.titel-attachment-item)').text();
		let id = $(this).attr('id');
		oldInputText = message;
		$("#message_inpute_div").hide();
		$("#message_inpute_div_edite").show();
		$(".message-ticket-input").val("");
		Dropzone.forElement('#ticket_document_dropzone').removeAllFiles(true)
		$(".message-ticket-input-edite").val(message);
		$(".send-ticket-message-edite-button").attr("data-id", id);
	});

	$(document).on('keyup', '.message-ticket-input-edite', function () {
		if (oldInputText != $(this).val() && $(this).val()) {
			$(".send-ticket-message-edite-button").removeClass('annuler-edite');
			$(".send-ticket-message-edite-button").addClass('valider-edite');
			$(".annuler-edite-icon").hide();
			$(".envoiye-edite-icon").show();
		} else {
			$(".send-ticket-message-edite-button").removeClass('annuler-edite');
			$(".send-ticket-message-edite-button").addClass('valider-edite');
			$(".annuler-edite-icon").show();
			$(".envoiye-edite-icon").hide();
		}
	});

	$(document).on('click', '.send-ticket-message-edite-button', function (event) {
		event.preventDefault();
		let id = $(this).attr('data-id');
		if ($(this).hasClass('annuler-edite-icon')) {
			annulerEditeMessage();
		} else {
			var formData = new FormData($("form[name='project_tickets_messages']")[0]);
			formData.append("tickets_id", $("#tickets_id").val());
			formData.append("message", $(".message-ticket-input-edite").val());
			formData.append("add_msg", "add_msg");
			formData.append("message_id", id);
			$.ajax({
				type: "POST",
				url: Routing.generate("submitAddEditeMessageModal"),
				data: formData,
				contentType: false,
				processData: false,
				success: function (data) {
					updateMessages(null, null, "add_msg");
					annulerEditeMessage();
				}
			})
		}

	});

	$(document).on('click', '.supprime-message-ticket-option', function () {
		let id = $(this).attr('id');
		let tickets_id = $("#tickets_id").val()
		annulerEditeMessage();
		$.ajax({
			type: "POST",
			url: Routing.generate("deletMessage"),
			data: { id, tickets_id },
			success: function (data) {
				updateMessages(null, null, "add_msg");
			}
		})
	});

	$(document).on('click', '.ajouter-ticker-btn', function () {
		var livrable_id = $(this).attr("data-livrable");
		$("#tickets_id").val('');
		$.ajax({
			method: 'POST',
			url: Routing.generate('edit_ticket_project_livrable', { _local: $("#local").val() }),
			data: {
				"project_id": $("#current_project_id").val(),
				"projectLivrable_id": $("#current_livrable_id").val(),
				"_local": $("#local").val(),
			},
			success: function (response) {
				$("#modal_edit_ticket .modal-body").html(response);
				updateMessages($("#start_msg").val(), $("#length_msg").val());
				$("#modal_edit_ticket .project_livrable_info").show();
				applySelect2($(".select2"));
				updateProjectsList($("#project_tickets_project").val(), $(".project_livrable_info"), livrable_id, null);
				$(".titel-modal-ticket").text($("#modal-ticket-titel").val())
				$("#modal_edit_ticket").modal("show");
				$('#modal_edit_ticket .close-modal').hide();
				initMessageInpute();
				$.ajax({
					method: 'POST',
					url: Routing.generate('updateSelectdestinataire'),
					data: {
						"ticket_id": null,
						"project_id": $("#project_tickets_project").val() ?? null,
						"projectLivrable_id": livrable_id,
						"_local": $("#local").val()
					},
					success: function (response) {
						$(".divProjectTicketDestination").html(response)
						applySelect2($(".select2"));

					}
				})
			}
		});
	})

	if ($("#projects_list").length && $("#projects_livrable").length) {
		livrableSelectView();
	}

	$(document).on('change', '#projects_list', function () {
		livrableSelectView();
	})
	$(document).on('change', '.modal-edit_ticket #project_tickets_project,.modal-edit_ticket #projects_livrable', function () {
		messagesView();
	})


	$(document).on("click", '.ticket-archive a ,#BtnDearchiver ', function (e) {

		e.stopPropagation();
		$("#modalArchived #confirmation_btn").attr("data-id", $(this).attr("data-id"))
		$("#modalArchived #confirmation_btn").attr("data-type", $(this).attr("data-type"))
		$("#modalArchived").modal("show");

	})
	$(document).on("click", '#modalArchived #confirmation_btn', function () {
		var id = $(this).attr("data-id");
		var type = $(this).attr("data-type");

		$.ajax({
			method: 'POST',
			url: Routing.generate('update_status_archive'),
			data: { 'id': id, "type": type },
			success: function (res) {
				if (type == 'Dearchiver') {
					ticket_archive_list.ajax.reload();
				} else {
					$("#card-ticket-" + id).remove()
				}


			}
		})
	})



});
