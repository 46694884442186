function format_for_treeview(data, arr) {
	for (var key in data) {
		if (Array.isArray(data[key]) || data[key].toString() === "[object Object]") {
			var nodes = [];
			var completedNodes = format_for_treeview(data[key], nodes);

			arr.push({
				text: key,
				nodes: completedNodes,
				state: {
					expanded: true,
				},
			});
		} else {
			arr.push({
				text: key + " : " + data[key]
			});
		}
	}

	return arr;
}





function category_tree_set_id() {
	$(".list-group-item").each(function (index) {
		myArray = $(this).text().split("|");

		if (myArray != 0) {
			$(this).attr("id", myArray[1]);
			var html = $(this).html();
			var rep = $(this).text();
			var result = html.replace(rep, myArray[0]);
			$(this).html(result);
		} else {
			$(this).attr("id", "");
		}
	});
}


function OnLoadCategoryDataTable() {

	let table = $("#category_list").DataTable({
		"columnDefs": [
			{ "data": "id", "targets": 0 },
			{ "data": "Libelle", "targets": 1 },
			{ "data": "Category", "targets": 2 },
			{ "data": "nbr_vues", "targets": 3, "orderable": false },
			{ "data": "Action", "targets": 4, "orderable": false },
		],
		"processing": true,
		"serverSide": true,
		"ajax": Routing.generate('server_processing_categories', { "iso_code": $("#local").val(), 'id_project': $("#current_id_project").val() }),
		"paging": true,
		"pageLength": 10,
		"lengthChange": false,
		"lengthMenu": [[20, 50, 100, -1], [20, 50, 100, "All"]],
		"language": {
			url: "/datatable_fr.json",
			paginate: {
				next: "<i class='ti-arrow-right'></i>",
				previous: "<i class='ti-arrow-left'></i>"
			}
		},
		"order": [[0, "desc"]],
		"info": false,
		"ordering": true,
		"searching": true,
		"bLengthChange": false,
		"scrollX": true,
	});

	return table;
}


$(document).ready(function () {


	var CategoryDataTable = OnLoadCategoryDataTable();


	$(document).on('click', '#category_save', function () {
		var treeText = $('.node-selected').attr("id");
		$('#treeval').val(treeText);
		$("#submit_form").submit();
	});

	var lang = $("#idLang").val();
	var id_cat = $("#id_item").val();

	if (typeof (lang) != "undefined") {
		$.ajax({
			method: 'GET',
			dataType: 'json',
			url: Routing.generate('category_treeview', { 'lang': lang, 'id_cat': id_cat }
			), success: function (Data) {
				$('#myTree').treeview({
					data: format_for_treeview(Data, [])
				});

				category_tree_set_id();

				var idsub = $('#idtree').val();

				$(".list-group-item").each(function (index) {
					if ($(this).attr("id") == idsub) {
						$(this).css("background-color", "#428bca");
						$(this).css("color", "#FFFFFF");
						$(this).addClass("node-selected");
					}
				});
			}
		});
	}



	$('#project_categories').select2();



	$(document).on('click', '.icons_category_activate', function () {
		var id = $(this).attr("data-id");

		$.ajax({
			method: 'GET',
			url: Routing.generate('activate_category', { 'id': id }),
			success: function (data) {
				CategoryDataTable.ajax.reload();
			}
		});
	});



	$(document).on('click', '.supprimer_button', function () {
		var id = $(this).attr("data-id");

		$.ajax({
			method: 'GET',
			url: Routing.generate('remove_category', { 'id': id }),
			success: function (data) {
				CategoryDataTable.ajax.reload();
				$.modal.close();
			}
		});
	});



	$(document).on('click', '.list-group-item', function () {
		category_tree_set_id();
	});



	if ($("#categories").length > 0) {
		$("#categories").select2({
			sorter: data => data.sort((a, b) => a.text.localeCompare(b.text)),
			width: '100%'
		});
	}


	$(document).on('change', '#categories', function () {
		if ($(this).val().length > 0) {
			let url = $("#front_projects_url").val();
			let categories = $(this).val().join("+");
			url += '?f=' + categories;
			$("#url_projects_front").html(url);
		} else
			$("#url_projects_front").html("");
	});


});

