import { getOpositeColor, areSameDate } from "./global.js";

const template = document.createElement('template');

template.innerHTML = $('#template_job').html();




var handleDeleteItem = function (dataset, element = null) { }
globalThis.handleDeleteItem = handleDeleteItem;



var handleItemClick = function (job_id, dataset) { }
globalThis.handleItemClick = handleItemClick;

var customDiffhours = function (heur1, heur2) {
	let start_heur = Math.min(heur1, heur2);
	let end_heur = Math.max(heur1, heur2);
	let diff = 0;

	const entreprise_hours = Object.values(JSON.parse($("#entreprise_hours").val()));
	for (let heur = start_heur; heur < end_heur; heur++) {
		if (entreprise_hours.includes(heur)) diff++
	}
	return diff;
}

export default class GanttJob extends HTMLElement {
	constructor() {
		super();

		this.attachShadow({ mode: 'open' });
		this.shadowRoot.appendChild(template.content.cloneNode(true));
	}

	_job;
	_level = "week";

	connectedCallback() {
		var nb_click = 0;
		var gantElement = this;
		var jobElement = this.shadowRoot.querySelector(".job");

		jobElement.id = this.id;

		if (this.job.pos) {
			jobElement.dataset.pos = this.job.pos;
			this.dataset.pos = this.job.pos;
		}

		if (this.job.clients) jobElement.dataset.clients = JSON.stringify(this.job.clients);

		jobElement.dataset.row = this.job.resource;
		jobElement.dataset.type = this.job.type;
		jobElement.dataset.job_id = this.job.id;
		jobElement.dataset.start = formatDate(this.job.start, true);
		jobElement.dataset.end = formatDate(this.job.end, true);
		jobElement.dataset.hours = globalThis.padTo2Digits(this.job.start.getHours());

		if (this.job.object_id) jobElement.dataset.object_id = this.job.object_id;

		this.dataset.start = formatDate(this.job.start, true);
		this.dataset.end = formatDate(this.job.end, true);

		if (this._level == "week" || "day") {
			this.dataset.start += "T" + padTo2Digits(this.job.start.getHours()) + ":00:00";

			var end_hour = this.job.end.getHours();
			if (this.job.start.getHours() !== this.job.end.getHours() || this._hourDiff(this.job.start, this.job.end) > 1) end_hour--;

			this.dataset.end += "T" + padTo2Digits(end_hour) + ":59:59";
			jobElement.dataset.start = this.dataset.start;
			jobElement.dataset.end = this.dataset.end;
		}

		this.dataset.hours = globalThis.padTo2Digits(this.job.start.getHours());
		if (this.job.object_id) this.dataset.object_id = this.job.object_id;
		this.dataset.row = this.job.resource;

		jobElement.addEventListener("click", function (e) {
			if ($("#vue_graphique_calendar").length === 0) e.stopPropagation();

			nb_click++;

			setTimeout(() => {
				if (nb_click == 1) globalThis.handleItemClick(this.id, this.dataset);
				nb_click = 0;
			}, 350);
		}, false);

		var node = this.shadowRoot.querySelector(".job_description .description");
		node.innerHTML = this.job.description;

		var delete_element = this.shadowRoot.querySelector(".job_description .description .delete_item");

		if (delete_element) {
			delete_element.dataset.job_id = this.job.id;
			delete_element.dataset.type = this.job.type;
			delete_element.dataset.row = this.job.resource;
			delete_element.dataset.start = this.dataset.start;
			delete_element.dataset.end = this.dataset.end;

			if (this.job.object_id) delete_element.dataset.object_id = this.job.object_id;

			delete_element.addEventListener("click", function (e) {
				e.stopPropagation();
				globalThis.handleDeleteItem(this.dataset, gantElement);
			}, false);
		}

		var type = this.shadowRoot.querySelector(".type");

		if (type) type.value = this.job.type;

		this._render();
	}



	disconnectedCallback() { }



	update() {
		this._render();
	}



	_render() {
		var jobElement = this.shadowRoot.querySelector(".job");
		var d;
		var border = 0;
		var date = globalThis.formatDate(this.job.start, true)

		if (this._level == "month") {
			d = this._dayDiff(this.job.start, this.job.end, true);
			this.width = 40;
		} else if (this._level == "week") {
			d = this._hourDiff(this.job.start, this.job.end, true);
			border = d > 1 ? 2 : -1;

		} else if (this._level == "day") {
			d = this._hourDiff(this.job.start, this.job.end);
			border = -1;
		}

		let job_width = (d * this.width) + border;

		if (this.job.hasOwnProperty("dashed_border")) {
			jobElement.classList.add("dashed_border_item");

			if (!isNaN(job_width)) job_width -= 4;

			jobElement.style.minHeight = "calc(100% - 4px)";
		} else {
			jobElement.style.backgroundColor = this.job.color;
			jobElement.style.opacity = ".7";

			if (globalThis.fusion_intervenant && this.job.type !== "project_duration_item") {
				jobElement.style.minHeight = "calc(100% - 4px)";
				jobElement.style.height = "calc(100% - 4px)";
				jobElement.style.top = "2px";
			}
		}

		if (this.job.type === "project_duration_item") jobElement.classList.add("project_duration_item");

		if (!isNaN(job_width)) jobElement.style.width = job_width + "px";

		var job_description = this.shadowRoot.querySelector(".job .job_description");

		if (this.job.type && this.job.type == "project_name_item") {
			jobElement.style.height = "100%";
			jobElement.style.top = "0px";
			job_description.style.marginTop = "10px";
		} else if (document.querySelector("#vue_graphique_calendar") && (globalThis.selected_btn !== "btn_year" || (globalThis.selected_btn === "btn_year" && globalThis.project_intervenant_vue === true && this.job.type !== "project_duration_item" && !this.job.hasOwnProperty("dashed_border"))))
			jobElement.classList.add("resizable");

		if (job_description) job_description.style.color = getOpositeColor(this.job.color);
	}




	isMouseOverDragHandle = function (e) {
		var panel = this.shadowRoot.querySelector(".job");
		var current_width = parseInt(getComputedStyle(panel, '').width);

		if (e.offsetX >= (current_width - this._HANDLE_SIZE)) return true;

		return false;
	}.bind(this);


	_HANDLE_SIZE = 4;


	_weekDiff(d1, d2) {
		var diffTime = Math.abs(d2 - d1);
		var diffWeeks = Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 7));
		return diffWeeks;
	}



	_dayDiff(d1, d2, isMonth) {
		if (!(d1 instanceof Date)) d1 = new Date(d1);
		if (!(d2 instanceof Date)) d2 = new Date(d2);
		const diffTime = Math.abs(d2 - d1);
		const diffDays = isMonth ? Math.ceil(diffTime / (1000 * 60 * 60 * 24)) : Math.floor(diffTime / (1000 * 60 * 60 * 24));
		return diffDays;
	}




	_hourDiff(d1, d2, is_week) {
		var diffHours = customDiffhours(this.job.start.getHours(), this.job.end.getHours());

		let day_diff = this._dayDiff(d1, d2);
		if (is_week && day_diff > 0) {
			const entreprise_hours = Object.values(JSON.parse($("#entreprise_hours").val()));
			const nb_day_hours = $("#entreprise_hours").length ? entreprise_hours.length : 9;

			let end_heur = this.job.end.getMinutes() > 0 ? this.job.end.getHours() + 1 : this.job.end.getHours();

			let diffHoursFirstDay = customDiffhours(parseInt(entreprise_hours[0]), this.job.start.getHours());
			let diffHoursLastDay = customDiffhours(parseInt(entreprise_hours.at(-1)), end_heur) + 1;

			diffHours = ((day_diff + 1) * nb_day_hours) - diffHoursFirstDay - diffHoursLastDay;
		}
		return diffHours;
	}




	set job(newValue) {
		this._job = newValue;
		this._render();
	}



	get job() {
		return this._job;
	}



	set level(newValue) {
		this._level = newValue;
	}



	get level() {
		return this._level;
	}
}

window.customElements.define('gantt-job', GanttJob);

