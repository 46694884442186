import {initializeMinAndMaxDatepickers} from './global.js';
var defaultDateDebut = null;
var defaultDateFin = null;
let defaultDateDebutfilter = null;
let defaultDateFinfilter = null;
let conges_table = null;

function getLastDayOfthisMonth() 
{
    const today = new Date();
    const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    return nextMonth.getDate();
}


function LoadDataTableConges()
{
    if ($("#isAdmin").val()) {
        var columnDefs = [
            { "data": "id", "targets": 0 },
            { "data": "nom", "targets": 1 },
            { "data": "type", "targets": 2 },
            { "data": "date", "targets": 3 },
            { "data": "state", "targets": 4 },
            { "data": "action", "targets": 5, orderable: false },
        ];
    } else {
        var columnDefs = [
            { "data": "id", "targets": 0 }, 
            { "data": "type", "targets": 1 },
            { "data": "date", "targets": 2 },
            { "data": "state", "targets": 3 },
            { "data": "action", "targets": 4, orderable: false },
        ];
    }

    conges_table = $('#datatable_conges_users').DataTable({
        "columnDefs": columnDefs,
        "processing": true,
        "serverSide": true,
        "ajax": {
            "url": Routing.generate('server_processing_conges'),
            "type": "POST",
            "data": function (d) {
                d._locale = $('#local').val();
                d.date_debut_conge = $('#user_conges_date_debut_filter').val();
                d.date_fin_conge = $('#user_conges_date_fin_filter').val();
            },

        },
        "paging": true,
        "pageLength": 20,
        "lengthChange": false,
        "searching": true,
        "ordering": true,
        "order": [[0, "desc"]],
        "info": false,
        "autoWidth": false,
        "responsive": true,
        "scrollX": true,
        "language": {
            "url": $("#datatable_lang").val()
        },
    });
	
    return conges_table;
}
function LoadDataTableCongesDash()
{
  
        var columnDefs = [
            { "data": "id", "targets": 0 },
            { "data": "nom", "targets": 1 },
            { "data": "type", "targets": 2 },
            { "data": "date", "targets": 3 },
            { "data": "state", "targets": 4 },
        ];
   

    conges_table = $('#datatable_conges_users_dashbord').DataTable({
        "columnDefs": columnDefs,
        "processing": true,
        "serverSide": true,
        "ajax": {
            "url": Routing.generate('liste_conge_current_month'),
            "type": "POST",
            "data": function (d) {
                d._locale = $('#local').val();
                d.date_debut_conge = $('#user_conges_date_debut_filter').val();
                d.date_fin_conge = $('#user_conges_date_fin_filter').val();
            },

        },
        "paging": true,
        "pageLength": 20,
        "lengthChange": false,
        "searching": true,
        "ordering": true,
        "order": [[0, "desc"]],
        "info": false,
        "autoWidth": false,
        "responsive": true,
        "scrollX": true,
        "language": {
            "url": $("#datatable_lang").val()
        },
    });
	
    return conges_table;
}


function checkTasks() 
{
    let userId = $("#user_conges_user").val() ?? $("#id_user_connecter").val() ;
    var startDate = $('#user_conges_date_debut').val().split("/").reverse().join("-");
    var endDate = $('#user_conges_date_fin').val().split("/").reverse().join("-");
   
    $.ajax({
        method: 'POST',
        url: Routing.generate('checkTaskUser'),
        data: {
            userId: userId,
            startDate: startDate,
            endDate: endDate,
            heureFin: $('#user_conges_heure_fin').val(),

        },

        success: function (response) {
            if (response.html.trim() === '')
                $("#info").html(""); 
            else
                $("#info").html(response.html);
        }
    });
}


function checkDates() 
{
    var userId = $('#user_conges_user').val() ?? $("#id_user_connecter").val();
    var state = $('#user_conges_state').val();
    var id_conge = $('#id_conge').val();
    
    if (!userId || userId.trim() === '') {
        $('#heure_error').text('Choisir un utilisateur.')
           $('html, body').animate({
            scrollTop: $("#heure_error").offset().top - 100
        }, 800);
        
        return;  
    }
    
    var type = $('#user_conges_type').val();

    var data = {
        userId,
        type,
        state,
        id_conge
    }

    var startDate = $('#user_conges_date_debut').val().split("/").reverse().join("-");
    var endDate = $('#user_conges_date_fin').val().split("/").reverse().join("-");
    
    if(type === "autorisation") {
        var heureDebut = $('#user_conges_heure_debut').val();
        startDate += " " + heureDebut;
        var heureFin = $('#user_conges_heure_fin').val();
        data = {...data, startDate, heureFin};
    } else {
        data = {...data, startDate, endDate};
    }

    $.ajax({
        url: Routing.generate('checkDateConge'),
        method: 'POST',
        data: data,
        success: function(response) {
            response.resultat

            $("#heure_error").html(response.html)

            $('html, body').animate({
                scrollTop: $("#heure_error").offset().top - 100
            }, 800)          
        }
    });
}





function export_conges(page, file_path) 
{
    $.ajax({
        type: "POST",
        url: Routing.generate('exporterConge'),
        data: {
            page,
            file_path,
            date_debut_conge: $("#user_conges_date_debut_filter").val(),
            date_fin_conge:  $("#user_conges_date_fin_filter").val(),
            search: $(".datatable_search").val()
        },
        dataType: 'json' 
    }).done(function (res) {
        if (res) {
            if (res.is_last_page) {
                var link = document.createElement('a');
                link.href = "/" + res.file_path;
                link.style.display = 'none';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                setTimeout(() => {
                    var directory_path = res.file_path.split("/");
                    directory_path.pop();
                    directory_path = directory_path.join("/");
                    delete_temp_files("/" + directory_path);
                }, 2000)
            } else {
                page++;
                file_path = res.file_path;
                export_conges(page, file_path); 
            }
        }
    }).fail(function (jqXHR, textStatus, errorThrown) {
        console.error("Request failed: " + textStatus + ", " + errorThrown);
    });
}




function delete_temp_files(path) 
{
   return $.ajax({
       type: "POST",
       url: Routing.generate('delete_temp_files'),
       data: {
           path: path
       }
   })
}



$(document).ready(function () {
    if($('#form_type #user_conges_type').length){
        if ($('#form_type #user_conges_type').val() === 'autorisation') {
            $('#heure_debut_container input').attr("required", "required");
            $('#heure_fin_container input').prop("required", "required");
        } else {
            $('#heure_debut_container input').removeAttr("required");
            $('#heure_fin_container input').removeAttr("required");
        }
    }


    const locale = $('#local').val();

    if ($('#id_conge').val() && $("#info").length )		 checkTasks();

    if ( $('#datatable_conges_users').length ) 		conges_table = LoadDataTableConges();
    if ( $('#datatable_conges_users_dashbord').length ) 		conges_table = LoadDataTableCongesDash();

    if ($("#user_conges_date_debut").length && $("#user_conges_date_debut").val())
        defaultDateDebut = new Date($("#user_conges_date_debut").val().split("/").reverse().join("-"));

    if ($("#user_conges_date_fin").length && $("#user_conges_date_fin").val())
        defaultDateFin = new Date($("#user_conges_date_fin").val().split("/").reverse().join("-"));

    defaultDateDebutfilter = new Date();
    defaultDateDebutfilter.setDate(1);
    defaultDateDebutfilter.setMonth(defaultDateDebutfilter.getMonth());


    defaultDateFinfilter = new Date();
    defaultDateFinfilter.setDate(getLastDayOfthisMonth());


    let user_conges_date_debut = $("#user_conges_date_debut").flatpickr({
        enableTime: false,
        dateFormat: "d/m/Y",
        defaultDate: defaultDateDebut,
        maxDate: defaultDateFin,
        position: 'auto',
        "locale": {
            "firstDayOfWeek": 1
        },
        onChange: function (selectedDates, dateStr, instance) {
            user_conges_date_fin.set('minDate', selectedDates[0]);
            checkTasks();
        },
    });
    

   if(( $('#state').val() !='en_cours') && !$('#isAdmin').val() && $('#isEdit').val() == 1 ) {
       $("#user_conges_date_debut").css('pointer-events','none');
       $("#user_conges_date_fin").css('pointer-events','none');
       $("#user_conges_heure_debut").css('pointer-events','none');
       $("#user_conges_heure_fin").css('pointer-events','none');
   }


   let user_conges_date_fin = $("#user_conges_date_fin").flatpickr({
        enableTime: false,
        dateFormat: "d/m/Y",
        defaultDate: defaultDateFin,
        minDate: defaultDateDebut,
        position: 'auto',
        "locale": {
            "firstDayOfWeek": 1
        },
        onChange: function (selectedDates, dateStr, instance) {
            user_conges_date_debut.set('maxDate', selectedDates[0]);
            checkTasks();
        },
    });
    

    if ($("#user_conges_date_debut_filter").length && $("#user_conges_date_debut_filter").val())
        defaultDateDebutfilter = new Date($("#user_conges_date_debut_filter").val());

    if ($("#user_conges_date_fin_filter").length && $("#user_conges_date_fin_filter").val())
        defaultDateFinfilter = new Date($("#user_conges_date_fin_filter").val());

    let user_conges_date_debut_filter = $("#user_conges_date_debut_filter").flatpickr({
        enableTime: false,
        time_24hr: false,
        dateFormat: "d/m/Y",
        defaultDate: defaultDateDebutfilter,
        maxDate: defaultDateFinfilter,
        minuteIncrement: 1,
        position: 'auto',
        "locale": {
            "firstDayOfWeek": 1
        },
        onChange: function (selectedDates, dateStr, instance) {
            user_conges_date_fin_filter.set('minDate', selectedDates[0]);
        },
    });



    let user_conges_date_fin_filter = $("#user_conges_date_fin_filter").flatpickr({
        enableTime: false,
        time_24hr: false,
        dateFormat: "d/m/Y",
        defaultDate: defaultDateFinfilter,
        minDate: defaultDateDebutfilter,
        minuteIncrement: 1,
        position: 'auto',
        "locale": {
            "firstDayOfWeek": 1
        },
        onChange: function (selectedDates, dateStr, instance) {
            user_conges_date_debut_filter.set('maxDate', selectedDates[0]);
        },
    });


    let user_conges_heure_debut = $("#user_conges_heure_debut").flatpickr({
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
    });


    
    let user_conges_heure_fin = $("#user_conges_heure_fin").flatpickr({
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true
    });





    $('form[name="user_conges"]').on("submit", function(e){
        var hasError = false;

        $(this).find('input[required="required"]:visible').each(function(index) {
            if(!$(this).val()) {
                hasError = true;

                $('html, body').animate({
                    scrollTop: $(this).offset().top - 100
                }, 800);

                $(this).focus();
                $(this).click();

                return false;
            }

            if ($('#heure_error').text().trim() !== '') {
                hasError = true;

                $('html, body').animate({
                    scrollTop: $('#heure_error').offset().top - 100
                }, 800);
            }
    
            if (hasError) {
                e.preventDefault();  

                return false;
            }
        });

        if(hasError) {
            e.preventDefault();
            
			return false;
        }
    });
	
	
	
	


    $(document).on('change', '#user_conges_date_fin , #user_conges_date_debut', function() {
        var type = $('#user_conges_type').val();
        var startDate = $('#user_conges_date_debut').val();
        var endDate = $('#user_conges_date_fin').val();

        if ((type === 'conge' && startDate.length && endDate.length) || (type === 'autorisation' && startDate.length && $('#heure_debut_container input').val() && $('#heure_fin_container input').val())) {  
			checkDates();
            checkTasks();
        }
    });




	$(document).on('change', '#user_conges_date_fin_filter , #user_conges_date_debut_filter ', function () {
		conges_table.settings()[0].ajax.data =  {
				_locale : $('#local').val() , 
				date_debut_conge : $('#user_conges_date_debut_filter').val() , 
				date_fin_conge : $('#user_conges_date_fin_filter').val()
		};
		
        conges_table.ajax.reload();
	});
   
   
   
    
    $(document).on('change', '#form_type #user_conges_type', function () {
        const dateDebutContainer = $('#date_debut_container');
        const dateFinContainer = $('#date_fin_container');
        var endDate = $('#user_conges_date_fin').val();
        var startDate = $('#user_conges_date_debut').val();    
           
        if ($(this).val() === 'autorisation') {
            dateFinContainer.hide();
            dateDebutContainer.find('label').text($("#date_label").val());
            dateDebutContainer.find('input').attr('placeholder', $("#date_label").val());
            
			$('#heure_debut_container').show();
            $('#heure_fin_container').show();
            
			$('#heure_debut_container input').prop("required", true);
            $('#heure_fin_container input').prop("required", true);
            
			if (startDate.length && $('#heure_debut_container input').val() && $('#heure_fin_container input').val()) {
                checkDates();
                checkTasks();
            } 
        } else {
            dateFinContainer.show();
            dateDebutContainer.find('label').text($("#date_debut_label").val());
            dateDebutContainer.find('input').attr('placeholder', $("#date_debut_label").val());
            
			$('#heure_debut_container').hide();
            $('#heure_fin_container').hide();
            $('#heure_debut_container input').prop("required", false);
            $('#heure_fin_container input').prop("required", false);
            
			if (startDate.length && endDate.length){
                checkDates();
                checkTasks();
            } 
        }
    });


   

    $(document).on('change', '#user_conges_heure_debut, #user_conges_heure_fin', function () {
        const heureDebut = $('#user_conges_heure_debut').val();
        const heureFin = $('#user_conges_heure_fin').val();
        
		if (heureDebut && heureFin) {
            const [debutHours, debutMinutes] = heureDebut.split(':').map(Number);
            const [finHours, finMinutes] = heureFin.split(':').map(Number);

            const debutTime = debutHours * 60 + debutMinutes;
            const finTime = finHours * 60 + finMinutes;
            
			if (finTime <= debutTime) {
                $('#time_error').text('L\'heure de fin doit être supérieure à l\'heure de début.');
                $('#heure_fin').addClass('error');
                $('#user_conges_heure_fin').val(''); 
            } else {
                $('#time_error').text('');
                $('#heure_fin').removeClass('error');
            }

            checkDates();
            checkTasks();

        }
    });
	
	
	
	
	
    $(document).on('change', '#user_conges_user', function () {
        var endDate = $('#user_conges_date_fin').val();
        var startDate = $('#user_conges_date_debut').val();
        var userId = $('#user_conges_user').val();
        var type = $('#user_conges_type').val();

        if ($(this).val().trim() !== '') 		$('#heure_error').text('');
        
        if((startDate!='' &&  endDate!='') || (startDate!='' && type == 'autorisation')){
            checkDates();
            checkTasks();
		}  
    });

    

    $(document).on('click', '.user_list_content #filter_date_conge', function () {
        conges_table.ajax.params.date_debut_conge = $("#user_conges_date_debut_filter").val();
        conges_table.ajax.params.date_fin_conge = $("#user_conges_date_debut_filter").val();
		
		conges_table.ajax.reload();
    });




    $(document).on('click', '#exporter_conges', function () {
        export_conges(0, "");
    });
      
  
});