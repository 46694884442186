function int_to_2_digits(nb)
{
    return ("0"+nb).substr(-2);
}





function updateCountdown(startTime, endTime) 
{
    var timeDifference = endTime - startTime;

    if (timeDifference <= 0)			return "";
    
    var days = Math.floor(timeDifference / (60 * 60 * 24));
    var hours = Math.floor((timeDifference % (60 * 60 * 24)) / (60 * 60));
    var minutes = Math.floor((timeDifference % (60 * 60)) / 60);
    var seconds = Math.floor(timeDifference % 60);

    var countdownText = "";
    if (days > 0) 		countdownText += days + " days ";
    
    countdownText += int_to_2_digits(hours) + ":" + int_to_2_digits(minutes) + ":" + int_to_2_digits(seconds);

    return countdownText;
}







$(document).ready(function () {


    if ($(".remain-time").length) {
        let startTime = parseInt($("#start_time").val());
        const endTime = parseInt($("#end_time").val());

        setInterval(function () {
            $(".remain-time").html(updateCountdown(startTime, endTime));
            startTime += 1;
        }, 1000);
    }


	if( $(".TimeRestContainer")){
		$(document).on('click', '.send_test_email', function () {
			$.ajax({
				type: "post",
				url:  Routing.generate('send_candidat_message'),
				data: 	 {
					candidatTestId: $(this).attr("data-id") 
				} ,
				dataType: 'json',
				success: (data) => {}
			});
		});
	}
    
    

});
