import { processMoneyFields, applySelect2 } from "../custom/global.js"

var removedOptions = [];
let PojectFactureDataTable = null;


let columnDefs = [
	{ "data": "id", "targets": 0 },
	{ "data": "date_envoi", "targets": 1 },
	{ "data": "date_reglement", "targets": 2 },
	{ "data": "montant_facture", "targets": 3 },
	{ "data": "devis", "targets": 4 },
	{ "data": "Action", "targets": 5, "orderable": false },
]
if ($("#prestataire_page").val()) {
	columnDefs = [
		{ "data": "id", "targets": 0 },
		{ "data": "date_envoi", "targets": 1 },
		{ "data": "client", "targets": 2 },
		{ "data": "date_reglement", "targets": 3 },
		{ "data": "montant_facture", "targets": 4 },
		{ "data": "devis", "targets": 5 },
		{ "data": "Action", "targets": 6, "orderable": false },
	]
}
function OnLoadPojectFactureDataTable() {
	let table = $("#dataTable_project_factures").DataTable({
		"columnDefs": columnDefs,
		"processing": true,
		"serverSide": true,
		"ajax": Routing.generate('server_processing_project_facture', {
			"iso_code": $("#local").val(),
			"id_projet": $("#id_projet").val(),
			"only_prestatire": $("#prestataire_page").val() ? true : false,
		}),
		"paging": true,
		"pageLength": 10,
		"lengthChange": false,
		"lengthMenu": [[20, 50, 100, -1], [20, 50, 100, "All"]],
		"language": {
			url: "/datatable_fr.json",
			paginate: {
				next: "<i class='ti-arrow-right'></i>",
				previous: "<i class='ti-arrow-left'></i>"
			}
		},
		"order": [[0, "desc"]],
		"info": false,
		"ordering": true,
		"searching": true,
		"bLengthChange": false,
		"scrollX": true,
	});

	return table;
}






function removeOptionFromSelect(element, id_devis, currentSelect = null) {
	var select = element;

	select.find('option').each(function () {
		if ($(this).val() === id_devis && (!select.is(currentSelect) || !currentSelect)) {
			var optionToRemove = select.find('option[value="' + id_devis + '"]');

			if (!removedOptions.includes(optionToRemove)) removedOptions.push(optionToRemove);

			optionToRemove.remove();
		}
	});

	return removedOptions;
}






function getSelectedDevisIds(elements) {
	var selectedDevisIds = elements.map(function () {
		return $(this).val();
	}).get();

	return selectedDevisIds;
}





function addOptionToSelects(elements, removedOptions, selectedDevisIds, currentSelect = null) {
	let uniqueRemovedOptions = {};

	removedOptions.forEach(function (option) {
		uniqueRemovedOptions[option.val()] = option;
	});

	let uniqueOptionsArray = Object.values(uniqueRemovedOptions);

	uniqueOptionsArray.forEach(function (option, index) {
		if (selectedDevisIds.indexOf(option.val()) == -1) {
			elements.not(currentSelect).each(function () {
				$(this).append(option.clone());
			});

			uniqueOptionsArray.splice(uniqueOptionsArray.indexOf(option), 1);
		}
	});

	return uniqueOptionsArray;
}







function removeFactureLine(element, $parentLineElements = null) {
	let $parentLine = element.closest('.devis_factures_lines');

	if ($parentLineElements) $parentLine = $parentLineElements;

	let lineCount = $('.add_factures_lines_div').children().length;

	if ($('.add_factures_lines_div').children().length > 1) {
		$parentLine.remove();

		lineCount--;

		if (lineCount === 1) $('.remove_factures_lines').first().hide();
	}

	let selectedDevisIds = getSelectedDevisIds($(".devis_factures_lines .project_factures_devis"));

	removedOptions = addOptionToSelects(
		$(".devis_factures_lines .project_factures_devis"),
		removedOptions, selectedDevisIds
	);

	$(".add_factures_lines").last().show();
}






$(document).ready(function () {
	var locale = $('#local').val();
	var id_project = $("#id_project").val();
	var isEdit = $("#isEdit").val();

	if (isEdit) {
		let lineCount = $('.devis_factures_lines').length;

		if (lineCount === 1)
			$('.remove_factures_lines').hide();
		else
			$('.remove_factures_lines').show();

		$(".add_factures_lines").last().show();
	}


	PojectFactureDataTable = OnLoadPojectFactureDataTable();



	$(document).on('click', '#delete_doc', function () {
		var url = $(this).data('href');

		$.ajax({
			method: 'post',
			url: url,
			success: function (res) {
				$('.DivInputExiste').html(res)
			}
		});
	});




	$(document).on("change", ".devis_factures_lines .project_factures_devis", function () {
		var id_devis = $(this).val();
		var parent = $(this).parents(".devis_factures_lines");
		var projectFacturesLinesMontant = parent.find('.money_field');
		var id_project = $("#id_project").val();
		var currency = $("#currency").val();

		parent.find(".error_msg").hide();

		projectFacturesLinesMontant.closest("form").find('button[type="submit"]').prop("disabled", false);

		var currentSelect = $(this);

		$(".devis_factures_lines .project_factures_devis").each(function () {
			removedOptions = removeOptionFromSelect($(this), id_devis, currentSelect);
		});

		let selectedDevisIds = getSelectedDevisIds($(".devis_factures_lines .project_factures_devis"));

		removedOptions = addOptionToSelects(
			$(".devis_factures_lines .project_factures_devis"),
			removedOptions,
			selectedDevisIds,
			currentSelect
		);

		let ancien_id_devis = $(this).attr("data-id-devis");
		let ancien_montant_facture = $(this).attr("data-ancien-montant");

		$.ajax({
			method: 'post',
			data: { id_devis, id_project },
			url: Routing.generate('get_project_devis_montant'),
			success: function (data) {
				let sommeProjectFacture = parseFloat(data['sommeProjectFactures']);

				if (ancien_id_devis && ancien_id_devis == id_devis) sommeProjectFacture = sommeProjectFacture - ancien_montant_facture;

				let montant_rester_a_payer = parseFloat(parseFloat(data['devisMontant']) - sommeProjectFacture).toFixed(2);

				projectFacturesLinesMontant.val(montant_rester_a_payer);
				processMoneyFields(".money_field", currency);
			}
		});
	});





	$(document).on("change", ".devis_factures_lines .money_field", function () {
		var parent = $(this).parents(".devis_factures_lines");
		var id_devis = (parent.find(".project_factures_devis")).val();
		var id_project = $("#id_project").val();
		var montant_a_payer_from_input = Number($(this).val().replaceAll(',', '.').replace(/[^0-9.]/g, ''));

		var currency = $("#currency").val();
		let ancien_id_devis = (parent.find(".project_factures_devis")).attr("data-id-devis");
		let ancien_montant_facture = (parent.find(".project_factures_devis")).attr("data-ancien-montant");

		if (id_devis) {
			parent.find(".error_msg").hide();
			parent.find("#project_factures_lines_montant").closest("form").find('button[type="submit"]').prop("disabled", false);

			$.ajax({
				method: 'post',
				data: { id_devis, id_project },
				url: Routing.generate('get_project_devis_montant'),
				success: function (data) {
					let sommeProjectFacture = parseFloat(data['sommeProjectFactures']);
					let montant_rester_a_payer = parseFloat(parseFloat(data['devisMontant']) - sommeProjectFacture).toFixed(2);

					if (ancien_id_devis && ancien_id_devis == id_devis) {
						montant_rester_a_payer = ancien_montant_facture;
						sommeProjectFacture = sommeProjectFacture - montant_rester_a_payer;
					}

					let current_montant_rest_a_payer = parseFloat(data['devisMontant']) - (sommeProjectFacture + parseFloat(montant_a_payer_from_input));

					if (current_montant_rest_a_payer < 0) {
						var errorMessage = parent.find(".error_msg.message_txt").text();
						errorMessage = errorMessage.replace("#montant#", montant_rester_a_payer + currency);
						parent.find(".error_msg.message_txt").text(errorMessage);
						parent.find(".error_msg.message_txt").show();
						parent.find("#project_factures_lines_montant").closest("form").find('button[type="submit"]').prop("disabled", true);
					}
				}
			});
		}
	});







	$(document).on('click', '.add_factures_lines', function () {
		var id_project = $("#id_project").val();
		var id_facture = $("#id_facture").val();

		let parent = $(this).parents(".devis_factures_lines");

		var $devisSelect = $(this).closest('.devis_factures_lines').find('.project_factures_devis');
		var $montantInput = $(this).closest('.devis_factures_lines').find('.montant_filed input');

		if ($devisSelect.val() == '') {
			parent.find(".devisInvalid").show();

			return;
		}

		if ($montantInput.val() == '' || isNaN(parseFloat($montantInput.val()))) {
			parent.find(".montant_invalid").show();

			return;
		}

		$(this).hide();

		$.ajax({
			method: 'post',
			url: Routing.generate('add_project_factures_lines', { 'id': id_project, '_locale': locale }),
			data: { id_facture },
			success: function (res) {
				let selectedDevisIds = getSelectedDevisIds($(".devis_factures_lines .project_factures_devis"));
				let $res = $(res);
				let $select = $res.find(".project_factures_devis");

				$res.find(".add_factures_lines").show();

				$res.find('.remove_factures_lines').last().show();

				selectedDevisIds.forEach(function (id_devis) {
					if (id_devis !== "") removeOptionFromSelect($select, id_devis);
				});

				$('.add_factures_lines_div').append($res);

				$('.select2').each(function () {
					applySelect2($(this));
				});

				$('.remove_factures_lines').show();
			}
		});
	});






	$(document).on('click', '.remove_factures_lines', function () {
		let id_facture_line = $(this).attr("data-id-facture-line");

		if (id_facture_line) {
			$("#Supp-modal .supprimer-button-factureLines").attr("data-id", id_facture_line);
			$('#Supp-modal').modal("show");
		} else
			removeFactureLine($(this));
	});






	$(document).on('click', '.supprimer-button-factureLines', function () {
		let id_facture_line = $(this).data('id');

		$.ajax({
			method: 'get',
			url: Routing.generate('remove_project_factures_lines', { 'id': id_facture_line }),
			success: function (res) {
				if (res == "done") {
					removeFactureLine(
						$('.remove_factures_lines'),
						$('.devis_factures_lines[data-id-facture-line="' + id_facture_line + '"]')
					);
				}

				$.modal.close();
			}
		});
	});





	$(document).on('click', '#project_factures_save', function (event) {
		event.preventDefault();

		let $form = $(this).closest('form');
		let formValid = true;

		$('.montant_filed input').each(function () {
			let $input = $(this);
			let inputValue = $input.val();

			if (inputValue == '' || isNaN(parseFloat(inputValue))) {
				$input.closest('.montant_filed').find('.montant_invalid').show();
				formValid = false;
			}
		});

		if ($('.error_msg:visible').length > 0) return;

		if (formValid) $form.submit();
	});





	$(document).on('click', '#download_devis', function (event) {
		let id_facture = $(this).data("id");

		window.location.href = Routing.generate('download_project_factures_devis', { 'id_facture': id_facture });
	});




});
