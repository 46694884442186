/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './css/vendor/bootstrap.min.css';
import './css/vendor/light-bootstrap-dashboard.css';
import './css/vendor/demo.css';
import './css/vendor/font-awesome.min.css';
import './css/vendor/pe-icon-7-stroke.css';
import './css/vendor/roboto.css';
import './css/vendor/select2.min.css';
import './css/vendor/bootstrap-treeview.min.css';
// import './css/vendor/dropzone.css';
import './css/vendor/dropzone.min.css';
import './css/vendor/flatpickr.min.css';
import './css/vendor/modal.css';
import './css/custom/global.css';
import './css/custom/user.css';
import './css/custom/category.css';
import './css/custom/Project.css';
import './css/custom/projectList.css';
import './css/vendor/jquery-ui.css';
import './css/vendor/richtext.min.css';
import './css/vendor/switcher.css';
import './css/vendor/slick.css';
import './css/vendor/jquery.dataTables.min.css';
import './css/vendor/date-picker.css';
import './css/vendor/flag-icons.min.css';
import './css/vendor/fullcalendar.min.css';
import './css/vendor/gantt_calendar.css';
import './css/vendor/rowReorder.dataTables.css';
import './css/custom/ticket.css';


const $ = require('jquery');
global.$ = global.jQuery = $;
global.DataTable = require('datatables.net-bs4');

// start the Stimulus application
const routes = require('../public/js/fos_js_routes.json');
import Routing from '../vendor/friendsofsymfony/jsrouting-bundle/Resources/public/js/router.min.js';
Routing.setRoutingData(routes);
global.Routing = Routing;

import './js/vendor/jquery.3.2.1.min.js';
import './js/vendor/jquery-ui.js';
import './js/vendor/jquery-ui-i18n.min.js';
import './js/vendor/bootstrap.min.js';
import './js/vendor/jquery.modal.js';
import './js/vendor/chartist.min.js';
import './js/vendor/bootstrap-notify.js';
import './js/vendor/light-bootstrap-dashboard.js';
import './js/vendor/demo.js';
import './js/vendor/select2.min.js';
import './js/vendor/bootstrap-treeview.min.js';
// import './js/vendor/dropzone.js';
import './js/vendor/dropzone.min.js';
import './js/vendor/flatpickr.js';
import './js/vendor/jquery.richtext.min.js';
import './js/vendor/jquery.lazy.min.js';
import './js/vendor/jquery.lazy.plugins.min.js';
import './js/vendor/jquery.switcher.js';
import './js/vendor/jquery.mask.js';
import './js/custom/global.js';
import './js/custom/Project.js';
import './js/custom/category.js';
import './js/custom/ProjectFront.js';
import './js/custom/access_request.js';
import './js/custom/client.js';
import './js/custom/clientUser.js';
import './js/custom/user.js';
import './js/vendor/slick.min.js';
import './js/vendor/datepicker.js';
import './js/vendor/datepicker.custom.js';
import './js/vendor/datepicker.fr.js';
import './js/custom/ProjectFactures.js';
import './js/custom/Ticket.js';
import './js/custom/users_tasks_graphique.js';
import './js/custom/current_user_tasks_graphique.js';
import './js/custom/client_tasks_graphique.js';
import './js/custom/comptesClient.js';
import './js/custom/conges.js';
import './js/custom/userJourFeries.js';
import './js/custom/projectDictionnaires.js';
import './js/custom/recrutementCandidats.js';
import './js/custom/recrutementTestes.js';
import './js/custom/candidat_test.js';
import './js/custom/annonce.js';
import './js/custom/annonceRequest.js';
import './js/custom/annonce_request_option.js';
import './js/custom/meeting.js';
import './js/vendor/dataTables.rowReorder.js';
