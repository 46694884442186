import VanillaGanttChart from "./VanillaGanttChart.js";
import { getModalTask, initTaskDates } from "../custom/global.js"

function checkDisponibility() {
    let selected_start_date = null;
    let selected_end_date = null;
    let user_id = null;
    let project_livrable_task_id = null;
    let project_livrable_id = null;
    let type = "ajoute";

    if ($("#modal_edit_task_container:visible").length) {
        selected_start_date = $("#modal_edit_task_container #task_start_date").val().replace("T", " ");
        selected_end_date = $("#modal_edit_task_container #date_fin_previsionnel").val().replace("T", " ");
        user_id = $("#modal_edit_task_container #task_user").val();
        project_livrable_task_id = $("#modal_edit_task_container #project_livrable_task_id").val();
        type = "edite";
    } else {
        selected_start_date = $("#modal_add_task_container #task_start_date").val().replace("T", " ");
        selected_end_date = $("#modal_add_task_container #date_fin_previsionnel").val().replace("T", " ");
        user_id = $("#modal_add_task_container #task_user").val();
        project_livrable_id = $("#project_livrable_id").val()
    }

    $(".erreur_block").remove();
    $(".erreur_text").remove();
    $.ajax({
        type: "POST",
        url: Routing.generate("checkDesponibliterUser"),
        data: {
            start_date: selected_start_date,
            end_date: selected_end_date,
            project_livrable_task_id,
            project_livrable_id,
            user_id,
            type,
        }
    }).done(function (res) {
        if (res != "none") {
            if ($("#modal_edit_task_container:visible").length) {
                $(res).insertBefore('#modal_edit_task_container a');
            } else {
                $(res).insertBefore('#modal_add_task_container a');
            }
        }
    });
}

if (document.querySelector("#project_livrable_graphique") || document.querySelector("#users_tasks_graphique") || document.querySelector("#affectation_projet")) {
    var chart = document.querySelector(".gantt_calendar");
    var users = new Array();
    var lastItemId = 0;
    var item_to_delete = null;
    var id_item_to_delete = null;

    var is_dashboard = document.querySelector("#users_tasks_graphique") ? true : false;

    function getData(is_local_update = false) {
        var request_data = is_dashboard ? { users_ids: $("#developers_select").val() } : { livrable_id: $("#current_project_livrable_id").val() };
        $.ajax({
            type: "POST",
            url: $('#project_users_list_url').val(),
            data: request_data,


        }).done(function (res) {
            let data = JSON.parse(res);
            let lastId = 1;
            let rows = [];
            let i = 0;

            for (const [rowKey, value] of Object.entries(data.users)) {
                let localElement = {
                    id: lastId++,
                    object_id: value.id,
                    all_id: value.all_ids,
                    label: value.label,
                    type: 'users',
                    path: '#',
                    color: value.color
                }

                i++;
                rows.push(localElement);
            }

            users = data.users;

            let urlParams = new URLSearchParams(window.location.search);

            if (urlParams.has("currentYear") && urlParams.has("currentMonth") && urlParams.has("currentDay")) {
                if (urlParams.has("currentTime") && urlParams.get("currentView") === "btn_year") {
                    let start_date_calendar = new Date(parseInt(urlParams.get("currentTime")));
                    globalThis.year = start_date_calendar.getFullYear();
                    urlParams.delete("currentYear");

                    globalThis.month = start_date_calendar.getMonth();
                    urlParams.delete("currentMonth");

                    globalThis.date_jour = start_date_calendar.getDate();
                    urlParams.delete("currentDay");

                    globalThis.selected_btn = "btn_week";
                    urlParams.delete("currentView");
                } else {
                    globalThis.year = parseInt(urlParams.get("currentYear"));
                    urlParams.delete("currentYear");

                    globalThis.month = parseInt(urlParams.get("currentMonth"));
                    urlParams.delete("currentMonth");

                    globalThis.date_jour = parseInt(urlParams.get("currentDay"));
                    urlParams.delete("currentDay");

                    globalThis.selected_btn = urlParams.get("currentView");
                    urlParams.delete("currentView");
                }

                let url = window.location.pathname;

                window.history.pushState({}, document.title, url);
            }

            chart.resources = rows;

            globalThis.updateTime();
        });
    }



    function getItems() {
        let { start_date_str, end_date_str } = getDateAndTypesForItems();
        var fix_request_data = { start_date: start_date_str, end_date: end_date_str };
        if (!is_dashboard) fix_request_data = { ...fix_request_data, project_livrable_id: $("#project_livrable_id").val() };

        chart.resources.map(user => {
            var request_data = { ...fix_request_data, user_id: user['object_id'] }
            $.ajax({
                type: "POST",
                url: is_dashboard ? $("#user_tasks_by_period").val() : $("#project_livrables_tasks_by_user").val(),
                data: request_data,
            }).done(function (res) {
                let data = JSON.parse(res);
                data.map((item, itemKey) => {
                    getItemObject(item, user['id'], itemKey, true, "task");
                })
            })

            $.ajax({
                type: "POST",
                url: $("#getUserCongerParPeriod").val(),
                data: { user_id: user['object_id'], start_date: start_date_str, end_date: end_date_str }
            }).done(function (res) {
                res.map((item, itemKey) => {
                    getItemObject(item, user['id'], itemKey, false, 'conger');
                })
            });

            $.ajax({
                type: "POST",
                url: $("#getUserJoursFeriesParPeriod").val(),
                data: { start_date: start_date_str, end_date: end_date_str }
            }).done(function (res) {
                res.map((item, itemKey) => {
                    getItemObject(item, user['id'], itemKey, false, "joursFeries");
                })
            });
        });

        if (globalThis.currentScrollLeft) {
            var container = chart.shadowRoot.querySelector("#gantt-container");
            container.scrollLeft = globalThis.currentScrollLeft;
            container.dispatchEvent(new Event('scroll'));
            globalThis.currentScrollLeft = null;
        }

        return;
    }
    globalThis.getItems = getItems;


    async function getItemObject(item, rowId, itemKey, canDelete = true, type = "") {
        let start = new Date(item.start_date);
        let end = new Date(item.end_date);

        let desc = "";

        if (($("#RoleUser").val() == "ROLE_ADMIN" && canDelete) || ($("#isResponsable").length && $("#isResponsable").val()))
            desc = $("#label_item").html();
        else {
            desc = item.Libelle ?? "";
        }

        let item_element = {
            id: ++lastItemId,
            resource: rowId,
            start,
            end,
            color: item.color ? item.color : rowData.color,
            description: desc,
            object_id: item.id,
            type
        };
        globalThis.initJobs(globalThis.setChartJobs([item_element]))
    }

    function isJSON(response) {
        try {
            JSON.parse(response);
            return true;
        } catch (e) {
            return false;
        }
    }

    function addTask(row, str_date) {
        let row_index = parseInt(row) - 1;

        let rowData = chart.resources[row_index];

        let selected_start_date = $("#task_start_date").val().replace("T", " ");
        let selected_end_date = $("#date_fin_previsionnel").val().replace("T", " ");
        let details_task = $("#details_task").val();
        let user_id = $("#task_user").val();

        $.ajax({
            type: "POST",
            url: Routing.generate("add_project_livrable_task"),
            data: {
                start_date: selected_start_date,
                end_date: selected_end_date,
                project_livrable_id: $("#project_livrable_id").val(),
                user_id,
                details_task
            }
        }).done(function (res) {
            if (isJSON(res)) {
                let res_data = JSON.parse(res);

                let start_datetime_parts = selected_start_date.split(" ");
                start_datetime_parts[0] = start_datetime_parts[0].split('-').reverse().join('-');
                let item_start_date = new Date(start_datetime_parts.join(" "));

                let end_datetime_parts = selected_end_date.split(" ");
                end_datetime_parts[0] = end_datetime_parts[0].split('-').reverse().join('-');
                let item_end_date = new Date(end_datetime_parts.join(" "));

                let row_id = globalThis.getRowById(user_id);
                let item_element = {
                    id: res_data.id,
                    resource: row_id,
                    start: item_start_date,
                    end: item_end_date,
                    color: res_data.color ?? rowData.color,
                    object_id: res_data.id,
                    description: $("#label_item").html(),
                };

                globalThis.initJobs(globalThis.setChartJobs([item_element]))

                var container = chart.shadowRoot.querySelector("#gantt-container");
                globalThis.currentScrollLeft = container.scrollLeft;
                $("#modal_add_task_container").modal('hide'); $("#modal_add_task_container").modal('hide');
            } else {
                $(".erreur_block").remove();
                $(".erreur_text").remove();
                $(res).insertBefore('#modal_add_task_container a');
            }
        });
    }



    if (document.querySelector("#project_livrable_graphique")) {
        globalThis.handleCellClick = function (row, str_date, row_type) {
            if ($("#RoleUser").val() == "ROLE_ADMIN" || ($("#isResponsable").length && $("#isResponsable").val())) {
                $("#details_task").val(null);
                $("#modal_add_task_container").attr("data-row", row);
                $("#modal_add_task_container").attr("data-str_date", str_date);
                $("#modal_add_task_container").modal({
                    type: 'confirm',
                    title: $("#modal_add_task_title").val(),
                    buttons: null,
                    size: 'small',
                    center: true,
                    clickClose: false,
                    escapeClose: false,
                    showClose: false
                });
                $(".erreur_text").remove();
                let row_index = parseInt(row) - 1;
                let rowData = chart.resources[row_index];
                $("#modal_add_task_container #task_user").val(rowData.object_id);

                initTaskDates(str_date);
            }
        }
    }
    if (document.querySelector("#project_livrable_graphique") || document.querySelector("#users_tasks_graphique")) {
        globalThis.handleItemClick = function (job_id, item) {

            if (item.type == "conger" && $("#conge_info_modal").length) {
                let conge_id = item.object_id;
                $.ajax({
                    type: "POST",
                    url: Routing.generate("conge_info"),
                    data: { id: conge_id },
                }).done(function (response) {
                    if (response != 'none') {
                        let data = JSON.parse(response);
                        let msg = $("#user_conge_texte_modal").val();

                        if (data.type == "conge") {
                            msg = msg.replace("#NOM_PRENOM#", "<strong>" + data.user_name + "</strong>")
                            msg = msg.replace("#START_DATE#", "<strong>" + data.start_date + "</strong>")
                            msg = msg.replace("#END_DATE#", "<strong>" + data.end_date + "</strong>")
                        } else {
                            msg = $("#user_autorisation_texte_modal").val();
                            msg = msg.replace("#NOM_PRENOM#", "<strong>" + data.user_name + "</strong>")
                            msg = msg.replace("#DATE#", "<strong>" + data.date + "</strong>")
                            msg = msg.replace("#START_TIME#", "<strong>" + data.start_time + "</strong>")
                            msg = msg.replace("#END_TIME#", "<strong>" + data.end_time + "</strong>")
                        }
                        $(".modal_conge_msg").html(msg);
                    }
                    $("#conge_info_modal").modal();
                });
            }
        }
    }

    function getDateAndTypesForItems() {
        var show_type = "day";
        var vue_type = "month";

        if (globalThis.selected_btn == "btn_week") {
            show_type = "hour";
            vue_type = "week";
        }

        var start_date = globalThis.calendar_start_date;
        let start_date_str = globalThis.formatDate(start_date);

        if (typeof startDate !== 'undefined' && startDate && start_date.valueOf() < startDate.valueOf()) {
            start_date_str = globalThis.padTo2Digits(startDate.getDate()) + "-" + globalThis.padTo2Digits(startDate.getMonth() + 1) + "-" + startDate.getFullYear();
        }

        var end_date = globalThis.calendar_end_date;
        let end_date_str = globalThis.formatDate(end_date);

        if (typeof endDate !== 'undefined' && endDate && end_date.valueOf() > endDate.valueOf()) {
            end_date_str = globalThis.padTo2Digits(endDate.getDate()) + "-" + globalThis.padTo2Digits(endDate.getMonth() + 1) + "-" + endDate.getFullYear();
        }

        return {
            show_type,
            vue_type,
            start_date_str,
            end_date_str
        };
    }


    globalThis.initTaskDates = initTaskDates;

    function editTask(isTimeLine = true) {
        let selected_start_date = $("#modal_edit_task_container #task_start_date").val().replace("T", " ");
        let selected_end_date = $("#modal_edit_task_container #date_fin_previsionnel").val().replace("T", " ");
        let reel_end_date = $("#modal_edit_task_container #date_fin_reel").val().replace("T", " ");
        let details_task = $("#modal_edit_task_container #details_task").val();
        let user_id = $("#modal_edit_task_container #task_user").val();
        let project_livrable_task_id = $("#modal_edit_task_container #project_livrable_task_id").val();

        $.ajax({
            type: "POST",
            url: Routing.generate("edit_project_livrable_task"),
            data: {
                start_date: selected_start_date,
                end_date: selected_end_date,
                reel_end_date,
                project_livrable_task_id,
                user_id,
                details_task
            }
        }).done(function (res) {
            if (isJSON(res)) {
                if (isTimeLine) {
                    let res_data = JSON.parse(res);

                    let start_datetime_parts = selected_start_date.split(" ");
                    start_datetime_parts[0] = start_datetime_parts[0].split('-').reverse().join('-');
                    let item_start_date = new Date(start_datetime_parts.join(" "));

                    let end_datetime_parts = selected_end_date.split(" ");
                    end_datetime_parts[0] = end_datetime_parts[0].split('-').reverse().join('-');
                    let item_end_date = new Date(end_datetime_parts.join(" "));

                    let element = globalThis.getItemById(project_livrable_task_id);
                    element.remove();
                    let row_id = globalThis.getRowById(user_id);
                    let item_element = {
                        id: res_data.id,
                        resource: row_id,
                        start: item_start_date,
                        end: item_end_date,
                        color: res_data.color ?? rowData.color,
                        object_id: res_data.id,
                        description: $("#label_item").html(),
                    };

                    globalThis.initJobs(globalThis.setChartJobs([item_element]))

                    var container = chart.shadowRoot.querySelector("#gantt-container");
                    globalThis.currentScrollLeft = container.scrollLeft;
                } else {
                    var table = $('#affectation_projet').DataTable();
                    table.ajax.reload();
                }
                $("#modal_edit_task_container").modal('hide');
            } else {
                $(".erreur_block").remove();
                $(".erreur_text").remove();
                $(res).insertBefore('#modal_edit_task_container a');
            }
        });
    }
    globalThis.editTask = editTask;


    globalThis.handleDeleteItem = function (dataset, element) {
        item_to_delete = element;
        id_item_to_delete = dataset.object_id;
        $("#calendar_delete_item_modal").modal({
            type: 'confirm',
            buttons: null,
            size: 'small',
            center: true,
            clickClose: false,
            escapeClose: false,
            showClose: false
        });
    }


    globalThis.handleItemDblClick = function (jobElementId, dataset) {
        if ($("#RoleUser").val() == "ROLE_ADMIN" || $("#RoleUser").val() == "ROLE_USER") {
            getModalTask(dataset.object_id, $("#current_project_id").val(), $("#current_project_livrable_id").val(), dataset.row, jobElementId);
        }
    }

    if (!document.querySelector("#affectation_projet"))
        getData();
    $(document).ready(function () {
        if (is_dashboard) {
            $(document).on("change", "#developers_select", function () {
                getData(true);
            })
        }

        if (document.querySelector("#project_livrable_graphique")) {
            $(document).on("click", "#modal_add_task_container #add_user_project_task", function () {
                let row = $("#modal_add_task_container").attr("data-row");
                let str_date = $("#modal_add_task_container").attr("data-str_date");

                addTask(row, str_date);
            })
        }



        $(document).on("click", "#calendar_delete_item_modal #calendar_delete_item_btn", function () {
            $.ajax({
                type: "POST",
                url: Routing.generate("remove_project_livrable_task"),
                data: {
                    id: id_item_to_delete,
                }
            }).done(function (res) {
                if (res == "done") {
                    item_to_delete.remove();
                    $("#calendar_delete_item_modal").modal('hide');
                }
            });
        })


        $(document).on("change", "#task_user,#task_start_date,#date_fin_previsionnel", function () {
            if ($("#modal_add_task_container").length || $("#modal_edit_task_container").length) {
                if ($("#task_user").val() && $("#task_start_date").val() && $("#date_fin_previsionnel").val()) {
                    checkDisponibility();
                }
            }
        });

        $(document).on("click", "#modal_edit_task_container #edit_user_project_task", function () {
            let isTimeLine = !document.querySelector("#affectation_projet");
            editTask(isTimeLine);
        })
    });
}